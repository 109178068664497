import React from 'react'
import styled from 'styled-components/macro'
import { NavLink, Link } from 'react-router-dom'

import { useAuth } from '@context/auth'
import useStore from '@store/store'
import { useUser } from '@hooks/user'
import useAlerts from '@hooks/alerts'
import useResponsive from '@hooks/responsive'

import Alerts from '@alerts/Alerts'

import { Dropdown, DropdownTrigger } from '@components/dropdown'
import { Button } from '@components/button'
import Breadcrumb from '@components/Breadcrumb'
import SVG from '@components/SVG'

import Burger from '@dashboard/ui/Burger'
import Logo from '@dashboard/ui/Logo'

const StyledToolbar = styled.div`
	width: 100%;
	height: var(--dashboard__toolbar-height);
	padding: 10px 15px;
	background-color: var(--c__white);
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-radius: var(--border__radius);
	box-shadow: var(--dashboard__shadow);

	@media (min-width: 768px) {
		padding: 10px 30px;
	}
`

const StyledToolbarUserImage = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;

	a {
		width: 60px;
		height: 60px;
		border-radius: 100%;
		overflow: hidden;
		transition: all 150ms ease-out;

		&:hover {
			opacity: 0.7;
		}

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			will-change: transform;
		}
	}
`
const StyledToolbarTools = styled.div`
	display: flex;
	align-items: center;
	gap: 20px;

	& > div {
		@media (min-width: 768px) {
			margin-right: 30px;
		}

		&:last-child {
			margin-right: 0;
		}

		svg {
			width: 1.5em;
			height: 1.5em;
		}
	}
`

function Toolbar() {
	const { data: user } = useUser()
	const breadcrumb = useStore(({ breadcrumb }) => breadcrumb)
	const { logout } = useAuth()
	const { toggleSidepanel, useFetch } = useAlerts()
	const { minMD } = useResponsive()

	const { data: alerts, isSuccess: isSuccessAlerts } = useFetch()

	return (
		<StyledToolbar>
			{minMD ? <Breadcrumb items={breadcrumb} /> : null}
			{minMD ? null : <Logo />}
			<StyledToolbarTools>
				<DropdownTrigger
					isFeat
					icon={<SVG name="notifications" />}
					hasBadge={isSuccessAlerts && !!alerts?.length}
					badgeCount={alerts?.length}
					onClick={() => toggleSidepanel(true)}
				/>
				{/* <Dropdown
					trigger={({ opened }) => (
						<DropdownTrigger
							isFeat
							opened={opened}
							icon={<SVG name="settings" />}
						/>
					)}
				>
					<p>Config</p>
				</Dropdown> */}
				<Dropdown
					trigger={({ opened }) => (
						<DropdownTrigger
							isFeat
							opened={opened}
							icon={<SVG name="profile" />}
						/>
					)}
				>
					{!!user?.urlImg && (
						<StyledToolbarUserImage>
							<Link to="/profile/edit">
								<img src={user?.urlImg} alt="Imagen de perfil" />
							</Link>
						</StyledToolbarUserImage>
					)}
					<p>
						¡Hola <Link to="profile">{user?.name}</Link>!
					</p>

					<NavLink to="profile">Perfil</NavLink>
					{(user?.role?.group === 'admin' || user?.role?.group === 'superadmin') && (
						<>
							<NavLink to="users">Usuarios</NavLink>
							<NavLink to="users/create">Crear usuario</NavLink>
							{user?.role?.group === 'superadmin' && (
								<>
							<NavLink to="accounts">Cuentas</NavLink>
							<NavLink to="access-list">Accesos por cuentas</NavLink>
								</>
							)}
						</>
					)}
					{/* <NavLink to="config">Configuración</NavLink> */}
					<Button label="Cerrar sesión" onClick={() => logout()} />
				</Dropdown>
				{minMD ? null : <Burger />}
			</StyledToolbarTools>
			<Alerts />
		</StyledToolbar>
	)
}

export default Toolbar

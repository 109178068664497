import { nanoid } from 'nanoid'

export default function reducer(state, action) {
	switch (action.type) {
		case 'ADD_NOTIFICATION': {
			const { payload: notification } = action

			if (!!notification.id && state.some(o => o.id === notification.id)) {
				return state
			}
			return [
				...state,
				{
					id: nanoid(),
					createdAt: Date.now(),
					...notification
				}
			]
		}

		case 'DISMISS_NOTIFICATION': {
			const {
				payload: { id }
			} = action
			return state.filter(notification => notification.id !== id)
		}

		case 'CLEAR_NOTIFICATIONS': {
			return []
		}

		default: {
			return state
		}
	}
}

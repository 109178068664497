import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'

import GlobalStyles from '@styles/index.js'
import { NotificationsProvider } from '@notifications'
import { ModalProvider } from '@components/modal'
import { AuthProvider } from '@context/auth'

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			useErrorBoundary: false,
			refetchOnWindowFocus: false,
			//staleTime: 1000 * 60 * 1,
			staleTime: Infinity,
			suspense: false,
			//retryDelay: attemptIndex => Math.min(1000 * 2 ** attemptIndex, 30000),
			retryDelay: null,
			retry: false
			//retry(failureCount, error) {
			//return false
			/*if (
					error.status === 401 ||
					error.status === 403 ||
					error.status === 404
				)
					return false
				else if (failureCount < 1) return true
				else return false*/
			//}
		}
	}
})

const AppProviders = ({ children }) => {
	return (
		<>
			<QueryClientProvider client={queryClient}>
				<Router>
					<NotificationsProvider>
						<ModalProvider>
							<AuthProvider>{children}</AuthProvider>
							<GlobalStyles />
						</ModalProvider>
					</NotificationsProvider>
				</Router>
			</QueryClientProvider>
		</>
	)
}

export default AppProviders

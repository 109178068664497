import '@src/handle-consoles'

import React from 'react'
import { createRoot } from 'react-dom/client'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'

import numeral from 'numeral'
import 'numeral/locales/es'

import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import 'dayjs/locale/es'

import AppProviders from './context/AppProviders'
import App from './App'

dayjs.locale('es')
dayjs.extend(relativeTime)
dayjs.extend(isSameOrAfter)

numeral.locale('es')

const rootEl = document.getElementById('root')
const root = createRoot(rootEl)

root.render(
	<AppProviders>
		<App />
	</AppProviders>
)

if (process.env.NODE_ENV === 'development') {
	serviceWorkerRegistration.unregister()
} else {
	serviceWorkerRegistration.register()
}

import React, { useContext, createContext } from 'react'
import mapboxgl from 'mapbox-gl'
import MapboxWorker from 'mapbox-gl/dist/mapbox-gl-csp-worker'
import 'mapbox-gl/dist/mapbox-gl.css'

mapboxgl.workerClass = MapboxWorker

const MapContext = createContext({
	map: {},
	container: null,
	draw: null
})

const useMapContext = () => {
	const context = useContext(MapContext)

	if (context === undefined) {
		throw new Error('useMap must be used within a MapProvider')
	}

	const { map, container, draw } = context
	return { map, container, draw }
}

const withMap = WrappedComponent => props => {
	const context = useContext(MapContext)

	if (context === undefined) {
		throw new Error('useMap must be used within a MapProvider')
	}

	return (
		<MapContext.Consumer>
			{({ map, container, draw }) => (
				<WrappedComponent
					map={map}
					container={container}
					draw={draw}
					{...props}
				/>
			)}
		</MapContext.Consumer>
	)
}

export { MapContext, useMapContext, withMap }

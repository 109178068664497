import React, { useCallback, useMemo, useState, useRef, useEffect } from 'react'
import { useQueryClient } from 'react-query'
import { useFormContext } from 'react-hook-form'

import yup from '@yup'

import useNumber from '@hooks/number'
import useCRUD from '@hooks/crud'
import useSelect from '@hooks/select'
import { useNotifications } from '@notifications'

import { Button } from '@components/button'
import { Grid, GridItem } from '@components/grid'
import { Flex } from '@components/flex'
import { Form, TextField, Datepicker, Textarea, Select } from '@components/form'

import Historic from '@costs/components/Historic'

import { numberValidationPositiveWithZero } from '@dashboard/templates/forms/utils/validation'

const schema = yup.object().shape({
	cost: yup.string().numberLocale().required()
})

const DialogCostsNewPriceInner = ({ slug, isSubmitSuccess }) => {
	const { reset } = useFormContext()

	const queryClient = useQueryClient()
	const units = queryClient.getQueryData(['costs', 'units'])

	const parsedUnits = useMemo(() => {
		return units[slug].map(unit => ({
			id: unit,
			title: unit
		}))
	}, [slug, units])

	const { select: unitsSelect, selectValue: unitsSelectValue } = useSelect({
		name: 'unit',
		collection: parsedUnits,
		value: null
	})

	useEffect(() => {
		if (!!isSubmitSuccess) {
			reset()
		}
	}, [isSubmitSuccess])

	return (
		<Grid>
			<GridItem columnStart="span 8">
				<TextField name="cost" label="Precio" inverted />
			</GridItem>
			<GridItem columnStart="span 4">
				<Select
					options={unitsSelect}
					name="unit"
					label="Unidad"
					defaultValue={unitsSelectValue}
					placeholder="Unidad"
					inverted
				/>
			</GridItem>
			<GridItem columnStart="span 12">
				<Datepicker name="date" label="Válido a partir de" inverted />
			</GridItem>
			<GridItem columnStart="span 12">
				<Textarea
					name="comment"
					label="Comentario"
					placeholder="Escribe el comentario"
					inverted
				/>
			</GridItem>
			<GridItem columnStart="span 12">
				<Flex justifyContent="flex-start">
					<Button
						label={'Crear precio'}
						type="submit"
						theme="primary"
						size="large"
					/>
				</Flex>
			</GridItem>
		</Grid>
	)
}

const DialogCostsNewPrice = ({ data, closeModal }) => {
	const [isSubmitSuccess, setIsSubmitSuccess] = useState(false)
	const queryClient = useQueryClient()
	const { notification } = useNotifications()

	const changeModeRef = useRef(null)

	/*const parsedData = useMemo(() => {
		return Object.keys(data).reduce((result, key) => {
			return {
				...result,
				[key]: key === 'cost' ? Number(data[key]) : data[key]
			}
		}, {})
	}, [data])*/

	const [historicMode, setHistoricMode] = useState('list')
	const { formatObject } = useNumber()

	const priceBaseKey = useMemo(() => ['costs', 'prices', data.id], [data])
	const historicBaseKey = useMemo(
		() => ['costs', 'prices', data.id, 'historic'],
		[data]
	)

	const { useRead } = useCRUD({
		baseKey: historicBaseKey,
		url: `costs/prices/${data.slug}/${data.id}`
	})
	const { useCreate } = useCRUD({
		baseKey: priceBaseKey,
		url: `costs/prices`
	})
	const { useDelete } = useCRUD({
		baseKey: historicBaseKey,
		url: `costs/prices`
	})
	const { useUpdate } = useCRUD({
		baseKey: historicBaseKey,
		url: `costs/prices`
	})

	const { data: dataHistoric, status: statusHistoric } = useRead()

	const { mutateAsync: createPrice, status: statusCreate } = useCreate({
		config: {
			onSuccess: data => {
				queryClient.invalidateQueries(['costs', 'prices'])

				setIsSubmitSuccess(true)

				//closeModal()
			}
		}
	})

	const { mutateAsync: deletePrice, status: statusDelete } = useDelete({
		config: {
			onSuccess: data => {
				queryClient.invalidateQueries(['costs', 'prices'])

				changeModeRef.current(true)
				setHistoricMode('list')

				notification({
					title: 'Precio eliminado correctamente',
					id: 'historic-delete-price',
					type: 'success'
				})
			}
		}
	})

	const { mutateAsync: updatePrice, status: statusUpdate } = useUpdate({
		config: {
			onSuccess: data => {
				queryClient.invalidateQueries(['costs', 'prices'])
			}
		}
	})

	const handleSubmit = useCallback(
		async ({ cost, date, unit, comment }) => {
			createPrice({
				id: data.id,
				tab_id: data?.slug,
				unit,
				cost,
				date,
				comment
			})
		},
		[data, createPrice]
	)

	const handleError = useCallback(error => {
		console.log(error)
	}, [])

	const handleDeleteHistoricItem = useCallback(
		data => {
			deletePrice({ id: data?.id })
		},
		[deletePrice]
	)

	const handleUpdateHistoricItem = useCallback(
		data => {
			updatePrice(data)
		},
		[updatePrice]
	)

	const historicData = useMemo(() => {
		if (!!dataHistoric) {
			return !dataHistoric.length
				? []
				: dataHistoric.map(({ id_price, ...restItem }) => {
						return {
							...restItem,
							slug: data.slug,
							id: id_price,
							active: id_price === data.id_price
						}
				  })
		} else {
			return []
		}
	}, [dataHistoric, data])

	return (
		<Grid>
			<GridItem columnStart="span 7">
				<Form
					onSubmit={handleSubmit}
					onError={handleError}
					loading={statusCreate === 'loading'}
					disabled={historicMode === 'edit'}
					schema={schema}
					hasPrompt={false}
					useFormProps={{
						mode: 'onChange',
						defaultValues: {
							cost: '',
							date: new Date(),
							comment: ''
						}
					}}
				>
					<DialogCostsNewPriceInner
						isSubmitSuccess={isSubmitSuccess}
						slug={data?.slug}
					/>
				</Form>
			</GridItem>
			<GridItem columnStart="span 5">
				<Historic
					id={data?.id}
					changeModeRef={changeModeRef}
					loading={
						statusHistoric === 'loading' ||
						statusDelete === 'loading' ||
						statusUpdate === 'loading'
					}
					items={historicData}
					onChangeMode={mode => void setHistoricMode(mode)}
					onDelete={handleDeleteHistoricItem}
					onUpdate={handleUpdateHistoricItem}
				/>
			</GridItem>
		</Grid>
	)
}

export default DialogCostsNewPrice

import React from 'react'
import styled, { css } from 'styled-components/macro'

const StyledPill = styled.span`
	font-size: 14px;
	font-weight: 600;
	line-height: 1;
	background-color: var(--c__white);
	color: var(--c__grey-700);
	border: 1px solid var(--c__grey);
	padding: 5px 7px;
	display: inline-block;
	border-radius: 5px;
	transition: all 150ms ease-out;

	${({ $hasMargin }) =>
		!!$hasMargin &&
		css`
			margin-right: 5px;
		`}

	${({ $theme }) => {
		if ($theme === 'error') {
			return css`
				background-color: var(--c__red);
				border-color: var(--c__red);
				color: var(--c__white);
			`
		} else if ($theme === 'success') {
			return css`
				background-color: var(--c__green);
				border-color: var(--c__green);
				color: var(--c__white);
			`
		} else if ($theme === 'info') {
			return css`
				background-color: var(--c__white);
				border-color: var(--c__grey-300);
				color: var(--c__grey-700);
			`
		} else if ($theme === 'blue') {
			return css`
				background-color: var(--c__info);
				border-color: var(--c__info);
				color: var(--c__white);
			`
		}
	}}
`

function Pill({ text = null, theme = 'default', hasMargin = true }) {
	if (!text) return null

	return (
		<StyledPill className="pill" $theme={theme} $hasMargin={hasMargin}>
			{text}
		</StyledPill>
	)
}

export default Pill

import React, { useRef, useState, useEffect } from 'react'
import { isFunction } from 'lodash'
import styled from 'styled-components/macro'

import { ReactComponent as CloseIcon } from '@svgs/close.svg'

import { Modal } from '@components/modal'

const StyledDialog = styled.div`
	width: 100%;
	height: 100%;
	background-color: var(--c__white);
	border-radius: var(--modal__border-radius);
	padding: 20px;

	& > header {
		height: 100%;
		width: 100%;
		margin-bottom: 20px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
		flex: 1;
		padding-bottom: 15px;
		border-bottom: 1px solid var(--c__grey-300);

		& > div {
			display: flex;
			align-items: center;
			width: calc(100% - 30px);

			svg {
				width: 20px;
				height: 20px;
				fill: var(--c__grey-700);
				margin-right: 15px;
				margin-top: -5px;
			}
			& > p {
				font-size: 14px;
				font-weight: 900;
				color: var(--c__grey-700);
				letter-spacing: 0.5px;
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;
				flex: 1;
				text-transform: uppercase;
			}
		}

		button {
			width: 30px;
			height: 30px;
			color: var(--c__grey-700);
			display: flex;
			align-items: center;
			justify-content: center;
			transition: all 150ms ease-out;

			&:hover {
				color: var(--c__grey-700);
			}

			svg {
				width: 50%;
				height: 50%;
				fill: currentColor;
				margin-right: 0;
			}
		}
	}
`
const DialogInner = ({
	children,
	id,
	setDismissable,
	dismissable,
	hasHeader,
	hasCloseButton,
	data,
	title
}) => {
	useEffect(() => {
		data.updateOptions({ dismissable })
	}, [dismissable])

	return (
		<StyledDialog>
			{!!hasHeader && (
				<header>
					<div>
						{data.headerIcon && data.headerIcon}
						<p>{data.headerTitle || title}</p>
					</div>
					{!!hasCloseButton && (
						<button
							type="button"
							onClick={e => !!dismissable && data.closeModal(id)}
						>
							<CloseIcon />
						</button>
					)}
				</header>
			)}
			<section>
				{isFunction(children)
					? children({ ...data, setDismissable })
					: children}
			</section>
		</StyledDialog>
	)
}
const Dialog = ({
	children,
	id = '',
	title = '',
	hasCloseButton = true,
	hasHeader = true,
	...restProps
}) => {
	const portalRef = useRef(document.querySelector('body'))
	const [dismissable, setDismissable] = useState(true)

	return (
		<Modal
			id={id}
			width={600}
			portalElement={portalRef.current}
			dismissable={dismissable}
			{...restProps}
		>
			{data => (
				<DialogInner
					children={children}
					data={data}
					title={title}
					dismissable={dismissable}
					hasHeader={hasHeader}
					setDismissable={setDismissable}
					hasCloseButton={hasCloseButton}
				/>
			)}
		</Modal>
	)
}

export default Dialog

import { isFunction } from 'lodash'

export function additionalProps(restProps, data) {
	return Object.keys(restProps).reduce((ret, cur) => {
		ret[cur] = isFunction(restProps[cur])
			? !!data
				? restProps[cur](data)
				: restProps[cur]
			: restProps[cur]
		return ret
	}, {})
}

import React, { useCallback, useMemo } from 'react'
import styled, { css } from 'styled-components'
import dayjs from 'dayjs'
import ReactHTMLParser from 'html-react-parser'

import { ReactComponent as CloseIcon } from '@svgs/close.svg'

import useAlerts from '@hooks/alerts'

import { Button } from '@components/button'
import Pill from '@components/Pill'

const StyledAlertsList = styled.div`
	display: flex;
	flex-flow: column;
	gap: 0;
`

const StyledAlertsListItem = styled.section`
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	gap: 15px;
	padding: 15px 10px 15px;
	transition: all 150ms ease-out;
	position: relative;
	border-bottom: 1px solid var(--c__grey-200);

	@media (min-width: 768px) {
		padding: 15px 10px 15px 20px;
	}

	&:hover {
		background-color: var(--c__grey-100);
	}

	${({ $loading }) =>
		!!$loading &&
		css`
			opacity: 0.5;
			pointer-events: none;
		`}

	& > a {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 2;
	}

	& > div {
		display: flex;
		flex-flow: column;
		gap: 12px;
		font-size: 14px;

		@media (min-width: 768px) {
			font-size: 15px;
		}

		&:last-child {
			flex-flow: row;
			align-items: center;
			justify-content: center;
		}

		button {
			padding: 0;
			width: 10px;
			height: 10px;
			color: var(--c__grey-500);

			@media (min-width: 768px) {
				width: 15px;
				height: 15px;
			}

			&:hover {
				color: var(--c__red);
			}
		}
	}
`
const StyledAlertsListItemText = styled.article`
	p {
		font-size: 1.15em;
		font-weight: 600;
		color: var(--c__grey-900);
	}
`
const StyledAlertsListItemDate = styled.time`
	font-size: 0.8em;
	font-weight: 500;
	color: var(--c__red);
`
const StyledAlertsListItemPills = styled.div`
	display: flex;
	gap: 5px;

	.pill {
		font-size: 12px;
		margin-right: 0;
	}
`
const StyledAlertsListItemSentBy = styled.p`
	font-size: 12px;
`

function AlertsListItem({ id, text, sender, status, createdAt }) {
	const { useDelete, parseNewLines } = useAlerts()

	const { mutateAsync: deleteAlert, status: statusDelete } = useDelete(id)

	const parsedText = useMemo(
		() => ReactHTMLParser(parseNewLines(text)),
		[text, parseNewLines]
	)

	const handleItemDelete = useCallback(() => {
		deleteAlert({ id })
	}, [id])

	return (
		<StyledAlertsListItem $loading={statusDelete === 'loading'}>
			<div>
				<StyledAlertsListItemText>{parsedText}</StyledAlertsListItemText>
				{/* <StyledAlertsListItemDate
					datetime={dayjs(createdAt).format('YYYY-MM-DD')}
				>
					{dayjs(createdAt).fromNow()}
				</StyledAlertsListItemDate> */}
				<StyledAlertsListItemPills>
					<Pill
						text={status === 'sent' ? 'Enviado' : 'Entrante'}
						theme={status === 'sent' ? 'blue' : 'error'}
					/>
					<Pill text={dayjs(createdAt).fromNow()} theme="info" />
				</StyledAlertsListItemPills>
				{sender && (
					<StyledAlertsListItemSentBy>
						Mensaje de{' '}
						<strong>{`${sender?.name}${
							sender?.surname ? ` ${sender?.surname}` : ''
						}`}</strong>
					</StyledAlertsListItemSentBy>
				)}
			</div>
			<div>
				<Button
					icon={<CloseIcon />}
					title="Eliminar alerta"
					onClick={handleItemDelete}
				/>
			</div>
		</StyledAlertsListItem>
	)
}
function AlertsList({ items = [], onItemDelete = () => {} }) {
	return (
		<>
			{!!items.length && (
				<StyledAlertsList>
					{items.map(item => (
						<AlertsListItem key={item.id} {...item} onDelete={onItemDelete} />
					))}
				</StyledAlertsList>
			)}
		</>
	)
}

export default AlertsList

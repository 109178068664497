import React from 'react'
import styled from 'styled-components/macro'

const StyledContent = styled.div`
	width: 100%;
	margin-bottom: ${({ $gutterBottom }) => $gutterBottom};
`

function Content({ children, gutterBottom = '100px' }) {
	return <StyledContent $gutterBottom={gutterBottom}>{children}</StyledContent>
}

export default Content

import React from 'react'
import styled from 'styled-components/macro'

import { Grid, GridItem } from '@components/grid'

const StyledLayoutSidebar = styled.aside`
	width: 100%;
	position: sticky;
	top: 20px;
`

function LayoutContentSidebar({ children, sidebar = null }) {
	return (
		<Grid gap={'var(--grid__gap--large)'}>
			<GridItem columnStart={1} columnEnd={sidebar ? 'span 9' : '-1'}>
				{children}
			</GridItem>
			{sidebar && (
				<GridItem columnStart={10} columnEnd={-1}>
					<StyledLayoutSidebar>{sidebar}</StyledLayoutSidebar>
				</GridItem>
			)}
		</Grid>
	)
}

export default LayoutContentSidebar

import React, { lazy, useEffect } from 'react'
import { Routes, Route } from 'react-router-dom'

import NotFound from '@not-found/NotFound'

import Login from '@auth/Login'
import ForgotPassword from '@auth/ForgotPassword'
import ResetPassword from '@auth/ResetPassword'

function AuthRouter() {
	return (
		<Routes>
			<Route path="login" element={<Login />} />
			<Route path="forgot-password" element={<ForgotPassword />} />
			<Route path="reset-password" element={<ResetPassword />} />
			<Route path="*" element={<NotFound />} />
		</Routes>
	)
}

export default AuthRouter

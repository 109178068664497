import React, { createContext, useContext, useReducer } from 'react'
import { createPortal } from 'react-dom'

import notificationsReducer from './reducer'
import Notifications from './Notifications'

const NotificationsContext = createContext()

const API = (dispatch, state) => ({
	notification: payload => {
		dispatch({
			type: 'ADD_NOTIFICATION',
			payload
		})
	},
	dismiss: id => {
		dispatch({
			type: 'DISMISS_NOTIFICATION',
			payload: { id }
		})
	},
	clear: () => {
		dispatch({
			type: 'CLEAR_NOTIFICATIONS',
			payload: {}
		})
	}
})

export const NotificationsProvider = ({ children }) => {
	const [state, dispatch] = useReducer(notificationsReducer, [])
	if (process.env.NODE_ENV !== 'production') {
		//console.log('[Notifications]', state)
	}
	return (
		<NotificationsContext.Provider value={API(dispatch, state)}>
			{children}
			{createPortal(<Notifications notifications={state} />, document.body)}
		</NotificationsContext.Provider>
	)
}

export const useNotifications = () => {
	const context = useContext(NotificationsContext)
	if (context === undefined) {
		throw new Error(
			'useNotifications must be used within a NotificationsProvider'
		)
	}
	return context
}

import { useState, useCallback, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'

import { PROMPT_STORAGE_KEY } from '@constants'

import useStore from '@store/store'

function usePromptForm(defaultBlocked = false) {
	const [blocked, setBlocked] = useState(defaultBlocked)
	const { formState } = useFormContext()
	const { isDirty } = formState

	const updateBlocked = useCallback(_blocked => {
		setBlocked(_blocked)
	}, [])

	useEffect(() => {
		if (!!isDirty) {
			setBlocked(true)
		}
	}, [isDirty])

	return { blocked, updateBlocked }
}

function usePromptStatus() {
	const promptStatus = useStore(
		({ promptStatus }) =>
			window.localStorage.getItem(PROMPT_STORAGE_KEY) || promptStatus
	)
	const set = useStore(({ set }) => set)

	const getPromptStatus = useCallback(() => promptStatus, [promptStatus])

	const canShowPrompt = useCallback(() => promptStatus === 'active', [
		promptStatus
	])

	const setPromptStatus = useCallback(
		status => {
			set(state => {
				window.localStorage.setItem(PROMPT_STORAGE_KEY, status)
				state.promptStatus = status
			})
		},
		[set]
	)

	return { getPromptStatus, canShowPrompt, setPromptStatus }
}

export { usePromptForm, usePromptStatus }

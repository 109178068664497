import React, {
	Fragment,
	useRef,
	useState,
	useMemo,
	useEffect,
	useCallback
} from 'react'
import styled from 'styled-components'
import { createPortal } from 'react-dom'
import classNames from 'classnames'
import { isString } from 'lodash'
import { motion, AnimatePresence } from 'framer-motion'
import { useDebounce } from 'use-debounce'

import SidePanelOverlay from './SidepanelOverlay'
import { additionalProps } from './helpers'

const StyledSidePanel = styled(motion.div)`
	position: fixed;
	overflow: hidden;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 99999;
	backface-visibility: hidden;
	transform-style: preserve-3d;
	display: flex;
	align-items: flex-start;
	justify-content: flex-end;
	cursor: ${({ dismissable }) => (dismissable ? 'pointer' : 'default')};
`

const StyledSidePanelBox = styled.div`
	width: ${({ width }) => (isString(width) ? width : `${width}px`)};
	height: 100%;
	cursor: auto;
`

function SidePanel({
	children,
	hasPortal = true,
	hasOverlay = true,
	isOpened = false,
	dismissable = true,
	width = 500,
	portalSelector = '#sidepanel',
	overlayProps = {},
	onOpen = () => {},
	onClose = () => {}
}) {
	const [isInternalOpened, setInternalOpened] = useState(isOpened)
	const [mounted, setMounted] = useState(false)

	const portalSelectorRef = useRef()
	const sidepanel = useRef()
	const [debouncedIsOpened] =
		useDebounce(isInternalOpened, isInternalOpened ? 0 : 1) || false

	const variants = useMemo(() => ({
		initial: {
			transform: 'translateX(100%)',
			opacity: 0
		},
		open: {
			transform: 'translateX(0%)',
			opacity: 1,
			transition: {
				type: 'spring',
				bounce: 0,
				duration: 0.4,
				delay: isInternalOpened ? 0.1 : 0
			}
		},
		close: {
			transform: 'translateX(100%)',
			opacity: 0,
			transition: {
				duration: 0.3
			}
		}
	}))

	const close = useCallback(() => {
		!!dismissable && setInternalOpened(false)
	}, [dismissable])

	const handleClickClose = e => {
		if (e.target === sidepanel.current) {
			e.preventDefault()
			close()
		}
	}

	const onEscape = useCallback(
		e => {
			if (e.keyCode === 27 && !!dismissable) {
				close()
			}
		},
		[dismissable]
	)

	function handleOpen() {
		lockBodyScroll()
		onOpen(sidepanel.current)
	}

	function handleClose() {
		unlockBodyScroll()
		onClose(sidepanel.current)
	}

	function lockBodyScroll() {
		document.body.style.overflow = 'hidden'
	}

	function unlockBodyScroll() {
		document.body.style.overflow = ''
	}

	useEffect(() => {
		window.addEventListener('keydown', onEscape, false)

		return () => {
			window.removeEventListener('keydown', onEscape, false)
			setInternalOpened(true)
			unlockBodyScroll()
		}
	}, [dismissable])

	useEffect(() => {
		setInternalOpened(isOpened)
	}, [isOpened])

	useEffect(() => {
		portalSelectorRef.current = document.querySelector(portalSelector)
		setMounted(true)
	}, [portalSelector])

	const SidePanelComponent = (
		<Fragment>
			{hasOverlay && (
				<SidePanelOverlay
					isOpened={isInternalOpened}
					dismissable={dismissable}
					{...additionalProps(overlayProps, { isOpened: isInternalOpened })}
				/>
			)}
			<AnimatePresence
				onExitComplete={() => (isInternalOpened ? handleOpen() : handleClose())}
			>
				{debouncedIsOpened && (
					<StyledSidePanel
						key="sidepanel"
						ref={sidepanel}
						className={classNames({
							sidepanel: true,
							'sidepanel--opened': isInternalOpened,
							'sidepanel--closed': !isInternalOpened
						})}
						initial={variants.initial}
						animate={variants.open}
						exit={variants.close}
						onClick={handleClickClose}
					>
						<StyledSidePanelBox width={width}>
							{children({
								isOpened: isInternalOpened,
								sidepanel: sidepanel.current
							})}
						</StyledSidePanelBox>
					</StyledSidePanel>
				)}
			</AnimatePresence>
		</Fragment>
	)

	return hasPortal
		? mounted
			? createPortal(SidePanelComponent, portalSelectorRef.current)
			: null
		: SidePanelComponent
}

export default SidePanel

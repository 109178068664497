import * as yup from 'yup'
import 'yup-phone'
import { parse } from 'numerable'
import { es } from 'numerable/locale'
import isNumber from 'is-number'

const locales = {
	es: {
		locale: es,
		pattern: '0,0[.]0[000000]'
	}
}

const currentLocale = 'es'

const parseFn = (value, options = {}) => {
	return parse(value, {
		locale: locales[currentLocale].locale,
		defaultPattern: locales[currentLocale].pattern,
		rounding: null,
		...options
	})
}

yup.addMethod(yup.string, 'integer', function (name) {
	return this.matches(/^\d+$/, name)
})

yup.addMethod(yup.string, 'validatePhone', function (...args) {
	return this.test('test-phone', 'El teléfono no es correcto', value => {
		if (value) return this.phone(...args).isValidSync(value)
		return true
	})
})

yup.addMethod(yup.array, 'selectMulti', function (...args) {
	return this.test(
		'select_multi_required',
		'Elige una opción',
		item => item.length > 0
	)
})

yup.addMethod(yup.string, 'numberLocale', function (...args) {
	return this.test('number_locale', 'Debe ser un número', value => {
		const isSpanishNumberRegex = /^\d+(,?\d+)?$/g
		const selectAllDotsRegex = /(\.*)/gi

		if (isSpanishNumberRegex.test(value.replace(selectAllDotsRegex, ''))) {
			return isNumber(parseFn(value))
		} else {
			return false
		}
	})
})

yup.setLocale({
	mixed: {
		required: 'El campo es obligatorio',
		typeError: 'El campo es obligatorio'
	},
	number: {
		required: 'El valor debe ser un número'
	},
	string: {
		email: 'El email no es correcto',
		min: '${min} caracteres como mínimo'
	}
})

export default yup

import React, {
	useCallback,
	useState,
	useRef,
	useEffect,
	useContext,
	createContext
} from 'react'
import { isFunction } from 'lodash'

import ModalComponent from './components/Modal'

const ModalContext = createContext({
	modal: { id: '', props: {}, options: {} },
	openModal: () => {},
	closeModal: () => {}
})

const ModalConsumer = ModalContext.Consumer

const Modal = ({ id, children, ...restProps }) => {
	const propsRef = useRef(null)

	return (
		<ModalContext.Consumer>
			{({ closeModal, updateOptions, modal }) => {
				if (!!id && id !== modal.id && !!modal.isOpen) return null

				if (!!id && id === modal.id && !!modal.isOpen) {
					propsRef.current = modal.props
				}

				return (
					<ModalComponent
						isOpened={modal.isOpen}
						onClose={() => closeModal()}
						{...modal.options}
						{...restProps}
					>
						{() =>
							children({
								closeModal,
								updateOptions,
								isOpened: modal.isOpen,
								...propsRef.current
							})
						}
					</ModalComponent>
				)
			}}
		</ModalContext.Consumer>
	)
}

const ModalProvider = ({ children }) => {
	const [modal, setModal] = useState({ id: null, props: {}, options: {} })
	const canShow = useRef(false)

	useEffect(() => {
		canShow.current = isFunction(modal.id)
	}, [modal])

	return (
		<ModalContext.Provider
			value={{
				modal,
				openModal: (id, props = {}, options = {}) =>
					setModal({ ...modal, id, props, options, isOpen: true }),
				closeModal: id => setModal({ ...modal, id, isOpen: false }),
				updateOptions: (options = {}) => setModal({ ...modal, ...options })
			}}
		>
			{children}
			{canShow.current && <Modal>{modal}</Modal>}
		</ModalContext.Provider>
	)
}

const useModal = (id, props = {}, options = {}) => {
	const context = useContext(ModalContext)

	if (context === undefined) {
		throw new Error('useModal must be used within a ModalProvider')
	}

	const openModal = useCallback(
		(data = {}) => context.openModal(id, { ...props, ...data }),
		[]
	)
	const closeModal = useCallback(
		(data = {}) => context.closeModal(id, { ...props, ...data }),
		[]
	)

	const updateOptions = useCallback(
		(data = {}) => context.updateOptions(id, { ...props, ...data }),
		[]
	)

	return { openModal, closeModal, updateOptions }
}

const withModal = WrappedComponent => () => {
	const context = useContext(ModalContext)

	if (context === undefined) {
		throw new Error('useModal must be used within a ModalProvider')
	}

	const openModal = useCallback((...args) => context.openModal(...args), [])
	const closeModal = useCallback((...args) => context.closeModal(...args), [])
	const updateOptions = useCallback(
		(...args) => context.updateOptions(...args),
		[]
	)

	return (
		<WrappedComponent
			openModal={openModal}
			closeModal={closeModal}
			updateOptions={updateOptions}
		/>
	)
}

export {
	ModalProvider,
	ModalConsumer,
	ModalContext,
	Modal,
	useModal,
	withModal
}

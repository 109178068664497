import React from 'react'
import styled from 'styled-components/macro'

const StyledPreloaderFull = styled.aside`
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 99999999;
	background-color: rgba(0, 0, 0, 0.1);
	display: flex;
	align-items: center;
	justify-content: center;

	& > section {
		max-width: 500px;
		display: flex;
		flex-flow: column;
		align-items: center;

		h1 {
			font-size: 40px;
			font-weight: 700;
		}
	}
`

function PreloaderFull({ title = 'Cargando' }) {
	return (
		<StyledPreloaderFull>
			<section>
				<h1>{title}</h1>
			</section>
		</StyledPreloaderFull>
	)
}

export default PreloaderFull

import React from 'react'
import styled from 'styled-components/macro'

import { ReactComponent as NotFoundIcon } from '@svgs/alert.svg'

const StyledNotFound = styled.section`
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
`
const StyledNotFoundBox = styled.div`
	max-width: 900px;
	width: 100%;
	background-color: var(--c__white);
	border-radius: var(--border__radius);
	box-shadow: var(--dashboard__shadow);
	padding: 30px;
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 20px;
`
const StyledNotFoundGraphic = styled.figure`
	display: flex;
	align-items: center;
	justify-content: center;

	svg {
		width: 70%;
		height: 70%;
		fill: var(--c__green);
	}
`
const StyledNotFoundBody = styled.div`
	display: flex;
	flex-flow: column;
	align-items: center;
	justify-content: center;
	gap: 20px;
	color: var(--c__grey-700);

	h1 {
		font-size: 150px;
		font-weight: 900;
		line-height: 0.9;
		margin: 0;
		text-align: center;
	}

	h4 {
		font-size: 28px;
		line-height: 1.2;
		text-align: center;
	}

	article {
		display: flex;
		flex-flow: column;
		justify-content: center;
		gap: 20px;
		text-align: center;

		p {
			font-size: 16px;
		}
	}
`

function NotFound() {
	return (
		<StyledNotFound>
			<StyledNotFoundBox>
				<StyledNotFoundGraphic>
					<NotFoundIcon />
				</StyledNotFoundGraphic>
				<StyledNotFoundBody>
					<h1>404</h1>
					<h4>¡Vaya! Parece que la página que buscas, no existe</h4>
					<article>
						<p>
							Prueba a seguir navegando y si el problema persiste, actualiza el
							navegador si es necesario.
						</p>
					</article>
				</StyledNotFoundBody>
			</StyledNotFoundBox>
		</StyledNotFound>
	)
}

export default NotFound

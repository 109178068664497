import React from 'react'
import styled, { css } from 'styled-components/macro'

const StyledLabel = styled.label`
	font-size: 14px;
	color: var(--c__grey-700);
	font-weight: 700;
	margin-bottom: 12px;
	display: block;

	@media (min-width: 768px) {
		font-size: 16px;
	}

	${({ $size }) =>
		$size === 'small' &&
		css`
			font-size: 14px;
		`}
`

function Label({ children, title, size = 'default', ...restProp }) {
	return (
		<StyledLabel $size={size} {...restProp}>
			<span>{title}</span>
			{children}
		</StyledLabel>
	)
}

export default Label

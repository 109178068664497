import React, { useCallback } from 'react'
import { useQueryClient } from 'react-query'

import useCRUD from '@hooks/crud'

function useMachineryTasks({ machine_id }) {
	const queryClient = useQueryClient()

	const useFetchAll = () => {
		const { useRead } = useCRUD({
			baseKey: [`machinery_tasks`, { machine_id }],
			url: 'machinery_tasks'
		})

		return useRead({
			config: {
				onSuccess: data => {
					data.children.forEach(child => {
						queryClient.setQueryData(
							[`machinery_tasks`, { machine_id }, child.id],
							child
						)
					})
				}
			},
			ajax: {
				params: {
					machine_id
				}
			}
		})
	}

	const useFetchCollection = (collection = []) => {
		const { useReadCollection } = useCRUD({
			baseKey: ({ id }) => [`machinery_tasks`, id],
			url: 'machinery_tasks'
		})

		return useReadCollection(collection, {
			ajax: {
				params: {
					machine_id
				}
			}
		})
	}

	const useCreate = () => {
		const baseKey = [`machinery_tasks`, { machine_id }]

		const { useCreate } = useCRUD({
			baseKey,
			url: 'machinery_tasks'
		})

		return useCreate()
	}

	const useUpdate = () => {
		const baseKey = [`machinery_tasks`, { machine_id }]

		const { useUpdate } = useCRUD({
			baseKey,
			url: 'machinery_tasks'
		})

		return useUpdate({
			config: {
				onMutate: async data => {
					await queryClient.cancelQueries(baseKey)

					const previousItems = queryClient.getQueryData(baseKey)

					if (previousItems && previousItems?.children) {
						queryClient.setQueryData(baseKey, old => {
							return {
								...old,
								children: old.children.map(item => {
									if (item.id === data?.id) {
										return { ...item, ...data }
									} else {
										return item
									}
								})
							}
						})
					}

					return { previousItems }
				},
				onError: (err, newTodo, context) => {
					queryClient.setQueryData(baseKey, context.previousItems)
				},
				onSettled: async (data, error, variables, context) => {
					queryClient.setQueryData([
						'machinery_tasks',
						{ machine_id },
						data?.id
					])
					queryClient.invalidateQueries(baseKey, { exact: true })
				}
			}
		})
	}

	const useDelete = () => {
		const baseKey = [`machinery_tasks`, { machine_id }]

		const { useDelete } = useCRUD({
			baseKey,
			url: 'machinery_tasks'
		})

		return useDelete({
			config: {
				onMutate: async itemId => {
					await queryClient.cancelQueries(baseKey)

					const previousItems = queryClient.getQueryData(baseKey)

					if (previousItems && previousItems?.children) {
						queryClient.setQueryData(baseKey, old => {
							const id = itemId?.id || itemId
							return {
								...old,
								children: old.children.filter(o => o.id !== id) || old?.children
							}
						})
					}

					return { previousItems }
				},
				onError: (err, newTodo, context) => {
					queryClient.setQueryData(baseKey, context.previousItems)
				},
				onSettled: async (data, error, variables, context) => {
					queryClient.removeQueries([
						'machinery_tasks',
						{ machine_id },
						data.id
					])
					queryClient.invalidateQueries(baseKey, { exact: true })
				}
			}
		})
	}

	return { useFetchAll, useFetchCollection, useCreate, useUpdate, useDelete }
}

export default useMachineryTasks

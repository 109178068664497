import React, { useCallback, useMemo } from 'react'
import styled from 'styled-components/macro'
import { useFormContext } from 'react-hook-form'
import { omit } from 'lodash'

import yup from '@yup'

import useSelect from '@hooks/select'
import { useUtils } from '@hooks/utils'
import useMachineryTasks from '@hooks/machinery-tasks'
import useNumber from '@hooks/number'

import { Form, TextField, Select, Textarea, Datepicker } from '@components/form'
import { Button } from '@components/button'
import { Grid, GridItem } from '@components/grid'
import { Flex } from '@components/flex'

const StyledMachineryTask = styled.div`
	width: 100%;
	height: 100%;
`

const schema = yup.object().shape({
	// title: yup.string().required(),
	date: yup.string().required(),
	type: yup.string().required('Elige una opción').nullable()
	// cost: numberValidationPositiveWithZero.required()
})

const MachineryTaskInner = ({ isEdit, onDelete = () => {} }) => {
	const { getValues } = useFormContext()
	const data = getValues()

	console.log(data)

	const typesSelectBase = useMemo(() => {
		return [
			{
				id: 'itv/iteaf',
				title: 'ITV/ITEAF'
			},
			{
				id: 'mantenimiento',
				title: 'Mantenimiento'
			},
			{
				id: 'limpieza',
				title: 'Limpieza (ECO)'
			},
			{
				id: 'reparaciones',
				title: 'Reparaciones'
			}
		]
	}, [])

	const { select: typesSelect, selectValue: typesSelectValue } = useSelect({
		name: 'unit',
		collection: typesSelectBase,
		value: data?.type
	})

	return (
		<Grid>
			<GridItem columnStart="1" columnEnd="-1">
				<TextField
					name="title"
					label="Nombre *"
					placeholder="Escribe el nombre"
					value={data?.title}
					inverted
				/>
			</GridItem>
			<GridItem columnStart="1" columnEnd="-1">
				<Select
					options={typesSelect}
					name="type"
					label="Tipo de tarea *"
					placeholder="Selecciona un tipo de tarea"
					defaultValue={typesSelectValue}
					inverted
				/>
			</GridItem>
			<GridItem columnEnd="span 6">
				<Datepicker
					name="date"
					label="Fecha *"
					defaultValue={data?.date}
					inverted
				/>
			</GridItem>
			<GridItem columnEnd="span 6">
				<TextField
					name="cost"
					label="Coste *"
					placeholder="Escribe el coste"
					value={data?.cost}
					inverted
				/>
			</GridItem>
			<GridItem columnStart="1" columnEnd="-1">
				<Textarea
					name="observations"
					label="Observaciones"
					placeholder="Escribe observaciones"
					defaultValue={data?.observations}
					inverted
				/>
			</GridItem>
			<GridItem columnStart="1" columnEnd="-1">
				<Flex
					justifyContent={isEdit ? 'space-between' : 'flex-end'}
					alignItems="center"
				>
					{isEdit && (
						<Button
							label={'Eliminar tarea'}
							type="button"
							theme="danger"
							onClick={e => onDelete(data?.id)}
						/>
					)}
					<Button
						label={isEdit ? 'Actualizar tarea' : 'Crear tarea'}
						type="submit"
						theme="primary"
						size="large"
					/>
				</Flex>
			</GridItem>
		</Grid>
	)
}

const DialogMachineryTask = ({ item, closeModal }) => {
	const { exclude } = useUtils()
	const { formatObject } = useNumber()
	const { data, isEdit } = item
	const { useCreate, useUpdate, useDelete } = useMachineryTasks({
		machine_id: isEdit ? data?.machine_id : data?.id
	})

	const { mutateAsync: createTask, status: statusCreate } = useCreate()
	const { mutateAsync: updateTask, status: statusUpdate } = useUpdate()
	const { mutateAsync: deleteTask, status: statusDelete } = useDelete()

	const handleDelete = useCallback(async id => {
		await deleteTask({ id })
		closeModal()
	}, [])

	const handleSubmit = async formData => {
		if (isEdit) {
			await updateTask({
				...omit(data, ['slug']),
				...omit(formData, ['slug'])
			})
		} else {
			await createTask({
				...formData,
				machine_id: data?.id
			})
		}

		closeModal()
	}

	const handleError = useCallback(error => {
		console.log(error)
	}, [])

	return (
		<StyledMachineryTask>
			<Form
				onSubmit={handleSubmit}
				onError={handleError}
				loading={
					statusCreate === 'loading' ||
					statusUpdate === 'loading' ||
					statusDelete === 'loading'
				}
				schema={schema}
				hasPrompt={false}
				onFilterState={form => {
					console.log(form)
					return exclude(form, ['slug'])
				}}
				useFormProps={{
					mode: 'onChange',
					defaultValues: isEdit ? formatObject(data, ['cost']) : null
				}}
			>
				<MachineryTaskInner
					data={isEdit ? data : null}
					isEdit={isEdit}
					onDelete={handleDelete}
				/>
			</Form>
		</StyledMachineryTask>
	)
}

export default DialogMachineryTask

import React, { useEffect } from 'react'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import './nprogress-override.scss'

function PreloaderTopBar() {
	useEffect(() => {
		NProgress.start()

		return () => {
			NProgress.done()
		}
	})
	return null
}

export default PreloaderTopBar

import React, { useMemo } from 'react'
import styled, { css } from 'styled-components/macro'

import Spinner from './Spinner'

const StyledPreloaderCenter = styled.aside`
	width: 100%;
	height: 100%;
	display: flex;
	flex-flow: column;
	align-items: center;
	justify-content: center;
	gap: 15px;

	${({ $hover }) =>
		!!$hover &&
		css`
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			z-index: 10;
			background: radial-gradient(
				circle,
				rgba(255, 255, 255, 0.8) 0%,
				rgba(255, 255, 255, 0.8) 50%,
				rgba(255, 255, 255, 0) 100%
			);
		`}

	.preloader-icon {
		position: static !important;
		transform: none !important;
	}

	p {
		font-size: 16px;
		text-align: center;
		color: var(--c__grey-700);
	}
`

function PreloaderCenter({
	size = 'normal',
	hover = false,
	title = null,
	...restProps
}) {
	const sizes = useMemo(() => {
		switch (size) {
			case 'large': {
				return {
					size: 50,
					strokeWidth: 13
				}
			}

			case 'medium': {
				return {
					size: 38,
					strokeWidth: 12
				}
			}

			case 'small': {
				return {
					size: 16,
					strokeWidth: 13
				}
			}

			default: {
				return {
					size: 28,
					strokeWidth: 10
				}
			}
		}
	}, [])

	return (
		<StyledPreloaderCenter $hover={hover}>
			<Spinner
				size={sizes.size}
				strokeWidth={sizes.strokeWidth}
				strokeColor="#969696"
				{...restProps}
			/>
			{!!title && <p>{title}</p>}
		</StyledPreloaderCenter>
	)
}

export default PreloaderCenter

import React, { useCallback, useEffect } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import dayjs from 'dayjs'

function useDatepickerRange({ nameStart, nameEnd }) {
	const { control, setValue } = useFormContext()

	const nameStartWatched = useWatch({
		control,
		name: nameStart
	})
	const nameEndWatched = useWatch({
		control,
		name: nameEnd,
		defaultValue: nameStartWatched
	})

	useEffect(() => {
		if (dayjs(nameStartWatched).isSameOrAfter(nameEndWatched)) {
			setValue(nameEnd, nameStartWatched)
		}
	}, [nameStartWatched, nameEndWatched, nameEnd, setValue])

	return {
		rangeStart: dayjs(nameStartWatched).toDate(),
		rangeEnd: dayjs(nameEndWatched).toDate()
	}
}

export default useDatepickerRange

import React, { useMemo } from 'react'
import styled from 'styled-components/macro'
import { motion } from 'framer-motion'
import dayjs from 'dayjs'

import bgSrc from '@images/landing/header-index.png'
import { StyledCard } from '@components/cards'

import { cardIntroAnimation } from './animations'

const StyledCardGreetings = styled(motion(StyledCard))`
	background-image: url(${bgSrc});
	background-size: contain;
	background-repeat: no-repeat;
	background-position: right bottom;
	background-color: var(--c__green);
	box-shadow: var(--card__shadow);
	padding: 30px;

	@media (min-width: 1024px) {
		padding: var(--card__gutter);
		min-height: var(--card__height--feat);
	}

	& > div {
		display: flex;
		flex-flow: column;
		align-items: flex-start;
		justify-content: space-between;
		color: var(--c__white);

		@media (min-width: 1200px) {
			margin-left: var(--card__intro-gutter);
		}

		time {
			font-size: 12px;
			font-weight: 700;
			text-transform: uppercase;

			@media (min-width: 768px) {
				font-size: 14px;
			}
		}

		article {
			margin-top: 10px;
			width: 100%;

			@media (min-width: 1024px) {
				margin-top: 20px;
			}

			h3 {
				font-size: 26px;
				line-height: 1.2;
				margin-bottom: 15px;

				@media (min-width: 568px) {
					font-size: 30px;
				}
				@media (min-width: 768px) {
					font-size: 36px;
				}
				@media (min-width: 1200px) {
					font-size: 46px;
				}
			}

			p {
				font-size: 16px;
				max-width: 80%;

				@media (min-width: 768px) {
					max-width: 50%;
				}

				@media (min-width: $screen-lg-min) {
					max-width: 35%;
				}
			}
		}
	}
`

function CardGreetings({ user }) {
	const date = useMemo(() => dayjs().format('dddd DD MMM YYYY'), [])

	return (
		<StyledCardGreetings
			initial="hidden"
			animate="visible"
			variants={cardIntroAnimation}
		>
			<div>
				<time>{date}</time>
				<article>
					<h3>¡Buenos días {user?.name}!</h3>
					<p>Bienvenido a tu panel de OKCampo, comenzamos.</p>
				</article>
			</div>
		</StyledCardGreetings>
	)
}

export default CardGreetings

import React, { useMemo } from 'react'

import { SELECT_EMPTY_OPTION } from '@constants'

import useCRUD from '@hooks/crud'
import useSelect from '@hooks/select'

import { Select } from '@components/form'
import { PreloaderInside } from '@components/preloaders'

function SelectWarehousesInner({ inverted, warehouse, warehouses }) {
	const finalWarehouses = useMemo(
		() => [{ id: SELECT_EMPTY_OPTION, title: 'Sin almacén' }, ...warehouses],
		[warehouses]
	)

	const { select: warehousesSelect, selectValue: warehousesSelectValue } =
		useSelect({
			name: 'warehouse',
			collection: finalWarehouses,
			value: warehouse?.id
		})

	console.log('warehousesSelectValue', warehousesSelectValue)

	return (
		<Select
			options={warehousesSelect}
			name="warehouse"
			label="Almacén"
			defaultValue={warehousesSelectValue}
			inverted={inverted}
		/>
	)
}

function SelectWarehouses({ warehouse = null, inverted = false }) {
	const { useRead: useWarehouses } = useCRUD({
		baseKey: ['warehouses', { type: 'agriculture' }],
		url: 'warehouses'
	})

	const { data: warehouses, isLoading: isLoadingWarehouses } = useWarehouses()

	return (
		<PreloaderInside loading={isLoadingWarehouses}>
			<SelectWarehousesInner
				inverted={inverted}
				warehouse={warehouse}
				warehouses={warehouses?.children}
			/>
		</PreloaderInside>
	)
}

export default SelectWarehouses

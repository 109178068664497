import React, { useEffect, useMemo, useCallback } from 'react'
import styled, { css } from 'styled-components/macro'
import { motion, AnimatePresence } from 'framer-motion'
import { NavLink, useLocation } from 'react-router-dom'

import { ReactComponent as ArrowDownIcon } from '@svgs/arrow-down.svg'

import SVG from '@components/SVG'

const StyledMenuItem = styled.li`
	font-size: 16px;
	font-weight: 700;
	color: var(--c__grey-450);
	transition: all 150ms ease-out;
	position: relative;
	margin-bottom: 10px;

	&:last-child {
		margin-bottom: 0;
	}

	&:hover {
		color: var(--c__green-700);
	}

	${({ $opened }) =>
		!!$opened &&
		css`
			color: var(--c__green-700);

			& > div {
				&:before {
					opacity: 0.15 !important;
					transform: scale(1) !important;
				}

				& > svg {
					transform: rotate(180deg);
				}
			}
		`}

	${({ $opened }) =>
		!$opened &&
		css`
			& > div {
				& > div {
					& > svg {
						fill: currentColor;

						path {
							fill: currentColor;
						}

						&.map path:first-child,
						&.costs path:first-child,
						&.help path:first-child {
							fill: var(--c__white);
						}
						&.catalogue path:nth-child(2) {
							fill: var(--c__white);
						}
					}
				}
			}
		`}

	& > div {
		display: flex;
		align-items: center;
		justify-content: space-between;
		cursor: pointer;
		position: relative;
		height: 40px;

		&:before {
			content: '';
			position: absolute;
			top: 0;
			bottom: 0;
			left: -10px;
			right: -10px;
			z-index: 1;
			background-color: var(--c__green);
			opacity: 0;
			transform: scale(0.5);
			display: block;
			border-radius: var(--border__radius);
			transition: all 150ms ease-out;
		}

		& > div {
			display: flex;
			flex-flow: row wrap;
			align-items: center;
			position: relative;
			z-index: 2;
			width: calc(100% - 22px);

			svg {
				width: 22px;
				height: 22px;

				path {
					transition: all 150ms ease-out;
				}
			}

			span {
				flex: 1;
				margin-left: 15px;
				line-height: 1;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				display: block;
			}
		}

		& > svg {
			width: 12px;
			height: 12px;
			fill: currentColor;
			position: relative;
			z-index: 2;
			transition: all 150ms ease-out;
		}
	}

	& > ul {
		position: relative;
		z-index: 2;

		li {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			display: block;

			&:first-child {
				padding-top: 10px;
			}

			&:last-child {
				padding-bottom: 10px;

				a {
					margin-bottom: 0;
				}
			}

			a {
				font-size: 14px;
				color: var(--c__grey-450);
				padding: 0 0 0 15px;
				border-left: 3px solid var(--c__white);
				transition: all 150ms ease-out;
				margin: 10px 0;

				&:hover {
					color: var(--c__green-700);
				}

				&.active {
					color: var(--c__green);
					border-color: var(--c__green);
				}
			}
		}
	}
`

function MenuItem({ item, opened = false, onToggle = () => {} }) {
	const location = useLocation()

	const handleLocation = useCallback(() => {
		if (location.pathname === '/') {
			onToggle(null)
		} else {
			if (`/${item.slug}` === location.pathname && !item.active) {
				onToggle(item.id)
			} else {
				if (!!item?.children && !item.active) {
					if (item?.children.some(o => `/${o.slug}` === location.pathname)) {
						onToggle(item.id)
					}
				}
			}
		}
	}, [location])

	const variants = useMemo(() => {
		return {
			opened: {
				opacity: 1,
				scale: 1,
				y: 0,
				height: 'auto'
			},
			closed: {
				opacity: 0,
				scale: 0.5,
				y: -30,
				height: 0
			}
		}
	}, [])

	useEffect(() => {
		handleLocation()
	}, [location])

	return (
		<StyledMenuItem $opened={opened}>
			<div onClick={() => onToggle(item.id)}>
				<div title={item.title}>
					<SVG
						name={`${item.slug}-color`}
						className={item.slug}
						width={22}
						height={22}
					/>
					{item?.children ? (
						<span>{item.title}</span>
					) : (
						<NavLink to={item.slug} title={item.title}>
							<span>{item.title}</span>
						</NavLink>
					)}
				</div>
				{item?.children ? <ArrowDownIcon /> : ''}
			</div>
			<AnimatePresence initial={false}>
				{opened && (
					<motion.ul
						key="content"
						initial="closed"
						animate="opened"
						exit="closed"
						variants={variants}
						transition={{
							type: 'easeOut',
							duration: 0.2
						}}
					>
						{item?.children?.map(child => (
							<li key={child.id}>
								<NavLink to={child.slug} title={child.title}>
									{child.title}
								</NavLink>
							</li>
						))}
					</motion.ul>
				)}
			</AnimatePresence>
		</StyledMenuItem>
	)
}

export default MenuItem

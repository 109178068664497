import React from 'react'
import styled from 'styled-components/macro'
import { BiInfoCircle } from 'react-icons/bi'

import Text from '@components/text/Text'

const StyledDescription = styled.aside`
	margin-top: 20px;

	@media (min-width: 768px) {
		max-width: 50%;
	}

	& > div {
		display: flex;
		align-items: flex-start;
		gap: 10px;
		color: var(--c__grey-500);
		background-color: #fff;
		padding: 20px;
		border-radius: 10px;

		svg {
			width: 30px;
			height: 30px;
			fill: currentColor;
		}

		& > article {
			flex: 1;

			p {
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}
`

function Description({ children, ...restProp }) {
	return (
		<StyledDescription {...restProp}>
			<div>
				<BiInfoCircle />
				<Text>{children}</Text>
			</div>
		</StyledDescription>
	)
}

export default Description

import { useMemo, useEffect } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { isArray } from 'lodash'

import { useUtils } from '@hooks/utils'

function useSelect({ name, collection, value, onChange = () => {} }) {
	const { collectionToSelect } = useUtils()
	const { control, setValue } = useFormContext()

	const { select } = collectionToSelect(collection)

	const selectValue = useMemo(() => {
		let child = select.find(option => option.value === value)

		/**
		 * Si es Multi pasamos de defaultValue un array de ids
		 * y si no queremos nada marcado por defecto, pasamos un array vacío -> []
		 */
		if (isArray(value)) {
			if (!value.length) return []
			child = select.filter(option => value.includes(option.value))
			if (child.length) {
				child = child.map(({ value }) => value)
			}
		}
		return !value || value === '' || !child || !value?.length
			? select[0]
			: child
	}, [value, select])

	const selectBaseValue = useMemo(() => {
		return collection.find(({ id }) => id === selectValue?.value)
	}, [collection, selectValue])

	const watched = useWatch({
		control,
		name,
		defaultValue: selectValue?.value
	})

	useEffect(() => {
		if (watched) {
			onChange({ watched, select, selectValue, selectBaseValue })
		}
	}, [watched])

	return {
		watched,
		select,
		selectValue,
		selectBaseValue
	}
}

export default useSelect

import React, { useState, useCallback } from 'react'
import styled from 'styled-components/macro'

import yup from '@yup'

import useActivities from '@hooks/activities'
import useCRUD from '@hooks/crud'

import { Form, TextField, Textarea } from '@components/form'
import { Button } from '@components/button'
import { Grid, GridItem } from '@components/grid'
import { Flex } from '@components/flex'

const StyledActivitiesPanelNewWarehouse = styled.div`
	width: 100%;
	height: 100%;
`

const schema = yup.object().shape({
	title: yup.string().required()
})

const ActivitiesPanelNewWarehouseInner = () => {
	return (
		<Grid>
			<GridItem columnStart="span 12">
				<TextField
					name="title"
					placeholder="Escribe el nombre"
					label="Nombre *"
					inverted
				/>
			</GridItem>
			<GridItem columnStart="span 12">
				<Textarea
					name="description"
					label="Descripción"
					placeholder="Escribe una descripción"
					inverted
				/>
			</GridItem>
			<GridItem columnStart="span 12">
				<TextField
					name="address"
					label="Dirección"
					placeholder="Escribe la dirección"
					inverted
				/>
			</GridItem>
			<GridItem columnStart="1" columnEnd="-1">
				<Flex justifyContent="flex-end" alignItems="center">
					<Button
						label={'Guardar'}
						type="submit"
						theme="primary"
						size="large"
					/>
				</Flex>
			</GridItem>
		</Grid>
	)
}

const DialogActivitiesNewWarehouse = ({ item, parent, closeModal }) => {
	const { useCreate } = useCRUD({
		baseKey: ['info', 'warehouses'],
		url: 'warehouses'
	})

	const { mutateAsync, status } = useCreate()

	const handleSubmit = async data => {
		await mutateAsync(data)

		closeModal()
	}

	const handleError = useCallback(error => {
		console.log(error)
	}, [])

	return (
		<StyledActivitiesPanelNewWarehouse>
			<Form
				onSubmit={handleSubmit}
				onError={handleError}
				loading={status === 'loading'}
				schema={schema}
				hasPrompt={false}
			>
				<ActivitiesPanelNewWarehouseInner />
			</Form>
		</StyledActivitiesPanelNewWarehouse>
	)
}

export default DialogActivitiesNewWarehouse

import { useMemo, useCallback } from 'react'
import { intersection } from 'lodash'

import { MAP_MULTISELECT_TOOLS } from '@constants'
import colors from '../utils/colors.json'

import useCRUD from '@hooks/crud'
import useMapbox from '@hooks/mapbox'
import { useMapContext } from '@map/context'

import useMapToolsStore from '@map/store/tools'
import useMapStateStore from '@map/store/state'

const log = data =>
	data ? console.log(JSON.parse(JSON.stringify(data))) : data

const FILTER_SELECT_ALL_TITLE = {
	select: 'Seleccionar todo',
	deselect: 'Deseleccionar todo'
}

export default function useMapTools() {
	const { map, draw } = useMapContext()
	const { freeDraw, cancelFreeDraw } = useMapbox()

	const set = useMapToolsStore.use.set()
	const activeTool = useMapToolsStore.use.activeTool()
	const campaigns = useMapToolsStore.use.campaigns()
	const filters = useMapToolsStore.use.filters()
	const activeAccordionItem = useMapToolsStore.use.activeAccordionItem()

	const updateState = useMapStateStore.use.updateState()
	const setState = useMapStateStore.use.set()
	const plots = useMapStateStore.use.plots()

	const activeCampaign = useMemo(
		() => campaigns.find(type => !!type.active),
		[campaigns]
	)

	const campaignYear = useMemo(() => activeCampaign?.year, [activeCampaign])

	const handleActiveTool = useCallback(
		id => {
			if (id) {
				if (MAP_MULTISELECT_TOOLS.includes(id)) {
					updateState({ mode: 'multiselect' })
				} else if (id === 'draw') {
					updateState({ mode: 'creating' })
					freeDraw(map, draw)
				}
			} else {
				updateState({ mode: null, selectedPlots: [] })

				if (id === 'draw') {
					cancelFreeDraw(map, draw)
				}
			}
		},
		[map, draw]
	)

	const toggleTool = useCallback(
		id => {
			set(state => {
				state.activeTool = activeTool === id ? null : id
				handleActiveTool(state.activeTool)
			})
		},
		[activeTool]
	)

	const resetTools = useCallback(() => {
		set(state => {
			state.activeTool = null
			state.activeAccordionItem = null
		})
	}, [])

	const isToolActive = useCallback(id => id === activeTool, [activeTool])

	const toggleCampaigns = useCallback(id => {
		set(state => {
			state.campaigns.forEach(type => {
				type.active = type.id === id
			})
		})
	}, [])

	const getMapFilterById = useCallback(
		id => {
			return filters.find(filter => filter.id === id)
		},
		[filters]
	)

	const getMapFiltersItemsActives = useCallback(
		id => {
			const filter = getMapFilterById(id)

			if (!filter) return null

			return filter?.items.filter(
				({ id: itemId, active }) => !!active && itemId !== 'all'
			)
		},
		[getMapFilterById]
	)

	const getMapFiltersItemsCount = useCallback(
		id => {
			const filter = getMapFilterById(id)
			return filter.items.filter(({ id }) => id !== 'all' && id !== 'empty')
				?.length
		},
		[getMapFilterById]
	)

	const parsePlotsColorsByFilters = useCallback(
		plots => {
			if (!activeAccordionItem) return plots

			const filterItem = filters.find(item => item.id === activeAccordionItem)

			if (!filterItem) return plots

			const filterItems = filterItem.items
				.filter(item => item.id !== 'all' && item.id !== 'empty')
				?.reduce((result, item) => {
					return {
						...result,
						[item.id]: item.color
					}
				}, {})
			const filterItemsKeys = Object.keys(filterItems)

			if (plots.features && plots.features?.length) {
				return {
					...plots,
					features: plots.features.map(feature => {
						const property = feature.properties[activeAccordionItem]
						const value = intersection(property, filterItemsKeys)[0]

						const variant = value ? filterItems[value] : 'plots'

						return {
							...feature,
							properties: {
								...feature.properties,
								variant
							}
						}
					})
				}
			} else {
				return plots
			}
		},
		[filters, activeAccordionItem]
	)

	const toggleMapFiltersAccordionItem = useCallback(
		({ id }) => {
			const filterItem = filters.find(item => item.id === id)

			// if (!filterItem) return null

			set(state => {
				state.activeAccordionItem = id
			})

			const filterItems = !filterItem
				? {}
				: filterItem.items
						.filter(item => item.id !== 'all' && item.id !== 'empty')
						?.reduce((result, item) => {
							return {
								...result,
								[item.id]: item.color
							}
						}, {})
			const filterItemsKeys = Object.keys(filterItems)

			setState(state => {
				if (state.plots.features && state.plots.features?.length) {
					state.plots.features.forEach(feature => {
						const property = feature.properties[id]
						// const match = filterItemsKeys.some(key => property.includes(key))
						const value = intersection(property, filterItemsKeys)[0]

						const variant = value ? filterItems[value] : 'plots'
						feature.properties.variant = variant
					})
				}
			})
		},
		[filters, plots]
	)

	const toggleMapFiltersItem = useCallback(
		async ({ id, filterId }) => {
			await set(({ filters }) => {
				const filterItem = filters.find(item => item.id === filterId)

				if (!filterItem) return null

				const item = filterItem.items.find(item => item.id === id)
				const allItem = filterItem.items.find(item => item.id === 'all')

				if (item) {
					if (id === 'all') {
						allItem.active = !allItem.active
						allItem.title = allItem.active
							? FILTER_SELECT_ALL_TITLE.deselect
							: FILTER_SELECT_ALL_TITLE.select

						filterItem.items.forEach(item => {
							item.active = allItem.active
						})
					} else {
						const itemActive = item.active
						item.active = !itemActive

						const actives = filterItem.items
							.filter(item => item.id !== 'all')
							.some(({ active }) => active)

						allItem.active = actives
						allItem.title = allItem.active
							? FILTER_SELECT_ALL_TITLE.deselect
							: FILTER_SELECT_ALL_TITLE.select
					}
				}
			})
		},
		[set]
	)

	const getMapFiltersParams = useCallback(() => {
		return filters.reduce(
			(result, item) => {
				const actives = getMapFiltersItemsActives(item.id)
				return {
					...result,
					[item.id]: actives?.map(({ id }) => id).filter(id => id !== 'empty')
				}
			},
			{
				year: activeCampaign?.year
			}
		)
	}, [filters, activeCampaign])

	// queries
	const useMapCampaigns = () => {
		const baseKey = ['map', 'campaigns']

		const { useRead } = useCRUD({
			baseKey,
			url: 'map/campaigns'
		})
		return useRead({
			config: {
				staleTime: 0,
				cacheTime: 0,
				refetchOnWindowFocus: false,
				onSuccess: data => {
					if (data) {
						set(state => {
							state.campaigns =
								data?.length === state.campaigns?.length
									? state.campaigns
									: data.map((item, i) => ({
											...item,
											active: i === 0
									  }))
						})
					}
				}
			}
		})
	}

	const useMapFilters = ({ year }) => {
		const baseKey = ['map', 'filters', { year }]

		const { useRead } = useCRUD({
			baseKey,
			url: 'map/filters'
		})

		return useRead({
			config: {
				staleTime: 0,
				cacheTime: 0,
				onSuccess: data => {
					set(state => {
						state.filters = data.map(filterItem => {
							return {
								...filterItem,
								items: !filterItem?.items?.length
									? []
									: [
											{
												id: 'all',
												title: FILTER_SELECT_ALL_TITLE.select,
												active: false
											},
											...(!filterItem?.items.length
												? []
												: filterItem?.items.map((item, i) => ({
														...item,
														active: false,
														color: colors[i]
												  }))),
											{
												id: 'empty',
												title: `Sin ${filterItem.name.singular}`,
												active: false,
												color: '#000'
											}
									  ]
							}
						})
					})
				}
			},
			ajax: {
				params: {
					year
				}
			}
		})
	}

	/* useEffect(() => {
		handleActiveTool()
	}, [activeTool]) */

	return {
		campaigns,
		filters,
		campaignYear,
		activeAccordionItem,
		activeTool,
		toggleTool,
		resetTools,
		isToolActive,
		toggleCampaigns,
		getMapFilterById,
		getMapFiltersItemsActives,
		getMapFiltersItemsCount,
		getMapFiltersParams,
		toggleMapFiltersItem,
		toggleMapFiltersAccordionItem,
		parsePlotsColorsByFilters,
		useMapCampaigns,
		useMapFilters
	}
}

import React, { useMemo } from 'react'
import styled, { css } from 'styled-components/macro'

import Pill from '@components/Pill'

const StyledDialogStatus = styled.div`
	width: 100%;
	height: 100%;

	header {
		margin-bottom: 10px;
		padding-bottom: 10px;
		border-bottom: 1px solid var(--c__grey-200);
		display: flex;
		flex-flow: row wrap;
		align-items: center;
		gap: 10px;
	}

	& > div {
		display: flex;
		flex-flow: column;
		gap: 20px;
		height: 100%;
		max-height: 300px;
		overflow-y: auto;

		::-webkit-scrollbar {
			width: 8px;
		}

		::-webkit-scrollbar-track {
			background-color: rgba(0, 0, 0, 0.1);
			border-radius: 5px;
		}

		::-webkit-scrollbar-thumb {
			background-color: rgba(0, 0, 0, 0.2);
			border-radius: 5px;

			&:hover {
				background-color: rgba(0, 0, 0, 0.3);
			}
			&:active {
				background-color: rgba(0, 0, 0, 0.4);
			}
		}
	}
`
const StyledDialogStatusItem = styled.p`
	font-size: 16px;
	color: var(--c__red);
	font-weight: 700;
	position: relative;
	padding-left: 15px;

	${({ $type }) =>
		$type === 'success' &&
		css`
			color: var(--c__green);
		`}

	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 2px;
		height: 100%;
		background-color: currentColor;
	}
`
function DialogStatusItem({ type, message }) {
	return <StyledDialogStatusItem $type={type}>{message}</StyledDialogStatusItem>
}
function DialogStatus({ data }) {
	const totalSuccessPlots = useMemo(() => {
		return data.filter(({ type }) => type === 'success').length
	}, [data])

	const totalErrorPlots = useMemo(() => {
		return data.filter(({ type }) => type === 'error').length
	}, [data])

	return (
		<StyledDialogStatus>
			<header>
				{!!totalSuccessPlots && (
					<Pill
						text={`${totalSuccessPlots} importados correctamente`}
						theme="success"
					/>
				)}
				{!!totalErrorPlots && (
					<Pill text={`${totalErrorPlots} errores encontrados`} theme="error" />
				)}
			</header>
			<div>
				{data.map(item => (
					<DialogStatusItem key={item.id} {...item} />
				))}
			</div>
		</StyledDialogStatus>
	)
}

export default DialogStatus

import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components/macro'
import dayjs from 'dayjs'

import yup from '@yup'

import useSelect from '@hooks/select'
import useCRUD from '@hooks/crud'

import { Form, Select } from '@components/form'
import { Button } from '@components/button'
import { Grid, GridItem } from '@components/grid'
import { Flex } from '@components/flex'
import { PreloaderInside } from '@components/preloaders'

const StyledDialogMapNewActivity = styled.div`
	width: 100%;
	height: 100%;
`

const schema = yup.object().shape({
	job: yup.string().required()
})

const DialogMapNewActivityInner = ({ jobs, onCancel = () => {} }) => {
	const { select: jobsSelect } = useSelect({
		name: 'job',
		collection: jobs?.children,
		value: null
	})

	return (
		<Grid>
			<GridItem columnStart="1" columnEnd="-1">
				<Select
					options={jobsSelect}
					name="job"
					label="Trabajo *"
					placeholder="Selecciona un trabajo a realizar"
					inverted
				/>
			</GridItem>

			<GridItem columnStart="1" columnEnd="-1">
				<Flex justifyContent="space-between" alignItems="center">
					<Button
						label={'Cancelar'}
						theme="secondary"
						size="large"
						onClick={onCancel}
					/>
					<Button
						label={'Guardar'}
						type="submit"
						theme="primary"
						size="large"
					/>
				</Flex>
			</GridItem>
		</Grid>
	)
}

const DialogMapNewActivity = ({ plots, closeModal }) => {
	const navigate = useNavigate()

	const { useRead } = useCRUD({
		baseKey: ['jobs'],
		url: `jobs`
	})

	const { useCreate } = useCRUD({
		baseKey: ['activities'],
		url: `activities`
	})

	const { data: jobs, status: jobsStatus } = useRead()
	const { mutateAsync: activityCreate, status: activityCreateStatus } =
		useCreate({
			config: {
				onSuccess: data => {
					closeModal()
					navigate(`/activities/${data?.id}?init=plots`)
				}
			}
		})

	const handleSubmit = async data => {
		const parsedData = {
			date: dayjs().format('YYYY-MM-DD'),
			jobs: [{ id: data.job.toString() }],
			plots: plots.map(({ properties }) => ({ id: properties.id }))
		}
		activityCreate(parsedData)
	}

	const handleError = useCallback(error => {
		console.log(error)
	}, [])

	return (
		<StyledDialogMapNewActivity>
			<Form
				onSubmit={handleSubmit}
				onError={handleError}
				loading={activityCreateStatus === 'loading'}
				schema={schema}
				useFormProps={{
					mode: 'onChange'
				}}
			>
				<PreloaderInside loading={jobsStatus === 'loading'} minHeight={300}>
					<DialogMapNewActivityInner
						jobs={jobs}
						onCancel={() => closeModal()}
					/>
				</PreloaderInside>
			</Form>
		</StyledDialogMapNewActivity>
	)
}

export default DialogMapNewActivity

import React from 'react'
import styled from 'styled-components/macro'

const StyledCostModalPanel = styled.div`
	width: 100%;
	height: 100%;

	section {
		margin-bottom: 20px;
		padding-bottom: 20px;
		border-bottom: 1px solid var(--c__grey-200);

		&:last-child {
			margin-bottom: 0;
			padding-bottom: 0;
			border-bottom: none;
		}

		h4 {
			font-size: 16px;
			font-weight: 700;
			line-height: 1.2;
			margin-bottom: 10px;
		}

		ul {
			display: flex;
			flex-flow: column;
			gap: 10px;
			padding-left: 18px;
			list-style: disc;

			li {
				font-size: 14px;
				line-height: 1.2;
			}
		}
	}
`

const DialogCostsWhereApply = ({ data, closeModal }) => {
	return (
		<StyledCostModalPanel>
			<section>
				<h4>Explotaciones</h4>
				<ul>
					{data.holdings.map(item => (
						<li key={item.id}>{item.title}</li>
					))}
				</ul>
			</section>
			<section>
				<h4>Grupos de parcelas</h4>
				<ul>
					{data.plots_groups.map(item => (
						<li key={item.id}>{item.title}</li>
					))}
				</ul>
			</section>
			<section>
				<h4>Parcelas</h4>
				<ul>
					{data.plots.map(item => (
						<li key={item.id}>{item.title}</li>
					))}
				</ul>
			</section>
		</StyledCostModalPanel>
	)
}

export default DialogCostsWhereApply

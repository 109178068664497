import { create } from 'zustand'
import { persist as persistMiddleware } from 'zustand/middleware'
import { isFunction } from 'lodash'

import { immer } from '@store/middlewares'

const logMiddleware =
	(config, { name }) =>
	(set, get, api) =>
		config(
			args => {
				set(args)
				console.log(`${name || '💾 Store 💾'}`, '\n', get())
			},
			get,
			api
		)

const createStore = ({
	state = {},
	log = {
		name: 'Store'
	},
	persist = null
}) => {
	const baseStore = (set, get) => ({
		...(isFunction(state) ? state(set, get) : state),
		updateState: data => {
			if (data) {
				set(state => {
					for (const key in data) {
						if (key in state) {
							state[key] = data[key]
						}
					}
				})
			}
		},
		set: fn => set(fn),
		get: fn => get(fn)
	})
	const storeDefault = fn => create(logMiddleware(immer(fn), log))
	const storePersist = fn =>
		create(logMiddleware(immer(persistMiddleware(fn, persist)), log))

	return persist ? storePersist(baseStore) : storeDefault(baseStore)
}

export default createStore

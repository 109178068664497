import React, { forwardRef } from 'react'
import styled, { css } from 'styled-components/macro'

const StyledDropdownTrigger = styled.button`
	width: 25px;
	height: 20px;
	color: var(--c__grey-500);
	position: relative;
	cursor: pointer;
	transition: all 150ms ease-out;

	${({ $opened }) =>
		!!$opened &&
		css`
			color: var(--c__grey-700);
		`}

	${({ $isFeat }) =>
		!!$isFeat &&
		css`
			color: var(--c__green);
		`}
    
	${({ $opened, $isFeat }) =>
		!!$isFeat &&
		!!$opened &&
		css`
			color: var(--c__green-600);
		`}

	&:hover {
		opacity: 0.7;
	}

	svg {
		width: 100%;
		height: 100%;
		fill: currentColor;
	}
`

const StyledDropdownTriggerBadge = styled.i`
	background-color: var(--c__error);
	padding: 0 7px;
	height: 22px;
	min-width: 22px;
	display: inline-block;
	border-radius: 50px;
	margin-right: 5px;
	transition: all 150ms ease-out;
	position: absolute;
	top: -15px;
	right: -15px;
	z-index: 10;
	display: flex;
	align-items: center;
	justify-content: center;

	span {
		font-size: 12px;
		font-weight: 700;
		line-height: 1;
		color: var(--c__white);
	}
`

const DropdownTrigger = (
	{
		opened = false,
		icon = null,
		isFeat = false,
		hasBadge = false,
		badgeCount = 0,
		...restProps
	},
	ref
) => {
	return (
		<StyledDropdownTrigger
			type="button"
			ref={ref}
			$opened={opened}
			$isFeat={isFeat}
			{...restProps}
		>
			{hasBadge && (
				<StyledDropdownTriggerBadge>
					<span>{badgeCount}</span>
				</StyledDropdownTriggerBadge>
			)}
			{icon}
		</StyledDropdownTrigger>
	)
}

export default forwardRef(DropdownTrigger)

import React, { memo } from 'react'
import { AnimatePresence } from 'framer-motion'

import { useNotifications } from './useNotifications'
import Notification from './Notification'

import './notifications.scss'

function Notifications({
	notifications,
	spring = {
		type: 'spring',
		stiffness: 500,
		damping: 30
	}
}) {
	const { dismiss } = useNotifications()

	return (
		<aside className="notifications">
			<AnimatePresence initial={false}>
				{notifications.map(notification => (
					<Notification
						key={notification.id}
						spring={spring}
						{...notification}
						onClose={id => dismiss(id)}
					/>
				))}
			</AnimatePresence>
		</aside>
	)
}

export default memo(Notifications)

import React, { useState, useCallback } from 'react'
import styled from 'styled-components/macro'

import yup from '@yup'

import useActivities from '@hooks/activities'
import useCRUD from '@hooks/crud'
import useSelect from '@hooks/select'

import { Form, TextField, Select } from '@components/form'
import { PreloaderInside } from '@components/preloaders'
import { Button } from '@components/button'
import { Grid, GridItem } from '@components/grid'
import { Flex } from '@components/flex'
import SelectHoldings from '@components/ui/SelectHoldings'

const StyledActivitiesPanelNewMachine = styled.div`
	width: 100%;
	height: 100%;
`

const schema = yup.object().shape({
	title: yup.string().required(),
	property: yup.string().required().nullable()
})

const ActivitiesPanelNewMachineInner = ({ properties }) => {
	const { select: propertiesSelect } = useSelect({
		name: 'property',
		collection: properties,
		value: null
	})

	return (
		<Grid>
			<GridItem columnStart="1" columnEnd="-1">
				<TextField
					name="title"
					label="Nombre *"
					placeholder="Escribe el nombre"
					inverted
				/>
			</GridItem>
			<GridItem columnStart="1" columnEnd="-1">
				<TextField
					name="registration"
					label="Matrícula"
					placeholder="Escribe la matrícula"
					inverted
				/>
			</GridItem>
			<GridItem columnStart="1" columnEnd="-1">
				<Select
					options={propertiesSelect}
					name="property"
					label="Propiedad *"
					placeholder="Selecciona una propiedad"
					inverted
				/>
			</GridItem>
			<GridItem columnStart="1" columnEnd="-1">
				<SelectHoldings name="holding_id" label="Explotación *" inverted />
			</GridItem>
			<GridItem columnStart="1" columnEnd="-1">
				<Flex justifyContent="flex-end" alignItems="center">
					<Button
						label={'Guardar'}
						type="submit"
						theme="primary"
						size="large"
					/>
				</Flex>
			</GridItem>
		</Grid>
	)
}

const DialogActivitiesNewMachine = ({ item, closeModal }) => {
	const [loading, setLoading] = useState(false)
	const { useActivitiesCreateChild } = useActivities()

	const { mutateAsync } = useActivitiesCreateChild(item.id)

	const { useRead } = useCRUD({
		baseKey: ['activities', 'info', 'machinery', 'types'],
		url: `info/machinery`
	})

	const { status, data } = useRead()

	const handleSubmit = async data => {
		setLoading(true)

		await mutateAsync(data)

		setLoading(false)
		closeModal()
	}

	const handleError = useCallback(error => {
		console.log(error)
	}, [])

	return (
		<StyledActivitiesPanelNewMachine>
			<Form
				onSubmit={handleSubmit}
				onError={handleError}
				loading={loading}
				schema={schema}
				hasPrompt={false}
			>
				<PreloaderInside loading={status === 'loading'}>
					<ActivitiesPanelNewMachineInner properties={data?.property} />
				</PreloaderInside>
			</Form>
		</StyledActivitiesPanelNewMachine>
	)
}

export default DialogActivitiesNewMachine

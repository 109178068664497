import { useCallback } from 'react'
import { isFunction } from 'lodash'
import { useQuery, useQueries, useMutation, useQueryClient } from 'react-query'

import { BASE_URL } from '@constants'

import useAjax from '@hooks/ajax'

function useCRUD({ baseKey, url }) {
	const { get, post, put, remove } = useAjax()
	const queryClient = useQueryClient()

	const triggerQuery = useCallback(
		refetch => {
			const cached = queryClient.getQueryData(baseKey)
			return cached ? () => cached : refetch()
		},
		[baseKey, queryClient]
	)

	/**
	 * Fetch request
	 * @param {object} options { config, ajax }
	 */
	const useRead = options => {
		const request = () => {
			return get(`${BASE_URL}/${url}`, options?.ajax).then(response => {
				if (options?.ajax?.filterResponse) {
					response = options.ajax.filterResponse(response)
				}
				return Promise.resolve(response)
			})
		}

		const queryFn = useCallback(() => {
			if (options?.config?.manual) {
				const cached = queryClient.getQueryData(baseKey)
				return cached || request()
			} else {
				return request()
			}
		}, [options])

		return useQuery(baseKey, queryFn, options?.config)
	}

	/**
	 * Prefetch request
	 * @param {object} options { config, ajax }
	 */
	const usePrefetch = options => {
		const queryFn = useCallback(
			() => get(`${BASE_URL}/${url}`, options?.ajax),
			[options]
		)

		return queryClient.prefetchQuery(baseKey, queryFn, options?.config)
	}

	/**
	 * Fetch multiple requests
	 * @param {object} options { config, ajax }
	 */
	const useReadCollection = (collection, options) => {
		const queryFn = useCallback(
			id => get(`${BASE_URL}/${url}/${id}`, options?.ajax),
			[options]
		)

		return useQueries(
			!collection.length
				? []
				: collection.map(item => {
						const queryKey = isFunction(baseKey) ? baseKey(item) : baseKey
						return {
							queryKey,
							queryFn: () => queryFn(item.id),
							...options?.config
						}
				  })
		)
	}

	/**
	 * Post request
	 * @param {object} options { config, ajax }
	 */
	const useCreate = options => {
		const queryFn = useCallback(
			data => {
				return post(`${BASE_URL}/${url}`, {
					data,
					...options?.ajax
				})
			},
			[options]
		)

		return useMutation(queryFn, {
			...options?.config,
			/* onMutate: values => {
				console.log(values)
				const oldPost = queryClient.getQueryData(baseKey)
				queryClient.setQueryData(baseKey, values)
				return () => queryClient.setQueryData(baseKey, oldPost)
			}, */
			onSuccess: (...args) => {
				if (options?.config?.onSuccess) {
					options.config.onSuccess(...args)
				} else {
					queryClient.invalidateQueries(baseKey)
				}
			}
		})
	}

	/**
	 * Put request
	 * @param {object} options { config, ajax }
	 */
	const useUpdate = options => {
		const queryFn = useCallback(
			data => {
				return put(`${BASE_URL}/${url}/${data?.id || options?.ajax?.id}`, {
					data,
					...options?.ajax
				})
			},
			[options]
		)

		return useMutation(queryFn, {
			...options?.config,
			// onMutate: values => {
			// 	const oldPost = queryClient.getQueryData(baseKey)
			// 	queryClient.setQueryData(baseKey, values)
			// 	return () => queryClient.setQueryData(baseKey, oldPost)
			// },
			onSuccess: (...args) => {
				if (options?.config?.onSuccess) {
					options.config.onSuccess(...args)
				} else {
					queryClient.invalidateQueries(baseKey)
				}
			}
		})
	}

	const useSimpleUpdate = options => {
		const queryFn = useCallback(
			data => {
				return put(`${BASE_URL}/${url}`, {
					data,
					...options?.ajax
				})
			},
			[options]
		)

		return useMutation(queryFn, {
			...options?.config,
			onMutate: values => {
				const oldPost = queryClient.getQueryData(baseKey)
				queryClient.setQueryData(baseKey, values)
				return () => queryClient.setQueryData(baseKey, oldPost)
			},
			onSuccess: (...args) => {
				if (options?.config?.onSuccess) {
					options.config.onSuccess(...args)
				} else {
					queryClient.invalidateQueries(baseKey)
				}
			}
		})
	}

	/**
	 * Delete request
	 * @param {object} options { config, ajax }
	 */
	const useDelete = options => {
		const queryFn = useCallback(
			data => {
				if (window.confirm('¿Estás seguro que quieres eliminar?')) {
					const id = data?.id || options?.ajax?.id || data
					return remove(`${BASE_URL}/${url}${id ? `/${id}` : ''}`, {
						...(options?.ajax || data?.ajax)
					})
				}
			},
			[options]
		)

		return useMutation(queryFn, options?.config)
	}

	return {
		queryClient,
		triggerQuery,
		useRead,
		usePrefetch,
		useReadCollection,
		useCreate,
		useUpdate,
		useSimpleUpdate,
		useDelete
	}
}

export default useCRUD

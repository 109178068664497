import React from 'react'
import styled from 'styled-components/macro'

import { ReactComponent as RefreshIcon } from '@svgs/refresh.svg'

import useDialogs from '@hooks/dialogs'
import useAlerts from '@hooks/alerts'
import useResponsive from '@hooks/responsive'

import SidePanel from '@components/sidepanel/SidePanel'
import { Button } from '@components/button'
import { PreloaderCenter } from '@components/preloaders'

import AlertsList from './AlertsList'

const StyledAlerts = styled.div`
	background-color: var(--c__white);
	width: 100%;
	height: 100%;
	box-shadow: -1px 0 40px rgba(0, 0, 0, 0.15);
`
const StyledAlertsHeader = styled.header`
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 20px;
	padding: 0 10px 0 20px;
	height: var(--sidepanel__header-height);
	border-bottom: 1px solid var(--sidepanel__border-color);

	h3 {
		font-size: 22px;
		font-weight: 700;
	}

	& > div {
		display: flex;
		align-items: center;
		gap: 10px;
	}
`
const StyledAlertsHeaderRefresh = styled(Button)`
	display: flex;
	align-items: center;
	justify-content: center;

	svg {
		width: 24px;
		height: 24px;
	}
`
const StyledAlertsContent = styled.section`
	position: relative;
	height: calc(100% - var(--sidepanel__header-height));
	overflow-y: auto;
	-webkit-overflow-scrolling: auto;

	::-webkit-scrollbar {
		width: 8px;
	}

	::-webkit-scrollbar-track {
		background-color: rgba(0, 0, 0, 0.1);
		border-radius: 5px;
	}

	::-webkit-scrollbar-thumb {
		background-color: rgba(0, 0, 0, 0.2);
		border-radius: 5px;

		&:hover {
			background-color: rgba(0, 0, 0, 0.3);
		}
		&:active {
			background-color: rgba(0, 0, 0, 0.4);
		}
	}
`

const StyledAlertsEmpty = styled.aside`
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 30px;

	p {
		font-size: 16px;
		font-weight: 700;
		color: var(--c__grey-700);
		text-align: center;
	}
`

function AlertsEmpty({ children }) {
	return <StyledAlertsEmpty>{children}</StyledAlertsEmpty>
}

function AlertsInner() {
	const { createAlert } = useDialogs()
	const { useFetch } = useAlerts()

	const { data: alerts, refetch, isRefetching } = useFetch()

	return (
		<StyledAlerts>
			<StyledAlertsHeader>
				<h3>Alertas</h3>
				<div>
					<StyledAlertsHeaderRefresh
						icon={<RefreshIcon />}
						onClick={e => refetch()}
					/>
					<Button
						label="Crear alerta"
						theme="secondary"
						size="normal"
						onClick={e => createAlert()}
					/>
				</div>
			</StyledAlertsHeader>
			<StyledAlertsContent>
				{isRefetching && <PreloaderCenter hover />}
				{!alerts || !alerts.length ? (
					<AlertsEmpty>
						<p>Estás al día, no tienes alertas</p>
					</AlertsEmpty>
				) : (
					<AlertsList items={alerts} />
				)}
			</StyledAlertsContent>
		</StyledAlerts>
	)
}

function Alerts() {
	const { alertsSidepanelOpened, toggleSidepanel } = useAlerts()
	const { minSM } = useResponsive()

	return (
		<SidePanel
			isOpened={alertsSidepanelOpened}
			portalSelector="#root"
			width={minSM ? 400 : 300}
			onClose={() => toggleSidepanel(false)}
		>
			{() => <AlertsInner />}
		</SidePanel>
	)
}

export default Alerts

import React from 'react'

import { useUser } from '@hooks/user'
import useBreadcrumb from '@hooks/breadcrumb'
import useCRUD from '@hooks/crud'
import useResponsive from '@hooks/responsive'

import { PreloaderInside } from '@components/preloaders'
import { Grid, GridItem } from '@components/grid'
import Content from '@dashboard/Content'
import { CardGreetings, CardCategory } from '@dashboard/ui'
//
function Landing() {
	useBreadcrumb([])

	const { minXXL, minLG } = useResponsive()

	const { data: user } = useUser()
	const { useRead: useCategory } = useCRUD({
		baseKey: 'categories',
		url: 'categories'
	})

	const { data, status, error } = useCategory()

	return (
		<Content>
			<PreloaderInside loading={status !== 'success'} error={error}>
				<Grid gap="var(--grid__gap--cards)">
					<GridItem columnStart={1} columnEnd={-1}>
						<CardGreetings user={user} />
					</GridItem>
					{data &&
						data.map(({ id, title, slug }, i) => (
							<GridItem
								key={id}
								columnStart={minXXL ? 'span 4' : minLG ? 'span 6' : 'span 12'}
							>
								<CardCategory id={id} title={title} link={slug} index={i} />
							</GridItem>
						))}
				</Grid>
			</PreloaderInside>
		</Content>
	)
}

export default Landing

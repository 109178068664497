import { useCallback, useEffect } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'

import { useNotifications } from '@notifications'
import useTimer from '@hooks/timer'
import { getToken, setToken } from '@auth/token'

import { BASE_URL } from '@constants'

function useAjax(config = {}) {
	const { notification, dismiss } = useNotifications()
	const navigate = useNavigate()

	const { timer, startTimer, stopTimer } = useTimer({
		autoStart: false,
		onStop: () => {
			notification({
				title: '¡Vaya, cuánto tarda!',
				description:
					'Parece que la consulta está llevando más tiempo de la cuenta, por favor, ten paciencia, la próxima irá mucho más rápido',
				id: 'ajax-info-timer',
				type: 'info',
				timeout: null
			})
		}
	})
	// console.log('[timer]', timer)

	useEffect(() => {
		return () => stopTimer()
	}, [])

	const defaults = {
		baseURL: BASE_URL,
		responseType: 'json',

		// validateStatus: status => status < 500,
		...config
	}

	const interceptors = {
		request: {
			success: useCallback(config => {
				startTimer()

				if (getToken()) {
					config = {
						...config,
						headers: {
							...config.headers,
							Authorization: `Bearer ${getToken()}`
						}
					}
				}
				return config
			}, []),
			error: useCallback(error => {
				console.log('error request')
				return Promise.reject(error)
			}, [])
		},
		response: {
			success: useCallback(response => {
				stopTimer()
				dismiss('ajax-info-timer')

				if (response.data?.payload?.token) {
					setToken(response.data.payload.token)
				}

				return response.data.payload
			}, []),
			error: useCallback(
				error => {
					const { response } = error
					let message = ''

					stopTimer()
					dismiss('ajax-info-timer')

					if (axios.isCancel(error)) {
						console.error(`[Ajax Cancel] ${error}`)
					}

					// si viene mensaje del server
					if (response?.data?.message) {
						message = response.data.message
						// si no viene, le metemos una notificación genérica según el code status
					} else {
						if (response.status >= 400 && response.status < 500) {
							message = {
								title: `Error ${response.status}`,
								description:
									'Parece que hubo un fallo en la petición. Inténtalo de nuevo.'
							}
						} else if (response.status >= 500) {
							message = {
								title: `Error ${response.status}`,
								description:
									'Parece que hubo un fallo en el servidor. Inténtalo de nuevo.'
							}
						}
					}

					notification({
						...message,
						id: 'ajax-error',
						type: 'error'
					})

					if (response.status === 401 || response.status === 403) {
						return navigate('/', { replace: true, state: { logout: true } })
					}

					return Promise.reject(error)
				},
				[notification, navigate]
			)
		}
	}

	const ajax = axios.create(defaults)
	const source = axios.CancelToken.source()

	ajax.defaults.baseURL = BASE_URL
	ajax.interceptors.request.use(
		interceptors.request.success,
		interceptors.request.error
	)
	ajax.interceptors.response.use(
		interceptors.response.success,
		interceptors.response.error
	)

	const request = async (url, options = {}) => {
		return ajax({
			url,
			cancelToken: source.token,
			...options
		})
			.then(res => res)
			.catch(error => {
				return Promise.reject(error)
			})
	}

	const methods = {
		post: (url, options = {}) => {
			return request(url, { ...options, method: 'post' })
		},
		get: (url, options = {}) => {
			return request(url, { ...options, method: 'get' })
		},
		put: (url, options = {}) => {
			return request(url, { ...options, method: 'put' })
		},
		remove: (url, options = {}) => {
			return request(url, { ...options, method: 'delete' })
		},
		fakeRequest: (response = { name: 'fake' }) => {
			return new Promise((resolve, reject) => {
				setTimeout(() => {
					resolve(response)
				}, 1000)
			})
		}
	}

	return {
		cancel: source.cancel,
		...methods
	}
}

export default useAjax

import React, { memo, useEffect, useRef } from 'react'
import { motion } from 'framer-motion'
import cx from 'classnames'
import pauseable from 'pauseable'

const defaultVariants = {
	initial: { opacity: 0, y: 50, scale: 0.3 },
	animate: { opacity: 1, y: 0, scale: 1 },
	exit: { opacity: 0, scale: 0.5 }
}

function CloseButton({ onClose = () => {} }) {
	return (
		<button onClick={onClose} className="notification__close">
			<svg width="23" height="23" viewBox="0 0 23 23">
				<path
					fill="transparent"
					strokeWidth="3"
					strokeLinecap="round"
					d="M 3 16.5 L 17 2.5"
				/>
				<path
					fill="transparent"
					strokeWidth="3"
					strokeLinecap="round"
					d="M 3 2.5 L 17 16.346"
				/>
			</svg>
		</button>
	)
}

function Notification({
	type = 'default', // default | info | success | warning | error
	timeout = 4000,
	id,
	title,
	description,
	spring,
	variants = defaultVariants,
	onClose = () => {}
}) {
	const timeoutRef = useRef(null)

	const startTimeout = () => {
		if (!!timeout) {
			timeoutRef.current = pauseable.setTimeout(() => onClose(id), timeout)
		}
	}

	const finishTimeout = () => {
		!!timeoutRef.current && timeoutRef.current.clear()
	}

	useEffect(() => {
		startTimeout()
		return () => finishTimeout()
	}, [])

	return (
		<motion.section
			className={cx({
				notification: true,
				[`notification--${type}`]: true
			})}
			layout
			transition={spring}
			{...variants}
			onMouseEnter={e => timeoutRef.current.pause()}
			onMouseLeave={e => timeoutRef.current.resume()}
		>
			<CloseButton onClose={() => onClose(id)} />
			{title && <p className="notification__title">{title}</p>}
			{description && (
				<p className="notification__description">{description}</p>
			)}
		</motion.section>
	)
}

export default memo(Notification)

import { useCallback } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useQueryClient } from 'react-query'
import dayjs from 'dayjs'
import { nanoid } from 'nanoid'
import { omit, isEmpty, uniq, uniqBy } from 'lodash'

import numeral from 'numeral'

import useNumber from '@hooks/number'
import useCRUD from '@hooks/crud'
import useStore from '@store/store'
import {
	ACTIVITIES_FILTERED_BY_HOLDINGS,
	ACTIVITIES_VARIABLES_WITH_UNITS,
	ACTIVITIES_VARIABLES_EDIT
} from '@constants'

const log = data =>
	data ? console.log(JSON.parse(JSON.stringify(data))) : data

function useActivities() {
	const queryClient = useQueryClient()

	const activities = useStore(({ activities }) => activities)

	const set = useStore(({ set }) => set)

	const { format, parseNumber } = useNumber()

	const getActivitiesItemById = useCallback(
		id => activities.collection.find(item => item.id === id),
		[activities]
	)

	const getActivitiesDefaults = useCallback(
		() =>
			activities.defaultsById.map(id =>
				activities.collection.find(item => item.id === id)
			),
		[activities]
	)

	const toggleActivitiesList = useCallback(
		id => {
			set(({ activities }) => {
				activities.collection = activities.collection.map(item => {
					if (activities.defaultsById.includes(item.id)) {
						return { ...item, active: id === item.id }
					} else {
						return item
					}
				})
			})
		},
		[set]
	)

	const getTitleByCount = useCallback(
		(count, title) => (count === 1 ? title?.singular : title?.plural),
		[]
	)

	const getActiveActivity = useCallback(() => {
		const defaults = getActivitiesDefaults()
		return defaults.find(({ active }) => !!active)
	}, [getActivitiesDefaults])

	const getActivitySelecteds = useCallback(
		id => {
			const activity = activities.collection.find(item => item.id === id)

			if (id === 'warehouses') {
				let sel = []
				if (activities.formState?.warehouses)
					sel = activities.formState.warehouses.map(w => 'warehouses-' + w.id)
				return activities.collection.filter(item => sel.includes(item.id))
			} else
				return activities.collection.filter(item =>
					activity.selecteds.includes(item.id)
				)
		},
		[activities]
	)

	const filterByHoldings = useCallback(
		collection => {
			const holdings = getActivitySelecteds('holdings')

			if (!holdings.length) return collection

			const holdingsIds = holdings.map(({ id }) => id)

			return collection.filter(item => {
				// si tiene holding_id
				if (item?.holding_id) {
					return holdingsIds.includes(`holdings-${item?.holding_id}`)
				} else {
					// si tiene un array de holdings (Grupos de parcelas)
					if (!item?.holdings) return item
					return holdingsIds.some(o => {
						const id = o.split('holdings-')[1]
						return item.holdings.includes(id)
					})
				}
			})
		},
		[getActivitySelecteds]
	)

	const getActiveActivityChildren = useCallback(() => {
		const itemActive = getActiveActivity()
		const ids = itemActive.children
		let items = ids.map(id =>
			activities.collection.find(item => item.id === id)
		)
		// aquí filtramos los items por las explotaciones seleccionadas
		if (ACTIVITIES_FILTERED_BY_HOLDINGS.some(id => itemActive.id === id)) {
			items = filterByHoldings(items)
		}

		if (itemActive.id === 'plots') {
			const currentCampaignId = itemActive.campaign_id
			items = items.filter(plot => {
				return plot.campaign_id.includes(currentCampaignId)
			})
		}

		return items
	}, [activities, getActiveActivity, filterByHoldings])

	const toggleActivityChildActions = useCallback(
		(activities, { id, parentId, selected }) => {
			const formState = activities.formState
			const child = activities.collection.find(item => item.id === id)
			const parent = activities.collection.find(item => item.id === parentId)

			// Elimina items del formState
			const removeFromFormState = (parentId, childId) => {
				if (formState[parentId]) {
					const index = formState[parentId].findIndex(
						item => item.id === childId
					)
					if (index !== -1) {
						formState[parentId].splice(index, 1)
						/* if (parentId === 'jobs') {
							formState[parentId].splice(index, 1)
						} else {
							formState[parentId] = null
						} */
					}
				}
			}

			// Devuelve los ids de los jobs unique
			const getJobsUnique = () => {
				return activities.collection
					.filter(
						item => item.parentId === 'jobs' && !item?.type_data?.sameItems
					)
					.map(({ id }) => id)
			}

			const resetJob = () => {
				activities.collection = activities.collection.filter(item => {
					return !child?.type_data?.variableItems?.includes(
						item.parentId || item.id
					)
				})

				activities.defaultsById = activities.defaultsById.filter(id => {
					return !child?.type_data?.variableItems?.includes(id)
				})

				if (child && child?.type_data && child?.type_data?.variableItems) {
					child.type_data.variableItems.forEach(id => {
						delete activities.formState[id]
						// activities.formState[id] = null
						queryClient.removeQueries(['activities', 'info', id], {
							exact: true
						})
					})
				}
			}

			const deleteJobsByUnique = ids => {
				ids.forEach(jobId => {
					activities.collection = activities.collection.filter(item =>
						item.parentId ? item.parentId !== jobId : item.id !== jobId
					)
					activities.defaultsById = activities.defaultsById.filter(
						id => id !== jobId
					)
					!!formState[jobId] && delete formState[jobId]
				})
			}

			// Desactiva los plots groups
			const deactivatePlotsGroups = () => {
				const plotsGroups = activities.collection.filter(
					item => !!item?.plots_group
				)

				if (!!plotsGroups && !!plotsGroups.length) {
					plotsGroups.forEach(item => {
						item.active = false
					})

					formState.plots_groups = []
				}
			}

			if (!parent) {
				removeFromFormState(parentId, child?._id)
				return
			}

			if (child) {
				child.active = selected
			}

			if (selected) {
				if (parent.selecteds.indexOf(id) === -1) {
					parent.selecteds.push(id)
				}
			} else {
				parent.selecteds = uniq(
					parent.selecteds.filter(selectedId => selectedId !== id)
				)
			}

			if (parent?.id === 'jobs') {
				let jobsSelected = parent.selecteds
				// const jobsUnique = getJobsUnique()

				if (child.active) {
					//! DE MOMENTO SÓLO DEJAMOS SELECCIONAR UN TRABAJO A LA VEZ. SI HACEN FALTA MÁS, HABRÍA QUE RETOCAR LA API PARA QUE DEVUELVE LOS CORRECTOS, AHORA SÓLO DEVUELVE UNO
					jobsSelected = [child.id]
					/* if (child?.type_data?.sameItems) {
						jobsSelected = jobsSelected.filter(id => {
							return !jobsUnique.includes(id)
						})

						// Recorremos los que si son Unique y los eliminamos del collection, defaultsById y formState
						deleteJobsByUnique(ACTIVITIES_JOBS_TO_REMOVE_WHEN_NOT_UNIQUE_ACTIVE)
					} else {
						jobsSelected = [child.id]

						// Recorremos los que no son Unique y los eliminamos del collection, defaultsById y formState
						deleteJobsByUnique(ACTIVITIES_JOBS_TO_REMOVE_WHEN_UNIQUE_ACTIVE)
					} */
				}

				activities.collection.forEach(item => {
					if (item.parentId === 'jobs') {
						item.active = jobsSelected.includes(item.id)
					}
				})

				activities.defaultsById = activities.defaultsById.filter(id => {
					return !child?.type_data?.variableItems?.includes(id)
				})

				parent.selecteds = jobsSelected
				activities.ajaxParams.jobs_selected = !jobsSelected.length
					? []
					: jobsSelected.map(id => id.split('-')[1])

				if (formState[parent.id]) {
					formState[parent.id] = jobsSelected.map(id => ({
						id: id.split('-')[1]
					}))
				}
			}

			if (parent?.id === 'plots') {
				activities.plotsById = parent.selecteds

				deactivatePlotsGroups()
			}

			// Al hacer toggle en Explotaciones, recorremos las secciones afectadas y las reseteamos
			if (parent?.id === 'holdings') {
				ACTIVITIES_FILTERED_BY_HOLDINGS.forEach(id => {
					const currentItem = activities.collection.find(item => item.id === id)

					if (currentItem) {
						if (currentItem.selecteds.length) {
							currentItem.selecteds.forEach(selectedId => {
								const selected = activities.collection.find(
									item => item.id === selectedId
								)

								if (selected) {
									selected.active = false
								}
							})

							currentItem.selecteds = []
						}

						if (currentItem.id === 'plots') {
							activities.plotsById = []

							formState.plots = []

							deactivatePlotsGroups()
						}
					}
				})
			}

			// formState
			if (selected) {
				let formStateItemData = { id: child?._id }

				// seteamos "value" de inicio a parte del id
				if (parent?.id === 'fertilizers' || parent?.id === 'seeds') {
					formStateItemData = { ...formStateItemData, value: child?.value }
				}

				// inserta el item en el formState
				if (formState[parent.id]) {
					formState[parent.id].push(formStateItemData)
					formState[parent.id] = uniqBy(formState[parent.id], 'id')
				} else {
					formState[parent.id] = [formStateItemData]
				}
			} else {
				// elimina el item del formState
				removeFromFormState(parentId, child?._id)
				resetJob()
			}
		},
		[queryClient]
	)

	const toggleActivityChild = useCallback(
		({ id, parentId, selected }) => {
			set(({ activities }) => {
				toggleActivityChildActions(activities, { id, parentId, selected })
			})
		},
		[set, toggleActivityChildActions]
	)

	const toggleVariableChild = useCallback(
		({ parentId, childId = null, view }) => {
			set(({ activities }) => {
				const defaults = activities.defaultsById.map(id =>
					activities.collection.find(item => item.id === id)
				)

				defaults.forEach(item => {
					item.active = item.id === parentId
				})

				const parent = defaults.find(({ id }) => id === parentId)

				if (parent) {
					parent.view = view
					parent.activeChild = childId
				}
			})
		},
		[set]
	)

	const showFilterList = useCallback(
		parentId => {
			set(({ activities }) => {
				const defaults = activities.defaultsById.map(id =>
					activities.collection.find(item => item.id === id)
				)

				const parent = defaults.find(({ id }) => id === parentId)

				if (parent) {
					parent.view = 'filter-list'
				}
			})
		},
		[set]
	)

	const addFromFilterList = useCallback(
		({ parentId, child }) => {
			const id = `${parentId}-${child.id}`

			set(({ activities }) => {
				const parsedChild = {
					...activities.models[parentId],
					...child,
					id,
					_id: child.id,
					active: true,
					selecteds: []
				}
				const defaults = activities.defaultsById.map(id =>
					activities.collection.find(item => item.id === id)
				)

				defaults.forEach(item => {
					item.active = item.id === parentId
				})

				const parent = defaults.find(({ id }) => id === parentId)

				if (parent) {
					activities.collection.push(parsedChild)

					parent.view = 'edit'
					parent.activeChild = parsedChild.id
					parent.selecteds = [...parent.selecteds, parsedChild.id]
					parent.children.push(parsedChild.id)
				}
			})
		},
		[set]
	)

	const getFormState = useCallback(
		id => (id ? activities.formState[id] : activities.formState),
		[activities.formState]
	)

	const updateFormState = useCallback(
		(id, data) => {
			set(({ activities }) => {
				const formState = activities.formState
				formState[id] = data
			})
		},
		[set]
	)

	const updateFormStateChild = useCallback(
		({ parentId, id, data }) => {
			set(({ activities }) => {
				const formState = activities.formState
				const item = formState[parentId].find(o => o.id === id)

				if (item) {
					Object.entries(data).forEach(([key, value]) => {
						item[key] = parseNumber(value)
					})
				}
			})
		},
		[set, parseNumber]
	)

	const resetActivity = useCallback(
		id => {
			set(({ activities }) => {
				activities.collection = []
				activities.defaultsById = []
				activities.formState = {
					date: dayjs().format('YYYY-MM-DD')
				}
				activities.ajaxParams.jobs_selected = []
				activities.models = {}
				activities.phytosanitaries = {}
				activities.campaigns = []
				activities.plagues = []
				activities.plotsById = []
				activities.selects = {}
				activities.canRequestEdit = false

				queryClient.removeQueries(['activities', id])
				queryClient.removeQueries(['activities', 'info'])
			})
		},
		[set, queryClient]
	)

	const updateItemData = useCallback(
		async (id, data = {}) => {
			await set(({ activities }) => {
				const item = activities.collection.find(o => o.id === id)
				if (item) {
					Object.entries(data).forEach(([key, value]) => {
						item[key] = value
					})
				}
			})
		},
		[set]
	)

	// WAREHOUSES
	const toggleWarehousesSelect = useCallback(
		async ({ parentId, item, optionId }) => {
			await set(({ activities }) => {
				const activeItem = activities.collection.find(o => o.id === item.id)
				if (activeItem) {
					activeItem.warehouse_id = optionId
				}
			})

			updateFormStateChild({
				parentId,
				id: item._id,
				data: {
					warehouse_id: optionId
				}
			})
		},
		[set, updateFormStateChild]
	)

	// CAMPAIGNS
	const getCampaigns = useCallback(() => {
		return activities.campaigns
	}, [activities.campaigns])

	const getActiveCampaign = useCallback(() => {
		const plots = getActivitiesItemById('plots')

		if (!!plots && 'campaign_id' in plots) {
			return (
				activities.campaigns.find(o => o.id === plots.campaign_id) ||
				activities.campaigns[0]
			)
		} else {
			return activities.campaigns[0]
		}
	}, [getActivitiesItemById, activities.campaigns])

	// SELECTS
	const getSelect = useCallback(
		(parentId, id) => {
			const selects = activities.selects
			return selects[parentId][id]
		},
		[activities.selects]
	)

	// PLOTS
	const getPlotsAreas = useCallback(() => {
		const plots = activities.collection.filter(({ id }) =>
			activities.plotsById.includes(id)
		)
		if (plots) {
			return plots.reduce((result, plot) => {
				return (result += Number(plot.area))
			}, 0)
		} else {
			return 0
		}
	}, [activities.plotsById, activities.collection])

	// PLOTS GROUPS
	const getPlotsGroups = useCallback(() => {
		const plotsGroups = activities.collection.filter(
			item => !!item?.plots_group
		)
		return filterByHoldings(plotsGroups)
	}, [activities, filterByHoldings])

	const togglePlotsGroupActions = useCallback(
		(activities, { id, selected, plotsFromEdit = null }) => {
			const formState = activities.formState
			const plotsGroup = activities.collection.find(item => item.id === id)
			const holdings = getActivitySelecteds('holdings')

			const parent = activities.collection.find(item => item.id === 'plots')

			if (plotsGroup) {
				plotsGroup.active = selected
			}

			parent.children.forEach(plotId => {
				const plot = activities.collection.find(item => item.id === plotId)
				if (plot) {
					plot.active = false
				}
			})

			const plotsGroupsActive = activities.collection.filter(item => {
				return !!item?.plots_group && item.active
			})

			let selecteds = []
			plotsGroupsActive.forEach(plotsGroupActive => {
				const plots = activities.collection
					.filter(plot => plotsGroupActive.plots.includes(plot._id))
					.filter(plot => {
						return holdings.length
							? holdings.some(({ _id }) => _id === plot.holding_id)
							: true
					})
					.filter(plot => {
						if (!plotsFromEdit) {
							return plot
						} else {
							const ids = plotsFromEdit.map(({ id }) => id)
							return ids.includes(plot._id)
						}
					})

				plots.forEach(plot => {
					selecteds = [...selecteds, plot._id]
					if (!selecteds.includes(plot.id)) {
						plot.active = true
					}
				})
				selecteds = uniqBy(selecteds)
			})

			parent.selecteds = selecteds.map(id => `plots-${id}`)
			activities.plotsById = parent.selecteds

			formState[parent.id] = selecteds.map(id => ({ id }))
			formState.plots_groups = plotsGroupsActive.map(({ _id }) => ({
				id: _id
			}))
		},
		[getActivitySelecteds]
	)
	const togglePlotsGroup = useCallback(
		({ id, selected }) => {
			set(({ activities }) => {
				togglePlotsGroupActions(activities, { id, selected })
			})
		},
		[set, togglePlotsGroupActions]
	)

	// VARIABLE UNITS
	const toggleVariableUnitsSelected = useCallback(
		(id, selectedId) => {
			set(({ activities }) => {
				const item = activities.collection.find(o => o.id === id)
				if (item) {
					item.unit = selectedId
				}
			})
		},
		[set]
	)

	const getVariableUnitsSelected = useCallback(
		({ parentId, unit = null }) => {
			const units = getSelect(parentId, 'units')
			return !unit ? units[0] : units.find(o => o.id === unit) || units[0]
		},
		[getSelect]
	)

	const getSummaryItemUnit = useCallback(
		(item, summaryItem) => {
			const { title } = getVariableUnitsSelected(item)
			const { type } = summaryItem

			if (type === 'dosis') {
				return title.indexOf('/ha') !== -1 ? title : `${title}/ha`
			} else {
				return title.indexOf('/ha') !== -1 ? title.split('/')[0] : title
			}
		},
		[getVariableUnitsSelected]
	)

	// PLAGUES
	const getPlagues = useCallback(() => {
		return activities.plagues
	}, [activities])

	const togglePlagueSelect = useCallback(
		(id, selectedId) => {
			set(({ activities }) => {
				const item = activities.collection.find(o => o.id === id)
				if (item) {
					item.plague = selectedId
				}
			})
		},
		[set]
	)

	// PHYTOSANITARIES
	const getPhytosanitariesSummary = useCallback(
		({ id }) => {
			const item = activities.collection.find(({ id: itemId }) => itemId === id)

			if (!item) return null

			const summary = getSelect(item.parentId, 'summary')

			const plotsAreas = getPlotsAreas()

			const dosis = summary.find(({ type }) => type === 'dosis')
			const itemProduct = parseNumber(item.product)

			const getValue = () => {
				const calc = itemProduct * plotsAreas
				const value = !!plotsAreas && !!itemProduct ? calc : 0

				return value
			}

			return [
				{
					...dosis,
					value: getValue(),
					parsed: numeral(getValue()).format('0,0[.]00')
				}
			]
		},
		[activities.collection, getPlotsAreas, getSelect, parseNumber]
	)

	// FERTILIZERS
	/**
	 * Devuelve cantidad y dosis del abono
	 */
	const getFertilizerSummary = useCallback(
		({ id }) => {
			const item = activities.collection.find(({ id: itemId }) => itemId === id)

			if (!item) return null

			const summary = getSelect(item.parentId, 'summary')

			const { type: unitType } = getVariableUnitsSelected(item)
			const plotsAreas = getPlotsAreas()

			const dosis = summary.find(({ type }) => type === 'dosis')
			const quantity = summary.find(({ type }) => type === 'quantity')

			const itemValue = parseNumber(item.value)

			const getValue = type => {
				const calc =
					type === 'dosis' ? itemValue / plotsAreas : itemValue * plotsAreas
				const value = !!plotsAreas && !!itemValue ? calc : 0

				if (unitType === 'dosis') {
					return type === 'dosis' ? value : itemValue
				} else {
					return type === 'quantity' ? value : itemValue
				}
			}

			return [
				{
					...dosis,
					value: getValue('dosis'),
					parsed: numeral(getValue('dosis')).format('0,0[.]00')
				},
				{
					...quantity,
					value: getValue('quantity'),
					parsed: numeral(getValue('quantity')).format('0,0[.]00')
				}
			]
		},
		[
			activities.collection,
			getVariableUnitsSelected,
			getPlotsAreas,
			getSelect,
			parseNumber
		]
	)

	// HARVESTS TICKETS
	const updateFormStateTickets = useCallback(
		id => {
			set(({ activities }) => {
				const formState = activities.formState
				const item = activities.collection.find(o => o.id === id)
				if (item) {
					const formStateItem = formState?.harvests.find(o => o.id === item._id)

					if (formStateItem) {
						formStateItem.tickets = item.tickets.map(
							({ quantity, number }) => ({
								quantity: Number(quantity),
								number: Number(number)
							})
						)
					}
				}
			})
		},
		[set]
	)

	const resetTickets = useCallback(
		async ({ id }) => {
			await set(({ activities }) => {
				const item = activities.collection.find(o => o.id === id)
				if (item) {
					item.tickets[0].quantity = 0
					item.tickets[0].number = 0
					item.tickets = item.tickets.filter((o, i) => i === 0)
				}
			})

			updateFormStateTickets(id)
		},
		[set, updateFormStateTickets]
	)

	const addTicket = useCallback(
		async id => {
			await set(({ activities }) => {
				const item = activities.collection.find(o => o.id === id)
				if (item) {
					item.tickets.push({
						id: nanoid(),
						quantity: 0,
						number: 0
					})
				}
			})

			updateFormStateTickets(id)
		},
		[set, updateFormStateTickets]
	)

	const removeTicket = useCallback(
		async ({ id, ticketId }) => {
			await set(({ activities }) => {
				const item = activities.collection.find(o => o.id === id)
				if (item) {
					item.tickets = item.tickets.filter(ticket => ticket.id !== ticketId)
				}
			})

			updateFormStateTickets(id)
		},
		[set, updateFormStateTickets]
	)

	const updateTicketValue = useCallback(
		async ({ id, ticketId, data }) => {
			await set(({ activities }) => {
				const formState = activities.formState
				const item = activities.collection.find(o => o.id === id)
				if (item) {
					if (data) {
						item.tickets.forEach(ticket => {
							if (ticket.id === ticketId) {
								Object.entries(data).forEach(([key, value]) => {
									ticket[key] = value
								})
							}
						})
					}

					const formStateItem = formState?.harvests.find(o => o.id === item._id)

					if (formStateItem) {
						formStateItem.tickets = item.tickets.map(
							({ quantity, number }) => ({
								quantity: Number(quantity),
								number: Number(number)
							})
						)
					}
				}
			})

			updateFormStateTickets(id)
		},
		[set, updateFormStateTickets]
	)

	/**
	 * Devuelve la cantidad total de los tickets
	 */
	const getTicketTotalQuantity = useCallback(
		item => {
			const value = item.tickets.reduce((result, ticket) => {
				return (result += parseNumber(ticket.quantity))
			}, 0)

			return {
				value,
				parsed: format(value)
			}
		},
		[format, parseNumber]
	)

	/**
	 * Devuelve el Rendimiento de los tickets según las parcelas seleccionados
	 */
	const getTicketPerformance = useCallback(
		item => {
			const plotsAreas = getPlotsAreas()
			const { value: totalQuantity } = getTicketTotalQuantity(item)
			const value =
				!!plotsAreas && !!totalQuantity ? totalQuantity / plotsAreas : 0

			return {
				value,
				parsed: value ? format(value.toFixed(3)) : 0
			}
		},
		[getTicketTotalQuantity, getPlotsAreas, format]
	)

	/**
	 * Devuelve cantidad total y rendimiento parseado
	 */
	const getTicketSummary = useCallback(
		({ id }) => {
			const item = activities.collection.find(({ id: itemId }) => itemId === id)
			const summary = getSelect(item.parentId, 'summary')

			if (!item) return null

			const totalQuantity = summary.find(
				({ type }) => type === 'total-quantity'
			)
			const performance = summary.find(({ type }) => type === 'performance')

			return [
				{ ...totalQuantity, ...getTicketTotalQuantity(item) },
				{ ...performance, ...getTicketPerformance(item) }
			]
		},
		[
			activities.collection,
			getTicketTotalQuantity,
			getTicketPerformance,
			getSelect
		]
	)

	/**
	 * EDIT
	 */
	const useActivitiesEdit = (id, defaultsLoaded = false) => {
		const navigate = useNavigate()

		const { useRead } = useCRUD({
			baseKey: ['activities', id],
			url: `activities/${id}`
		})

		return useRead({
			config: {
				staleTime: Infinity,
				refetchOnWindowFocus: false,
				enabled: !!id && defaultsLoaded,
				onError: error => {
					console.error(error)
					navigate('/not-found', { replace: true })
				},
				onSuccess: data => {}
			}
		})
	}

	const requestEditJobs = useCallback(
		async data => {
			set(({ activities }) => {
				for (const key in data) {
					// Añadimos el key "plots" para el caso de cuando se crea la actividad directamente desde el mapa
					if (ACTIVITIES_VARIABLES_EDIT.includes(key) || key === 'plots') {
						if ('jobs' in data && !!data?.jobs?.length) {
							const jobsSelected = data.jobs.map(job => job.id)

							const jobs = activities.collection.find(({ id }) => id === 'jobs')

							if (jobs) {
								jobs.selecteds = jobsSelected.map(id => `jobs-${id}`)
								activities.ajaxParams.jobs_selected = jobsSelected
							}
						}
					}
				}
			})
		},
		[set]
	)

	const updateCanRequestEdit = useCallback(
		value => {
			set(({ activities }) => {
				activities.canRequestEdit = value
			})
		},
		[set]
	)

	const requestEdit = async data => {
		return new Promise(resolve => {
			const processRequestEdit = async () => {
				if (!!data && !isEmpty(data)) {
					const getItem = (activities, key) => {
						return activities.collection.find(o => o.id === key)
					}

					const updateItemData = (activities, key, data) => {
						const item = getItem(activities, key)

						if (item) {
							Object.entries(data).forEach(([key, value]) => {
								item[key] = value
							})
						}
					}

					await set(({ activities }) => {
						activities.formState = data
					})

					await set(({ activities }) => {
						for (const key in data) {
							const value = data[key]

							if (key === 'campaign') {
								updateItemData(activities, 'plots', { campaign_id: value })
							} else if (key === 'time') {
								updateItemData(activities, 'time', value)
							} else if (key === 'comments') {
								updateItemData(activities, 'comments', { value })
							} else if (
								key === 'machinery' ||
								key === 'staff' ||
								key === 'holdings' ||
								key === 'jobs' ||
								key === 'plots' ||
								key === 'warehouses'
							) {
								if (value.length) {
									value.forEach(({ id }) => {
										toggleActivityChildActions(activities, {
											id: `${key}-${id}`,
											parentId: key,
											selected: true
										})
									})
								}
							}
						}
					})

					await set(({ activities }) => {
						for (const key in data) {
							const value = data[key]

							if (key === 'fertilizers' || key === 'seeds') {
								if (value.length) {
									value.forEach(({ id, ...restData }) => {
										toggleActivityChildActions(activities, {
											id: `${key}-${id}`,
											parentId: key,
											selected: true
										})
										updateItemData(activities, `${key}-${id}`, restData)
									})
								}
							} else if (key === 'harvests') {
								if (value.length) {
									value.forEach(({ id, tickets, ...restData }) => {
										const item = activities.collection.find(
											o => o._id === id && o.parentId === 'harvests'
										)
										if (!!item && !!tickets.length) {
											const itemTickets = tickets.map(ticket => ({
												id: nanoid(),
												quantity: Number(ticket.quantity),
												number: Number(ticket.number)
											}))

											item.tickets = itemTickets

											toggleActivityChildActions(activities, {
												id: `${key}-${id}`,
												parentId: key,
												selected: true
											})
										}
									})
								}
							} else if (key === 'phytosanitaries') {
								if (value.length) {
									value.forEach(({ id, ...restData }) => {
										toggleActivityChildActions(activities, {
											id: `${key}-${id}`,
											parentId: key,
											selected: true
										})
										updateItemData(activities, `${key}-${id}`, restData)
									})
								}
							} else if (key === 'plots_groups') {
								if (value.length) {
									value.forEach(({ id }) => {
										togglePlotsGroupActions(activities, {
											id: `${key}-${id}`,
											parentId: key,
											selected: true,
											plotsFromEdit: data?.plots
										})
									})
								}
							}
						}
					})
				}
			}

			processRequestEdit()

			return resolve()
		})
	}

	/**
	 * QUERIES
	 */
	const createActivityDefault = (activities, item, options = {}) => {
		const collectionIds = activities.collection.map(({ id }) => id)

		if (collectionIds.includes(item.id)) return

		let props = {
			...item,
			_id: item.id,
			id: item.id,
			icon: item.id,
			active: false,
			children: [],
			selecteds: [],
			activeChild: null,
			view: 'list', // list | edit
			...options
		}

		const itemsByParentIds = activities.collection.map(
			({ parentId }) => parentId
		)

		if (itemsByParentIds.includes(item.id)) {
			const children = activities.collection.filter(
				({ parentId }) => parentId === item.id
			)
			const childrenIds = children.map(({ id }) => id)

			props = {
				...props,
				children: childrenIds
			}
		}

		activities.collection.push(props)
	}

	const useActivitiesDefaults = (params = {}, enabled = false) => {
		const [searchParams] = useSearchParams()

		const { useRead } = useCRUD({
			baseKey: ['activities', 'info'],
			url: 'info/activities'
		})
		return useRead({
			config: {
				staleTime: Infinity,
				refetchOnWindowFocus: false,
				enabled,
				onSuccess: data => {
					set(({ activities }) => {
						data.forEach((item, i) => {
							createActivityDefault(activities, item, {
								active: searchParams.has('init')
									? item.id === searchParams.get('init')
									: i === 0
							})
						})

						activities.defaultsById = data.map(({ id }) => id)
					})
				}
			},
			ajax: {
				params
			}
		})
	}

	const useActivitiesSingle = (id, query = {}, enabled = true) => {
		const { useRead } = useCRUD({
			baseKey: ['activities', 'info', id],
			url: `info/activities/${id}`,
			...query
		})
		return useRead({
			config: {
				staleTime: Infinity,
				refetchOnWindowFocus: false,
				enabled,
				onSuccess: data => {
					set(({ activities }) => {
						const collectionIds = activities.collection.map(({ id }) => id)
						if (data)
							data.forEach(({ slug: _slug, ...item }) => {
								const newId = `${id}-${item.id}`
								if (!collectionIds.includes(newId)) {
									let props = {
										...item,
										_id: item.id,
										id: newId,
										parentId: id,
										icon: item.id,
										active: false,
										children: [],
										selecteds: [],
										warehouse_id: item?.warehouse_id || null
									}

									if (id === 'plots_groups') {
										props = {
											...props,
											plots_group: true
										}
									}

									if (ACTIVITIES_VARIABLES_WITH_UNITS.includes(id)) {
										props = {
											...props,
											unit: ''
										}
									}

									activities.collection.push(props)

									const currentItem = activities.collection.find(
										item => item.id === id
									)

									if (currentItem) {
										currentItem.children.push(newId)
									} else {
										// createActivityDefault(activities, item, { active: i === 0 })
									}
								}
							})
					})
				}
			}
		})
	}

	const useActivitiesCreateChild = (id, query = {}) => {
		const { useCreate } = useCRUD({
			baseKey: ['activities', 'info', id],
			url: id,
			...query
		})

		return useCreate({
			config: {
				onSuccess: async item => {
					const newId = `${id}-${item.id}`
					await set(({ activities }) => {
						const currentItem = activities.collection.find(
							item => item.id === id
						)

						let props = {
							...item,
							_id: item.id,
							id: newId,
							parentId: id,
							icon: item.id,
							active: true,
							children: [],
							selecteds: []
						}

						if (id === 'plots_groups') {
							props = {
								...props,
								plots_group: true
							}
						} else {
							currentItem.children.unshift(newId)
						}

						activities.collection.unshift(props)
					})

					if (id === 'plots_groups') {
						togglePlotsGroup({ id: newId, selected: true })
					} else {
						toggleActivityChild({ id: newId, parentId: id, selected: true })
					}
				}
			}
		})
	}

	const useActivitiesCreateSingle = (query = {}) => {
		const { useCreate, queryClient } = useCRUD({
			baseKey: ['activities', 'info'],
			url: 'activities',
			...query
		})

		const navigate = useNavigate()

		return useCreate({
			config: {
				onSuccess: async data => {
					if (queryClient.getQueryData(['activities'])) {
						await queryClient.setQueryData(['activities'], old => {
							return {
								...old,
								children: [...old.children, data]
							}
						})
					}
					await queryClient.invalidateQueries(['activities'], { exact: true })
					navigate('../', { replace: true })
				}
			}
		})
	}

	const useActivitiesPlagues = (enabled = true) => {
		const { useRead } = useCRUD({
			baseKey: ['activities', 'info', 'plagues'],
			url: `info/activities/plagues`
		})
		return useRead({
			config: {
				staleTime: Infinity,
				refetchOnWindowFocus: false,
				enabled,
				onSuccess: data => {
					set(({ activities }) => {
						activities.plagues = data
					})
				}
			}
		})
	}

	function useActivitiesSelects() {
		const { useRead } = useCRUD({
			baseKey: ['activities', 'info', 'selects'],
			url: `info/activities/selects`
		})
		return useRead({
			config: {
				staleTime: Infinity,
				refetchOnWindowFocus: false,
				onSuccess: data => {
					set(({ activities }) => {
						activities.selects = data
					})
				}
			}
		})
	}

	function useActivitiesPhytoSelects() {
		const { useRead } = useCRUD({
			baseKey: ['activities', 'info', 'phytosanitaries', 'selects'],
			url: `info/activities/phytosanitaries/selects`
		})
		return useRead({
			config: {
				staleTime: Infinity,
				refetchOnWindowFocus: false,
				onSuccess: data => {
					set(({ activities }) => {
						activities.phytosanitaries = data
					})
				}
			}
		})
	}

	const useActivitiesCampaigns = () => {
		const { useRead } = useCRUD({
			baseKey: ['activities', 'info', 'campaigns'],
			url: `info/activities/campaigns`
		})
		return useRead({
			config: {
				staleTime: Infinity,
				refetchOnWindowFocus: false,
				onSuccess: data => {
					set(({ activities }) => {
						activities.campaigns = data
					})
				}
			}
		})
	}

	const useActivitiesEmptyModel = id => {
		const { useRead } = useCRUD({
			baseKey: ['activities', 'info', `${id}_empty`],
			url: `info/activities/${id}_empty`
		})
		return useRead({
			config: {
				staleTime: Infinity,
				refetchOnWindowFocus: false,
				onSuccess: data => {
					const parsedData = {
						...omit(data, ['summary']),
						unit: '',
						parentId: id
					}

					set(({ activities }) => {
						activities.models = {
							...activities.models,
							[id]: parsedData
						}
					})
				}
			}
		})
	}

	const useActivitiesUpdate = (id, query = {}) => {
		const baseKey = ['activities', id]

		const { useUpdate, queryClient } = useCRUD({
			baseKey,
			url: `activities`,
			...query
		})

		return useUpdate({
			config: {
				onMutate: values => {
					const oldPost = queryClient.getQueryData(baseKey)
					queryClient.setQueryData(baseKey, values)
					return () => queryClient.setQueryData(baseKey, oldPost)
				},
				onSuccess: async data => {
					/* if (queryClient.getQueryData(['activities'])) {
						await queryClient.setQueryData(['activities'], old => {
							return {
								...old,
								children: [...old.children, data]
							}
						})
					} */
					await queryClient.invalidateQueries(baseKey, { exact: true })
					// navigate('../', { replace: true })
				}
			}
		})
	}

	return {
		activities,
		getActivitiesItemById,
		getActivitiesDefaults,
		getTitleByCount,
		getActiveActivity,
		getActiveActivityChildren,
		getActivitySelecteds,
		toggleActivitiesList,
		toggleActivityChild,
		toggleVariableChild,
		filterByHoldings,
		showFilterList,
		addFromFilterList,
		getFormState,
		updateFormState,
		updateFormStateChild,
		getSelect,
		getCampaigns,
		getActiveCampaign,
		getPlotsAreas,
		getPlotsGroups,
		togglePlotsGroup,
		toggleWarehousesSelect,
		getVariableUnitsSelected,
		getSummaryItemUnit,
		toggleVariableUnitsSelected,
		getPlagues,
		togglePlagueSelect,
		resetActivity,
		updateItemData,
		resetTickets,
		addTicket,
		removeTicket,
		updateTicketValue,
		getTicketSummary,
		getFertilizerSummary,
		getPhytosanitariesSummary,
		useActivitiesDefaults,
		useActivitiesSingle,
		useActivitiesCreateChild,
		useActivitiesCreateSingle,
		useActivitiesUpdate,
		useActivitiesPlagues,
		useActivitiesSelects,
		useActivitiesPhytoSelects,
		useActivitiesCampaigns,
		useActivitiesEmptyModel,
		useActivitiesEdit,
		requestEditJobs,
		updateCanRequestEdit,
		requestEdit
	}
}

export default useActivities

import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'

const PublicRoute = ({
	element,
	redirectTo = '/',
	authenticated,
	status,
	path,
	children
}) => {
	const location = useLocation()

	if (!!authenticated) {
		return <Navigate replace to={redirectTo} state={{ from: location }} />
	}
	return status !== 'loading' ? children : null
}

export default PublicRoute

import React, {
	Fragment,
	useRef,
	useState,
	useEffect,
	useCallback
} from 'react'
import styled from 'styled-components/macro'
import { createPortal } from 'react-dom'
import classNames from 'classnames'
import { isString } from 'lodash'
import { motion, AnimatePresence } from 'framer-motion'
import { useDebounce } from 'use-debounce'

import Overlay from './Overlay'
import { additionalProps } from '../helpers'

const StyledModal = styled(motion.div)`
	position: fixed;
	overflow: hidden;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 2147483642;
	backface-visibility: hidden;
	transform-style: preserve-3d;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: ${({ dismissable }) => (dismissable ? 'pointer' : 'default')};
`

const StyledModalBox = styled.div`
	width: 100%;
	max-width: ${({ width }) => (isString(width) ? width : `${width}px`)};
	cursor: auto;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 15px;
`

function Modal({
	children,
	hasPortal = true,
	hasOverlay = true,
	isOpened = false,
	dismissable = true,
	width = 500,
	portalElement = document.body,
	overlayProps = {},
	onOpen = () => {},
	onClose = () => {}
}) {
	const [isInternalOpened, setInternalOpened] = useState(isOpened)

	const modal = useRef()
	const [debouncedIsOpened] =
		useDebounce(isInternalOpened, isInternalOpened ? 0 : 1) || false

	const variants = {
		initial: {
			transform: 'scale(0.7)',
			opacity: 0
		},
		open: {
			transform: 'scale(1)',
			opacity: 1,
			transition: {
				type: 'spring',
				bounce: 0,
				duration: 0.2,
				delay: isInternalOpened ? 0.1 : 0
			}
		},
		close: {
			transform: 'scale(0.7)',
			opacity: 0,
			transition: {
				duration: 0.1
			}
		}
	}

	const close = useCallback(() => {
		!!dismissable && setInternalOpened(false)
	}, [dismissable])

	const handleClickClose = e => {
		if (e.target === modal.current) {
			e.preventDefault()
			close()
		}
	}

	const onEscape = useCallback(
		e => {
			if (e.keyCode === 27 && !!dismissable) {
				close()
			}
		},
		[dismissable]
	)

	function handleOpen() {
		lockBodyScroll()
		onOpen(modal.current)
	}

	function handleClose() {
		unlockBodyScroll()
		onClose(modal.current)
	}

	function lockBodyScroll() {
		document.body.style.overflow = 'hidden'
	}

	function unlockBodyScroll() {
		document.body.style.overflow = ''
	}

	useEffect(() => {
		window.addEventListener('keydown', onEscape, false)

		return () => {
			window.removeEventListener('keydown', onEscape, false)
			setInternalOpened(true)
			unlockBodyScroll()
		}
	}, [dismissable])

	useEffect(() => {
		setInternalOpened(isOpened)
	}, [isOpened])

	const ModalComponent = (
		<Fragment>
			{hasOverlay && (
				<Overlay
					isOpened={isInternalOpened}
					dismissable={dismissable}
					{...additionalProps(overlayProps, { isOpened: isInternalOpened })}
				/>
			)}
			<AnimatePresence
				onExitComplete={() => (isInternalOpened ? handleOpen() : handleClose())}
			>
				{debouncedIsOpened && (
					<StyledModal
						key="modal"
						ref={modal}
						style={isInternalOpened ? { overflowY: 'auto' } : {}}
						className={classNames({
							modal: true,
							'modal--opened': isInternalOpened,
							'modal--closed': !isInternalOpened
						})}
						initial={variants.initial}
						animate={variants.open}
						exit={variants.close}
						onClick={handleClickClose}
					>
						<StyledModalBox
							width={width}
							style={isInternalOpened ? { margin: 'auto' } : {}}
						>
							{children({
								isOpened: isInternalOpened,
								modal: modal.current
							})}
						</StyledModalBox>
					</StyledModal>
				)}
			</AnimatePresence>
		</Fragment>
	)

	return hasPortal
		? createPortal(ModalComponent, portalElement)
		: ModalComponent
}

export default Modal

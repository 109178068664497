import React, { useCallback } from 'react'
import styled from 'styled-components/macro'
import { motion } from 'framer-motion'
import { Link } from 'react-router-dom'

import bgCampo from '@images/landing/dest-campo.png'
import bgGanaderia from '@images/landing/dest-ganaderia.png'
import bgMapas from '@images/landing/dest-mapas.png'
import bgCostes from '@images/landing/dest-costes.png'
import bgAyuda from '@images/landing/dest-ayuda.png'
import bgCatalogo from '@images/landing/dest-catalogo.png'
import { StyledCard } from '@components/cards'

import { cardAnimation } from './animations'

const StyledCardCategory = styled(motion(StyledCard))`
	width: 100%;
	height: var(--card__height);
	display: flex;
	flex-flow: column;
	align-items: flex-start;
	justify-content: center;
	position: relative;
	padding: var(--card__gutter);
	transition: all 150ms ease-out;
	box-shadow: var(--card__shadow);
	overflow: hidden;

	&:hover {
		box-shadow: var(--card__shadow--hover);
	}

	a {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 2;
	}

	& > div {
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		position: relative;

		h3 {
			font-size: 25px;
			font-weight: 900;
			color: var(--c__green-700);
			line-height: 1.2;
		}

		img {
			width: auto;
			height: calc(100% + 20px);
			position: absolute;
			top: -10px;
			right: 0;

			&.cattle-raising {
				right: -10%;
				height: 85%;
				top: 10px;
			}
			&.map {
				top: -20px;
				right: -20px;
				height: calc(100% + 40px);
			}
			&.costs {
				right: -5%;
				height: 85%;
				top: 10px;
			}
			&.catalogue {
				height: 85%;
				top: 10px;
				bottom: 0;
			}
		}
	}
`

function CardCategory({ id, title, link = '/', index = 0 }) {
	const getBg = useCallback(() => {
		switch (link) {
			case 'agriculture': {
				return bgCampo
			}
			case 'cattle-raising': {
				return bgGanaderia
			}
			case 'map': {
				return bgMapas
			}
			case 'costs/prices': {
				return bgCostes
			}
			case 'help': {
				return bgAyuda
			}
			case 'catalogue': {
				return bgCatalogo
			}
			default: {
				return bgCampo
			}
		}
	}, [link])

	return (
		<StyledCardCategory
			custom={index}
			initial="hidden"
			animate="visible"
			variants={cardAnimation}
		>
			{id === 'help' ? (
				<a
					href="https://okcampo.com/home/recursos/tutoriales"
					target="_blank"
					rel="noopener noreferrer"
				></a>
			) : (
				<Link to={link} />
			)}
			<div>
				<h3>{title}</h3>
				<img src={getBg()} className={link} alt={title} />
			</div>
		</StyledCardCategory>
	)
}

export default CardCategory

import React, { useCallback, useMemo, useState } from 'react'
import styled from 'styled-components/macro'

import yup from '@yup'

import useSelect from '@hooks/select'
import useCRUD from '@hooks/crud'
import { useInfoPage } from '@hooks/info'

import { Form, Select } from '@components/form'
import { Button } from '@components/button'
import { Grid, GridItem } from '@components/grid'
import { Flex } from '@components/flex'
import { PreloaderInside } from '@components/preloaders'

const StyledDialogMapAddCampaign = styled.div`
	width: 100%;
	height: 100%;
`
const StyledDialogMapAddCampaignEmpty = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 30px;

	p {
		font-size: 16px;
		font-weight: 700;
		text-align: center;
	}
`

const schema = yup.object().shape({
	campaign_id: yup.string().required()
})

const DialogMapAddCampaignInner = ({
	plotCampaigns,
	campaigns,
	setCampaignId,
	onCancel = () => {}
}) => {
	const parsedCampaigns = useMemo(() => {
		if (!plotCampaigns.length) return campaigns
		const plotCampaignsIds = plotCampaigns.map(({ id }) => id)
		return campaigns.filter(({ id }) => !plotCampaignsIds.includes(id))
	}, [campaigns, plotCampaigns])

	const { select: campaignsSelect } = useSelect({
		name: 'campaign_id',
		collection: parsedCampaigns,
		value: null,
		onChange: ({ watched }) => {
			setCampaignId(watched)
		}
	})

	if (!parsedCampaigns.length) {
		return (
			<StyledDialogMapAddCampaignEmpty>
				<p>No hay campañas para añadir</p>
			</StyledDialogMapAddCampaignEmpty>
		)
	}

	return (
		<Grid>
			<GridItem columnStart="1" columnEnd="-1">
				<Select
					options={campaignsSelect}
					name="campaign_id"
					label="Campañas *"
					placeholder="Selecciona una campaña"
					inverted
				/>
			</GridItem>

			<GridItem columnStart="1" columnEnd="-1">
				<Flex justifyContent="space-between" alignItems="center">
					<Button
						label={'Cancelar'}
						theme="secondary"
						size="large"
						onClick={onCancel}
					/>
					<Button
						label={'Guardar'}
						type="submit"
						theme="primary"
						size="large"
					/>
				</Flex>
			</GridItem>
		</Grid>
	)
}

const DialogMapAddCampaign = ({ plotId, plotCampaigns = [], closeModal }) => {
	const [campaignId, setCampaignId] = useState(null)

	const { useCampaigns } = useInfoPage({
		type: 'agriculture'
	})

	const { data: campaigns, status: campaignsStatus } = useCampaigns()

	const { useUpdate, queryClient } = useCRUD({
		baseKey: ['campaigns', { type: 'agriculture' }, { id: campaignId }],
		url: `campaigns`
	})
	const { mutate: updateCampaign, status: statusUpdateCampaign } = useUpdate({
		config: {
			onSuccess: () => {
				queryClient.invalidateQueries(['map', 'plots', plotId])
				closeModal()
			}
		}
	})

	const handleSubmit = useCallback(
		async ({ campaign_id }) => {
			const campaign = campaigns?.children?.find(({ id }) => id === campaign_id)

			if (campaign) {
				const data = { id: campaign_id, plots: [...campaign.plots, plotId] }
				updateCampaign(data)
			}
		},
		[campaigns]
	)

	const handleError = useCallback(error => {
		console.log(error)
	}, [])

	return (
		<StyledDialogMapAddCampaign>
			<Form
				onSubmit={handleSubmit}
				onError={handleError}
				loading={false}
				schema={schema}
				useFormProps={{
					mode: 'onChange'
				}}
			>
				<PreloaderInside
					loading={
						campaignsStatus === 'loading' || statusUpdateCampaign === 'loading'
					}
					minHeight={300}
				>
					<DialogMapAddCampaignInner
						campaigns={campaigns?.children}
						plotCampaigns={plotCampaigns}
						setCampaignId={setCampaignId}
						onCancel={() => closeModal()}
					/>
				</PreloaderInside>
			</Form>
		</StyledDialogMapAddCampaign>
	)
}

export default DialogMapAddCampaign

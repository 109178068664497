import { useCallback } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { find } from 'lodash'

import { BASE_URL } from '@constants'

import useAjax from '@hooks/ajax'
import useCRUD from '@hooks/crud'

function useInfo() {
	const { useRead } = useCRUD({
		baseKey: ['info'],
		url: `info`
	})

	return useRead()
}

function useInfoData() {
	const queryClient = useQueryClient()
	const info = queryClient.getQueryData('info')

	const getInfoData = useCallback(() => info, [info])

	const getInfoDataItem = useCallback(
		(id, childId) => {
			const item = info[id]
			return find(item, { id: childId })
		},
		[info]
	)

	const getInfoTitles = useCallback(
		id => {
			const titles = info?.titles
			const parsedId = id === 'plots-groups' ? 'groups' : id
			return find(titles, { id: parsedId })
		},
		[info]
	)

	return { getInfoData, getInfoDataItem, getInfoTitles }
}

function useInfoPage({ pageId, categoryId }) {
	const { get } = useAjax()

	const options = {
		ajax: {
			params: {
				type: categoryId
			}
		}
	}

	const useFormData = () => {
		const queryFn = useCallback(
			() => get(`${BASE_URL}/info/${pageId}`, options?.ajax),
			[options]
		)

		return useQuery(
			[`info/${pageId}`, { type: categoryId }],
			queryFn,
			options?.config
		)
	}

	const useHoldings = () => {
		const queryFn = useCallback(
			() => get(`${BASE_URL}/holdings`, options?.ajax),
			[options]
		)

		return useQuery(
			['holdings', { type: categoryId }],
			queryFn,
			options?.config
		)
	}

	const useHarvests = () => {
		const queryFn = useCallback(
			() => get(`${BASE_URL}/harvests`, options?.ajax),
			[options]
		)

		return useQuery(
			['holdings', { type: categoryId }],
			queryFn,
			options?.config
		)
	}

	const useCultivations = () => {
		const queryFn = useCallback(
			() => get(`${BASE_URL}/info/cultivations`, options?.ajax),
			[options]
		)

		return useQuery(
			['cultivations', { type: categoryId }],
			queryFn,
			options?.config
		)
	}

	const useCampaigns = () => {
		const queryFn = useCallback(
			() => get(`${BASE_URL}/campaigns`, options?.ajax),
			[options]
		)

		return useQuery(
			['campaigns', { type: categoryId }],
			queryFn,
			options?.config
		)
	}

	const usePlots = () => {
		const queryFn = useCallback(
			() => get(`${BASE_URL}/plots`, options?.ajax),
			[options]
		)

		return useQuery(['plots', { type: categoryId }], queryFn, options?.config)
	}

	const useGroups = () => {
		const queryFn = useCallback(
			() => get(`${BASE_URL}/groups`, options?.ajax),
			[options]
		)

		return useQuery(['groups', { type: categoryId }], queryFn, options?.config)
	}

	const useJobs = () => {
		const queryFn = useCallback(
			() => get(`${BASE_URL}/jobs`, options?.ajax),
			[options]
		)

		return useQuery(['jobs', { type: categoryId }], queryFn, options?.config)
	}

	return {
		useFormData,
		useHoldings,
		usePlots,
		useGroups,
		useHarvests,
		useCultivations,
		useCampaigns,
		useJobs
	}
}

export { useInfo, useInfoData, useInfoPage }

import produce from 'immer'

export const log = config => (set, get, api) =>
	config(
		args => {
			set(args)
			// console.log('[Store > Report]', '\n', get()?.report)
			// console.log('[Store > campaigns]', '\n', get()?.campaigns)
			// console.log('[Store > activities]', '\n', get()?.activities)
			/* console.log(
				'[Store > activities > formState]',
				'\n',
				get()?.activities?.formState
			) */
		},
		get,
		api
	)

export const immer = config => (set, get, api) =>
	config(
		(partial, replace) => {
			const nextState =
				typeof partial === 'function' ? produce(partial) : partial
			return set(nextState, replace)
		},
		get,
		api
	)

import useCRUD from '@hooks/crud'

function useUser(config = {}) {
	const { useRead } = useCRUD({
		baseKey: ['user'],
		url: `user`
	})

	return useRead({ config })
}

/* function useUser(config = {}) {
	const { get } = useAjax()

	const fetchUser = useCallback(async () => {
		return await get(FETCH_USER)
	}, [get])

	return useQuery('user', fetchUser, config)
} */

export { useUser }

import React, { useEffect } from 'react'
import { useNavigate, useLocation, Link } from 'react-router-dom'
import styled, { css } from 'styled-components/macro'

import { ReactComponent as ProfileIcon } from '@svgs/profile.svg'

import authBg from '@images/auth/bg-login.png'
import logo from '@images/common/logo-okcampo.png'

const StyledAuth = styled.div`
	width: 100%;
	height: 100%;
	overflow-y: auto;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 50px 0;
	background-color: var(--c__white);
	background-image: url(${authBg});
	background-size: auto 100%;
	background-repeat: no-repeat;
	background-position: center 50px;
	background-attachment: fixed;

	& > aside {
		max-width: var(--auth__panel-width);
		width: 100%;
		padding: 0 15px;

		@media (min-width: 768px) {
			padding: 0;
		}

		& > h1 {
			color: var(--c__green-700);
			margin-bottom: 40px;

			span,
			strong {
				display: block;
				text-align: center;
				line-height: 0.9;
			}

			span {
				font-size: 40px;
				font-weight: 400;
			}

			strong {
				font-size: 70px;
				font-weight: 900;
			}
		}

		& > section {
			background-color: var(--c__grey-100);
			padding: 30px;
			border-radius: var(--border__radius--large);
			box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
			display: flex;
			flex-flow: column;
			align-items: center;

			& > h2 {
				font-size: 28px;
				color: var(--c__green-400);
				text-align: center;
				line-height: 1.1;
				margin-bottom: 20px;

				${({ $isTitleBold }) =>
					!!$isTitleBold &&
					css`
						font-weight: 800;
					`}
			}
		}
	}
`

const StyledLogo = styled(Link)`
	margin-bottom: 30px;
	display: flex;
	justify-content: center;
	transition: opacity 150ms ease-out;

	&:hover {
		opacity: 0.7;
	}

	img {
		max-width: 200px;
	}
`

const StyledUserSvg = styled.figure`
	width: 90px;
	height: 90px;
	background-color: var(--c__green);
	color: var(--c__white);
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 100px;
	margin-bottom: 20px;

	svg {
		width: 60%;
		height: 60%;
		fill: currentColor;
	}
`

function Auth({
	children,
	title = null,
	isTitleBold = false,
	isLogoShown = true
}) {
	const navigate = useNavigate()
	const location = useLocation()

	useEffect(() => {
		if (location.pathname === '/') {
			navigate('/login')
		}
	}, [location])

	return (
		<StyledAuth $isTitleBold={isTitleBold}>
			<aside>
				{isLogoShown ? (
					<StyledLogo to="/">
						<img src={logo} alt="OKCampo" />
					</StyledLogo>
				) : (
					<h1>
						<span>Bienvenido a</span>
						<strong>okcampo</strong>
					</h1>
				)}
				<section>
					<StyledUserSvg>
						<ProfileIcon />
					</StyledUserSvg>
					{!!title && <h2>{title}</h2>}
					{children}
				</section>
			</aside>
		</StyledAuth>
	)
}

export default Auth

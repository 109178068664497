import React from 'react'
import styled from 'styled-components/macro'

import { PreloaderInside } from '@components/preloaders'

import Content from '@dashboard/Content'

const StyledLayoutHeadingContentHeading = styled.section`
	width: 100%;
	margin-bottom: 30px;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;

	h1 {
		font-size: 36px;
		color: var(--c__green);
		line-height: 1;
		margin: 0;

		@media (min-width: 768px) {
			font-size: 46px;
		}
	}
`

function LayoutHeadingContent({
	children,
	title,
	loading = false,
	error = null,
	renderActions = null
}) {
	return (
		<Content>
			<PreloaderInside loading={loading} error={error}>
				<>
					<StyledLayoutHeadingContentHeading>
						<h1>{title}</h1>
						{!!renderActions && renderActions()}
					</StyledLayoutHeadingContentHeading>
					{children}
				</>
			</PreloaderInside>
		</Content>
	)
}

export default LayoutHeadingContent

import React, { Fragment } from 'react'
import styled from 'styled-components/macro'
import { Link } from 'react-router-dom'

import { ReactComponent as ArrowRightIcon } from '@svgs/arrow-right.svg'

const StyledBreadcrumb = styled.nav`
	display: flex;
	align-items: center;

	svg {
		width: 10px;
		height: 10px;
		fill: var(--c__grey-450);
	}

	a,
	span {
		font-size: 14px;
		color: var(--c__grey-450);
		padding: 0 10px;
		position: relative;

		&:first-child {
			padding-left: 0;
		}

		&:last-child {
			padding-right: 0;
		}
	}

	a {
		transition: color 150ms ease-out;

		&:hover {
			color: var(--c__green-700);
		}
	}

	span {
		color: var(--c__green-700);
		font-weight: 700;
	}
`

function Breadcrumb({ items = null }) {
	if (!items || !items.length) {
		return <div />
	}
	return (
		<StyledBreadcrumb>
			{items.map(({ id, title, slug, active }, index) => {
				return !active ? (
					<Fragment key={id}>
						<Link to={slug} title={title}>
							{title}
						</Link>
						{index < items.length - 1 && <ArrowRightIcon />}
					</Fragment>
				) : (
					<span key={id} title={title}>
						{title}
					</span>
				)
			})}
		</StyledBreadcrumb>
	)
}

export default Breadcrumb

import { useQuery } from 'react-query'

import { BASE_URL } from '@constants'

import useCRUD from '@hooks/crud'
import useAjax from '@hooks/ajax'
import useMapPlaceStore from '@map/store/place'
import useMapStateStore from '@map/store/state'

function useSigpac() {
	const { post } = useAjax()

	const setSearchPlot = useMapStateStore.use.setSearchPlot()

	const parseSigpacCode = code => {
		if (!code) return null
		return Object.keys(code).reduce((result, key, index, array) => {
			const symbol = index < array.length - 1 ? ':' : ''
			result += `${code[key]}${symbol}`
			return result
		}, '')
	}

	// queries
	const useSigpacByPlot = plotId => {
		const baseKey = ['sigpac', { plotId }]

		const { useRead } = useCRUD({
			baseKey,
			url: `sigpac/plot/${plotId}`
		})

		return useRead({
			config: {
				enabled: !!plotId,
				refetchOnWindowFocus: false,
				staleTime: 0,
				cacheTime: 0
			}
		})
	}

	const useSigpacByFeature = feature => {
		const setSigpac = useMapPlaceStore.use.setSigpac()

		const request = () => {
			return post(`${BASE_URL}/sigpac/fields`, {
				data: { geometry: feature?.geometry }
			})
		}
		const baseKey = ['sigpac', { feature: feature?.id }]

		return useQuery(baseKey, request, {
			enabled: !!feature?.id,
			refetchOnWindowFocus: false,
			staleTime: 0,
			cacheTime: 0,
			onSuccess: data => {
				if (data) {
					setSigpac(data)
				}
			}
		})
	}

	const useSigpacSearch = ({ code, onSuccess = () => {} }) => {
		const parsedCode = parseSigpacCode(code)

		const baseKey = ['sigpac', 'code', parsedCode]

		const { useRead } = useCRUD({
			baseKey,
			url: `sigpac/code`
		})

		return useRead({
			config: {
				enabled: false,
				refetchOnWindowFocus: false,
				staleTime: 0,
				cacheTime: 0,
				onSuccess: data => {
					setSearchPlot(data)
					onSuccess(data)
				}
			},
			ajax: {
				params: { code: parsedCode }
			}
		})
	}

	/* const useSigpacSearch = code => {
		const parsedCode = !code
			? null
			: Object.keys(code).reduce((result, key, index, array) => {
					const symbol = index < array.length - 1 ? ':' : ''
					result += `${code[key]}${symbol}`
					console.log(result)
					return result
			  }, '')
		const baseKey = ['sigpac', 'code', parsedCode]

		const request = () => {
			return post(`${BASE_URL}/sigpac/code`, {
				data: { code: parsedCode }
			})
		}

		return useQuery(baseKey, request, {
			enabled: !!code,
			refetchOnWindowFocus: false,
			staleTime: 0,
			cacheTime: 0,
			onSuccess: data => {
				console.log(data)
			}
		})
	} */

	return {
		useSigpacByPlot,
		useSigpacByFeature,
		useSigpacSearch
	}
}

export default useSigpac

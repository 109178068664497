import React, { useCallback, useState } from 'react'
import styled from 'styled-components/macro'

import { ReactComponent as BurgerIcon } from '@svgs/burger.svg'

import SidePanel from '@components/sidepanel/SidePanel'
import Menu from '@dashboard/menu/Menu'

const StyledBurger = styled.button`
	width: 25px;
	height: 20px;
	color: var(--c__green);
	position: relative;
	cursor: pointer;
	transition: all 150ms ease-out;

	&:hover {
		opacity: 0.7;
	}

	svg {
		width: 100%;
		height: 100%;
		fill: currentColor;
	}
`
const StyledMenu = styled.div`
	background-color: var(--c__white);
	width: 100%;
	height: 100%;
	box-shadow: -1px 0 40px rgba(0, 0, 0, 0.15);
`
const StyledMenuContent = styled.section`
	position: relative;
	height: 100%;
	overflow-y: auto;

	::-webkit-scrollbar {
		width: 8px;
	}

	::-webkit-scrollbar-track {
		background-color: rgba(0, 0, 0, 0.1);
		border-radius: 5px;
	}

	::-webkit-scrollbar-thumb {
		background-color: rgba(0, 0, 0, 0.2);
		border-radius: 5px;

		&:hover {
			background-color: rgba(0, 0, 0, 0.3);
		}
		&:active {
			background-color: rgba(0, 0, 0, 0.4);
		}
	}
`

function MenuMobile() {
	return (
		<StyledMenu>
			<StyledMenuContent>
				<Menu />
			</StyledMenuContent>
		</StyledMenu>
	)
}

function Burger() {
	const [menuOpened, setMenuOpened] = useState(false)

	const handleClickToggleMenu = useCallback(() => {
		setMenuOpened(!menuOpened)
	}, [menuOpened])

	return (
		<>
			<StyledBurger onClick={handleClickToggleMenu}>
				<BurgerIcon />
			</StyledBurger>
			<SidePanel
				isOpened={menuOpened}
				portalSelector="#root"
				width={300}
				onClose={() => setMenuOpened(false)}
			>
				{() => <MenuMobile />}
			</SidePanel>
		</>
	)
}

export default Burger

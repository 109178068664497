import React, { useCallback } from 'react'
import styled from 'styled-components/macro'
import { Link } from 'react-router-dom'

import yup from '@yup'

import useActivities from '@hooks/activities'
import useCRUD from '@hooks/crud'
import useSelect from '@hooks/select'

import { Form, TextField, Select } from '@components/form'
import { PreloaderInside } from '@components/preloaders'
import { Text } from '@components/text'
import { Button } from '@components/button'
import { Grid, GridItem } from '@components/grid'
import { Flex } from '@components/flex'

const StyledActivitiesPanelNewPlotsGroup = styled.div`
	width: 100%;
	height: 100%;
`

const schema = yup.object().shape({
	title: yup.string().required(),
	plots: yup.array().selectMulti()
})

const ActivitiesPanelNewPlotsGroupInner = ({ plots }) => {
	const { select: plotsSelect } = useSelect({
		name: 'plots',
		collection: plots
	})

	return (
		<Grid>
			<GridItem columnStart="span 12">
				<TextField
					name="title"
					placeholder="Escribe el nombre"
					label="Nombre *"
					inverted
				/>
			</GridItem>
			<GridItem columnStart="1" columnEnd="-1">
				<Select
					options={plotsSelect}
					name="plots"
					label="Campo *"
					placeholder="Selecciona una parcela"
					inverted
					isMulti
				/>
			</GridItem>
			<GridItem columnStart="1" columnEnd="-1">
				<Flex justifyContent="flex-end" alignItems="center">
					<Button
						label={'Guardar'}
						type="submit"
						theme="primary"
						size="large"
					/>
				</Flex>
			</GridItem>
			<GridItem columnStart="1" columnEnd="-1">
				<Flex justifyContent="center">
					<Text>
						<p>
							<strong>Consejo: </strong>
							Para introducir más detalles haz{' '}
							<Link to="/agriculture/fields/groups" target="_blank">
								click aquí
							</Link>
						</p>
					</Text>
				</Flex>
			</GridItem>
		</Grid>
	)
}

const DialogActivitiesNewPlotsGroup = ({ item, closeModal }) => {
	const { useActivitiesCreateChild } = useActivities()

	const { mutateAsync, status: statusCreate } = useActivitiesCreateChild(
		'plots_groups',
		{
			url: 'info/activities/plots_groups'
		}
	)

	const { useRead: usePlots } = useCRUD({
		baseKey: ['activities', 'info', 'plots'],
		url: `info/activities/plots`
	})

	const { status, data } = usePlots()

	const handleSubmit = async data => {
		console.log('submit', data)

		await mutateAsync(data)

		closeModal()
	}

	const handleError = useCallback(error => {
		console.log(error)
	}, [])

	return (
		<StyledActivitiesPanelNewPlotsGroup>
			<Form
				onSubmit={handleSubmit}
				onError={handleError}
				loading={statusCreate === 'loading'}
				schema={schema}
				hasPrompt={false}
			>
				<PreloaderInside loading={status === 'loading'}>
					<ActivitiesPanelNewPlotsGroupInner plots={data} />
				</PreloaderInside>
			</Form>
		</StyledActivitiesPanelNewPlotsGroup>
	)
}

export default DialogActivitiesNewPlotsGroup

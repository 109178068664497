import React, { useState, useCallback, useEffect } from 'react'
import styled from 'styled-components/macro'

import yup from '@yup'

import useSigpac from '@hooks/sigpac'

import SVG from '@components/SVG'
import { Text } from '@components/text'
import { Button } from '@components/button'
import { Form, TextField } from '../components/form'

const schema = yup.object().shape({
	PRO: yup.string().required().integer(),
	MUN: yup.string().required().integer(),
	ZON: yup.string().required().integer(),
	AGR: yup.string().required().integer(),
	POL: yup.string().required().integer(),
	PAR: yup.string().required().integer(),
	REC: yup.string().required().integer()
})

const StyledDialogSigpacSearch = styled.div`
	width: 100%;
	height: 100%;
`

const StyledSigpacInputs = styled.div`
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
	gap: 10px;

	& > div {
		min-width: 80px;
	}
`

const StyledSigpacInputsActions = styled.div`
	padding-top: 20px;
	margin-top: 20px;
	border-top: 1px solid var(--c__grey-300);
	display: flex;
	align-items: center;
	justify-content: flex-end;
`

const DialogSearchSigpacInner = () => {
	return (
		<>
			<Text>
				<p>Introduce el código SIGPAC para buscar un recinto</p>
			</Text>

			<StyledSigpacInputs>
				<TextField
					type="number"
					name="PRO"
					placeholder="PRO"
					inverted
					showError={false}
					autoFocus
					// disabled={true}
				/>
				<TextField
					type="number"
					name="MUN"
					placeholder="MUN"
					inverted
					showError={false}
				/>
				<TextField
					type="number"
					name="AGR"
					placeholder="AGR"
					inverted
					showError={false}
				/>
				<TextField
					type="number"
					name="ZON"
					placeholder="ZON"
					inverted
					showError={false}
				/>
				<TextField
					type="number"
					name="POL"
					placeholder="POL"
					inverted
					showError={false}
				/>
				<TextField
					type="number"
					name="PAR"
					placeholder="PAR"
					inverted
					showError={false}
				/>
				<TextField
					type="number"
					name="REC"
					placeholder="REC"
					inverted
					showError={false}
				/>
			</StyledSigpacInputs>
			<StyledSigpacInputsActions>
				<Button
					type="submit"
					label="Buscar"
					theme="primary"
					size="large"
					icon={<SVG name={'loupe'} width={22} height={22} />}
				/>
			</StyledSigpacInputsActions>
		</>
	)
}

const DialogSigpacSearch = ({ closeModal }) => {
	const { useSigpacSearch } = useSigpac()
	const [sigpacCode, setSigpacCode] = useState(null)

	const { status: statusSearch, refetch } = useSigpacSearch({
		code: sigpacCode,
		onSuccess: () => {
			closeModal()
		}
	})

	const handleSubmit = useCallback(data => {
		setSigpacCode(data)
	}, [])

	const handleError = useCallback(error => {
		console.error(error)
	}, [])

	useEffect(() => {
		if (sigpacCode) {
			refetch()
		}
	}, [sigpacCode])

	return (
		<StyledDialogSigpacSearch>
			<Form
				onSubmit={handleSubmit}
				onError={handleError}
				loading={statusSearch === 'loading'}
				schema={schema}
				hasPrompt={false}
			>
				<DialogSearchSigpacInner />
			</Form>
		</StyledDialogSigpacSearch>
	)
}

export default DialogSigpacSearch

import React, { isValidElement, useMemo, useCallback } from 'react'
import styled from 'styled-components/macro'
import { useQueryClient } from 'react-query'
import ReactHTMLParser from 'html-react-parser'
import { Link, useParams } from 'react-router-dom'
import { isBoolean, isString, intersectionBy, omit } from 'lodash'

import useStore from '@store/store'
import useCRUD from '@hooks/crud'
import useDialogs from '@hooks/dialogs'
import useNumber from '@hooks/number'

import { ReactComponent as DropdownIcon } from '@svgs/menu-points.svg'

import { Dropdown, DropdownTrigger } from '@components/dropdown'
import { PreloaderCenter } from '@components/preloaders'
import { Button } from '@components/button'

const useFetchSingle = ({
	tableKey,
	url,
	baseKey,
	config = {},
	onSuccess = () => {}
}) => {
	const set = useStore(({ set }) => set)

	const { useRead } = useCRUD({
		baseKey,
		url
	})

	return useRead({
		config: {
			staleTime: Infinity,
			refetchOnWindowFocus: false,
			onSuccess: data => {
				/* set(({ currentTable }) => {
					currentTable[tableKey] = {
						...currentTable[tableKey],
						[data?.id]: {
							id: data?.id,
							title: data?.title
						}
					}
				}) */

				onSuccess()
			}
		},
		...config
	})
}

export const getValue = (key, data) => {
	if (!data) return '-'
	return isBoolean(data[key]) ? (data[key] ? 'Sí' : 'No') : data[key] || '-'
}

export const getHectareasValue = (key, data) => {
	return data ? `<span>${data[key]}</span><sup>has</sup>` : getValue(key, data)
}

export const CellEmpty = () => <span>{'-'}</span>

export const CellList = styled.div`
	width: 100%;
	max-height: 150px;
	overflow-y: auto;
	padding-right: 5px;

	::-webkit-scrollbar {
		width: 8px;
	}

	::-webkit-scrollbar-track {
		background-color: rgba(0, 0, 0, 0.1);
		border-radius: 5px;
	}

	::-webkit-scrollbar-thumb {
		background-color: rgba(0, 0, 0, 0.2);
		border-radius: 5px;

		&:hover {
			background-color: rgba(0, 0, 0, 0.3);
		}
		&:active {
			background-color: rgba(0, 0, 0, 0.4);
		}
	}

	& > * {
		display: block;
		width: 100%;
		margin-bottom: 5px;

		&:last-child {
			margin-bottom: 0;
		}
	}
`

export const CellField = ({ children, value, style = {} }) =>
	isBoolean(value) ? (
		value ? (
			'Sí'
		) : (
			'No'
		)
	) : !value ? (
		<CellEmpty />
	) : (
		children || (
			<span style={style}>{isString(value) ? value : value?.title}</span>
		)
	)

export const tableSimpleColumns = [
	{
		accessor: 'key',
		width: 200,
		minWidth: 100
	},
	{
		accessor: 'value',
		Cell: data => {
			if (!data.value) return null

			const value = data.row.original.value

			return isValidElement(value) ? (
				<strong>{value}</strong>
			) : (
				<strong>{ReactHTMLParser(value)}</strong>
			)
		}
	}
]

export const CellOptions = ({ children }) => (
	<Dropdown
		timeout={null}
		closeOnClick
		trigger={({ opened }) => (
			<DropdownTrigger opened={opened} icon={<DropdownIcon />} />
		)}
	>
		{children}
	</Dropdown>
)

export const CellLink = ({ value, url, target = '_self' }) => (
	<CellField value={value}>
		<Link to={url} title={value} target={target}>
			{value}
		</Link>
	</CellField>
)

export const CellButton = ({ value, onClick = () => {}, ...restProps }) => (
	<CellField value={value}>
		<Button label={value} onClick={onClick} {...restProps} />
	</CellField>
)

export const CellHectareas = ({ value, suffix = 'has', style = {} }) => {
	const { format } = useNumber()
	return (
		<CellField value={value}>
			<span style={style}>
				{format(value)}
				<sup>{suffix}</sup>
			</span>
		</CellField>
	)
}

export const CellHarvest = ({ value, unit }) => (
	<CellField value={value}>
		<span>
			{value}
			<sup>{unit}</sup>
		</span>
	</CellField>
)
export const CellTotalHectareasCampos = ({ areaSuma, campos }) => {
	return (
		<CellList>
			<CellHectareas value={areaSuma} />
			<CellField value={campos}>
				<span>
					{campos} {campos[0] === '1' ? 'parcela' : 'parcelas'}
				</span>
			</CellField>
		</CellList>
	)
}

export const CellLoading = ({ children, status }) =>
	status !== 'success' ? <PreloaderCenter size="small" /> : children

export const CellHolding = ({ id, url }) => {
	const { data, status } = useFetchSingle({
		tableKey: 'holdings',
		url: `holdings/${id}`,
		baseKey: ['holdings', { id }]
	})

	return !id ? (
		<CellEmpty />
	) : (
		<CellLoading status={status}>
			<CellLink url={url} value={data?.title} />
		</CellLoading>
	)
}

export const CellPlot = ({ id, url }) => {
	const { data, status } = useFetchSingle({
		tableKey: 'plots',
		url: `plots/${id}`,
		baseKey: ['plots', { id }]
	})

	if (status === 'error') {
		return <CellEmpty />
	}

	return (
		<CellLoading status={status}>
			<CellLink url={url} value={data?.title} />
		</CellLoading>
	)
}
export const CellGroup = ({ id, url }) => {
	const { data, status } = useFetchSingle({
		tableKey: 'groups',
		url: `groups/${id}`,
		baseKey: ['groups', { id }]
	})

	return (
		<CellLoading status={status}>
			<CellLink url={url} value={`${data?.title}`} />
		</CellLoading>
	)
}

export const CellCampaign = ({ id, url }) => {
	const { data, status } = useFetchSingle({
		tableKey: 'campaigns',
		url: `campaigns/${id}`,
		baseKey: ['campaigns', { id }]
	})

	return !id ? (
		<CellEmpty />
	) : (
		<CellLoading status={status}>
			<CellLink url={url} value={`${data?.title} (${data?.year})`} />
		</CellLoading>
	)
}
export const CellCultivation = ({ id }) => {
	const { data, status } = useFetchSingle({
		tableKey: 'cultivations',
		url: `cultivations/${id}`,
		baseKey: ['cultivations', { id }]
	})

	return (
		<CellLoading status={status}>
			<span>{data?.title}</span>
		</CellLoading>
	)
}

const CellInfoInner = ({ category, id, data }) => {
	const value = useMemo(() => {
		return data[category].find(item => item.id === id)?.title
	}, [data, category, id])

	return <CellField value={value} />
}
export const CellInfo = ({ queryId, category, id }) => {
	const { categoryId } = useParams()

	const { data, status } = useFetchSingle({
		tableKey: 'info',
		url: `info/${queryId}?type=${categoryId}`,
		baseKey: [`info/${queryId}`, { type: categoryId }]
	})

	return (
		<CellLoading status={status}>
			<CellInfoInner category={category} id={id} data={data} />
		</CellLoading>
	)
}
export const CellActivities = ({ id, category, url }) => {
	const { data, status } = useFetchSingle({
		tableKey: category,
		url: `${category}/${id}`,
		baseKey: [category, { id }]
	})

	return !id ? (
		<CellEmpty />
	) : (
		<CellLoading status={status}>
			<CellLink url={url} value={data?.title} />
		</CellLoading>
	)
}

export const CellPhytosanitary = ({ id }) => {
	const { data, status } = useFetchSingle({
		tableKey: 'phytosanitaries',
		url: `phytosanitaries/${id}`,
		baseKey: ['phytosanitaries', { id }]
	})

	return !id ? (
		<CellEmpty />
	) : (
		<CellLoading status={status}>
			<CellLink
				url={`/agriculture/products/phytosanitaries/${id}`}
				value={data?.title}
			/>
		</CellLoading>
	)
}

export const CellActiviAreaPlot = ({ plots }) => {
	const { format } = useNumber()

	let sum = 0
	const { useRead: usePlots } = useCRUD({
		baseKey: ['activities', 'info', 'plots'],
		url: `info/activities/plots`
	})

	const { status, data } = usePlots()

	if (status === 'loading') return null

	const area = intersectionBy(data, plots, 'id')
	area.forEach(values => {
		sum += parseFloat(values.area)
	})
	return sum === 0 ? (
		<CellEmpty />
	) : (
		<CellField value={`${format(sum.toFixed(2))} ha`} />
	)
}

export const CellMachineryTask = ({ id, machineId }) => {
	const queryClient = useQueryClient()
	const { createMachineryTask } = useDialogs()

	const cached = queryClient.getQueryData([
		'machinery_tasks',
		{ machine_id: machineId }
	])

	const { useRead } = useCRUD({
		baseKey: ['machinery_tasks', { machine_id: machineId }, id],
		url: `machinery_tasks/${id}`
	})

	const { status, data } = useRead({
		config: {
			onSuccess: data => {
				if (!cached) {
					queryClient.setQueryData(
						[`machinery_tasks`, { machine_id: machineId }],
						old => {
							return {
								children: [...(old?.children || []), omit(data, ['slug'])]
							}
						}
					)
				}
			}
		},
		ajax: {
			params: {
				machine_id: machineId
			}
		}
	})

	const handleEditTask = useCallback(async data => {
		createMachineryTask({ data, isEdit: true })
	}, [])

	return (
		<CellLoading status={status}>
			<CellButton
				value={data?.title}
				theme="link"
				onClick={e => handleEditTask(data)}
			/>
		</CellLoading>
	)
}

import React, { useCallback, useMemo, useState, useEffect } from 'react'
import ReactSelect, { components } from 'react-select'
import styled, { css } from 'styled-components/macro'
import { useFormContext, useController } from 'react-hook-form'
import { isArray, isEmpty } from 'lodash'

import { FaCheck } from 'react-icons/fa'

import { MULTISELECT_ALL_KEY } from '@constants'

import Label from './Label'
import ErrorMessage from './ErrorMessage'

const StyledSelect = styled(ReactSelect)`
	width: 100%;
	max-width: ${({ $width }) => ($width ? `${$width}px` : '100%')};
	font-size: 14px;
	background-color: var(--c__white);

	@media (min-width: 768px) {
		font-size: 16px;
	}

	${({ $size }) =>
		$size === 'small' &&
		css`
			font-size: 14px;
		`}

	.select__control {
		font-size: 1em;
		color: var(--c__grey-500);
		border: 1px solid var(--c__white);
		box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
		border-radius: var(--border__radius--small);
		min-height: var(--textfield__height);

		${({ $size }) =>
			$size === 'small' &&
			css`
				min-height: var(--textfield__height-small);
			`}

		&:hover {
			box-shadow: 0 3px 6px rgba(0, 0, 0, 0.24);
		}

		&--menu-is-open {
			.select__indicator:not(.select__clear-indicator) {
				transform: rotate(180deg);
			}
		}

		${({ $inverted }) =>
			!!$inverted &&
			css`
				border-color: var(--c__grey-200);
				background-color: var(--c__grey-200);
				box-shadow: none;

				&:hover {
					border-color: var(--c__grey-200);
					box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
				}
			`}

		${({ $error }) =>
			!!$error &&
			css`
				color: var(--c__white);
				border-color: var(--c__error);
				background-color: var(--c__error);
				box-shadow: none;

				&:hover {
					border-color: var(--c__error);
					box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
				}
			`}
	}
	.select__multi-value {
		padding: 5px;
		border-radius: 4px;
		max-width: 48%;

		${({ $inverted }) =>
			!!$inverted &&
			css`
				background-color: var(--c__white);
			`}
	}
	.select__placeholder {
		${({ $error }) =>
			!!$error &&
			css`
				color: var(--c__white);
			`}
	}
	.select__indicator-separator {
		display: none;
	}
	.select__indicator {
		margin-top: 5px;
		color: var(--c__grey-700);
		transition: transform 150ms ease-out;

		${({ $error }) =>
			!!$error &&
			css`
				color: var(--c__white);
			`}
	}
	.select__indicators {
		align-items: flex-start;
	}
	.select__menu {
		border: none;
		box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
		z-index: 99;
	}
	.select__option {
		background-color: var(--c__white);
		font-size: 1em;
		color: var(--c__grey-500);

		&:hover {
			background-color: var(--c__grey-150);
		}

		&--is-selected {
			background-color: var(--c__grey-150);
			color: var(--c__grey-700);
			font-weight: 700;
		}
	}
`

const StyledSelectMultiValue = styled.div`
	width: 100%;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
`

const StyledSelectMultiValueCheck = styled.div`
	display: flex;
	justify-content: center;
	gap: 10px;

	span {
		width: 17px;
		height: 17px;
		color: currentColor;
		border: 1px solid var(--c__grey-500);
		border-radius: 4px;
		display: flex;
		align-items: center;
		justify-content: center;

		svg {
			width: 70%;
			height: 70%;
			fill: currentColor;
		}
	}
`
const selectAllOption = {
	value: MULTISELECT_ALL_KEY,
	label: 'Seleccionar todo'
}

const MultiValue = props => {
	const title = useMemo(() => {
		return props.data.value === selectAllOption.value
			? 'Todo seleccionado'
			: props.data.label
	}, [props.data.value])

	return (
		<components.MultiValue {...props}>
			<StyledSelectMultiValue title={title}>
				<span>{title}</span>
			</StyledSelectMultiValue>
		</components.MultiValue>
	)
}

const ValueContainer = ({ children, ...props }) => {
	const currentValues = props.getValue()
	let toBeRendered = children
	if (currentValues.some(val => val.value === selectAllOption.value)) {
		toBeRendered = [[children[0][0]], children[1]]
	}

	return (
		<components.ValueContainer {...props}>
			{toBeRendered}
		</components.ValueContainer>
	)
}

const InputOption = ({
	getStyles,
	isDisabled,
	isFocused,
	isSelected,
	children,
	innerProps,
	...rest
}) => {
	const [isActive, setIsActive] = useState(false)
	const onMouseDown = () => setIsActive(true)
	const onMouseUp = () => setIsActive(false)
	const onMouseLeave = () => setIsActive(false)

	// styles
	let bg = 'transparent'
	if (isFocused) bg = 'var(--c__grey-100)'
	if (isActive) bg = 'var(--c__green-200)'

	const style = {
		alignItems: 'center',
		backgroundColor: bg,
		color: 'inherit',
		display: 'flex '
	}

	// prop assignment
	const props = {
		...innerProps,
		onMouseDown,
		onMouseUp,
		onMouseLeave,
		style
	}

	return (
		<components.Option
			{...rest}
			isDisabled={isDisabled}
			isFocused={isFocused}
			isSelected={isSelected}
			getStyles={getStyles}
			innerProps={props}
		>
			<StyledSelectMultiValueCheck>
				<span>{isSelected && <FaCheck />}</span>
				{children}
			</StyledSelectMultiValueCheck>
		</components.Option>
	)
}

function Select({
	options = [],
	name = '',
	defaultValue = null,
	value = null,
	className = 'select',
	classNamePrefix = 'select',
	label = null,
	rules = null,
	width = 0,
	size = 'default',
	showError = true,
	errorMessage = 'Este campo es obligatorio',
	placeholder = 'Selecciona una opción',
	inverted = false,
	isMulti = false,
	components = {},
	onChange = () => {},
	...restProps
}) {
	const {
		control,
		setValue,
		getValues,
		resetField,
		formState: { errors }
	} = useFormContext()

	const { field } = useController({
		name,
		control,
		rules,
		defaultValue: isMulti ? defaultValue || [] : defaultValue?.value
	})
	const { ref, ...fieldRest } = field

	const error = !!errors[name]
	const customErrorMessage = errors ? errors[name]?.message : null

	const parsedOptions = useMemo(() => {
		return isMulti ? [selectAllOption, ...options] : options
	}, [isMulti, options])

	const currentValue = useMemo(() => {
		if (isArray(fieldRest.value)) {
			return parsedOptions.filter(option =>
				fieldRest.value.includes(option.value)
			)
		} else {
			return parsedOptions.find(
				option =>
					fieldRest.value === option.value ||
					fieldRest?.value?.id === option.value
			)
		}
	}, [parsedOptions, isMulti, fieldRest.value])

	const finalComponents = useMemo(() => {
		let result = { ...components }
		if (isMulti) {
			result = { ...result, Option: InputOption, ValueContainer, MultiValue }
		}
		return result
	}, [isMulti, components])

	const handleChange = useCallback(
		(data, { action, option, removedValue }) => {
			if (isMulti) {
				if (
					action === 'select-option' &&
					option?.value === selectAllOption.value
				) {
					fieldRest.onChange(
						Array.from(parsedOptions).map(({ value }) => value)
					)
				} else if (
					action === 'deselect-option' &&
					option.value === selectAllOption.value
				) {
					fieldRest.onChange([])
				} else if (
					action === 'remove-value' &&
					removedValue.value === selectAllOption.value
				) {
					fieldRest.onChange([])
				} else if (
					action === 'deselect-option' &&
					data.length === options.length
				) {
					fieldRest.onChange(
						options
							.filter(({ value }) => value !== option.value)
							.map(({ value }) => value)
					)
				} else {
					fieldRest.onChange(
						!data ? [] : Array.from(data).map(({ value }) => value)
					)
				}
			} else {
				fieldRest.onChange(data.value)
			}

			onChange(data)
		},
		[isMulti, parsedOptions, onChange, fieldRest]
	)

	useEffect(() => {
		if (!isEmpty(value) && getValues(name) !== value) {
			setValue(name, value.value)
		}
	}, [value])

	/* useEffect(() => {
		setValue(name, isMulti ? defaultValue || [] : defaultValue?.value)
	}, []) */

	return (
		<>
			{label && <Label htmlFor={name} title={label} size={size} />}
			<StyledSelect
				{...fieldRest}
				styles={{
					option: (styles, { isDisabled }) => {
						return {
							...styles,
							opacity: isDisabled ? 0.5 : 1,
							cursor: isDisabled ? 'not-allowed' : 'default'
						}
					}
				}}
				inputRef={ref}
				onChange={handleChange}
				value={currentValue}
				components={finalComponents}
				className={className}
				classNamePrefix={classNamePrefix}
				options={parsedOptions}
				$width={width}
				$size={size}
				$error={error}
				$inverted={inverted}
				defaultValue={defaultValue?.value}
				placeholder={placeholder}
				isMulti={isMulti}
				closeMenuOnSelect={!isMulti}
				hideSelectedOptions={!isMulti}
				isOptionDisabled={({ disabled }) => disabled}
				{...restProps}
			/>
			{showError && error && (
				<ErrorMessage message={customErrorMessage || errorMessage} />
			)}
		</>
	)
}

export default Select

// import { random } from 'lodash'

import createStore from '@store/createStore'
import { createSelectors } from '@store/utils'

import { MAP_DEFAULTS } from '@constants'

const initialState = {
	loading: false,
	mode: null, // 'creating' | 'modifying' | 'multiselect'
	plots: null,
	selectedPlots: [],
	searchPlot: null,
	feature: null,
	drawActive: false,
	activitiesActive: false,
	currentZoom: 0
}

const mockColors = ['#f94144', '#f9c74f', '#4d908e']

const parsePlotsVariants = plots => {
	if (!plots?.features?.length) return []
	return {
		...plots,
		features: plots.features.map(feature => {
			return {
				...feature,
				properties: {
					...feature.properties,
					// variant,
					// color: mockColors[random(0, mockColors.length - 1)]
					variant: 'plots'
				}
			}
		})
	}
}

const state = (set, get) => ({
	...initialState,
	setPlots: plots => {
		set(state => {
			state.plots = plots
			// state.plots = parsePlotsVariants(plots)
		})
	},
	addPlot: plot => {
		set(state => {
			const plots = state.plots
			if (plots) {
				state.plots = {
					...plots,
					features: [...plots?.features, plot]
				}
			}
		})
	},
	setSearchPlot: plot => {
		if (!plot) return null
		set(state => {
			const plots = state.plots
			if (plots) {
				const searchPlot = {
					...plot,
					properties: { ...plot.properties, variant: 'search' }
				}

				state.plots.features = state.plots.features.filter(
					feature => feature.properties.variant !== 'search'
				)
				state.plots.features.push(searchPlot)
				state.searchPlot = searchPlot
			}
		})
	},
	reset: () => {
		set(state => {
			for (const key in initialState) {
				state[key] = initialState[key]
			}
		})
	},
	canRefetch: () => {
		const { mode, currentZoom } = get()
		return !mode && currentZoom > MAP_DEFAULTS?.minZoomFetch
	}
})

const useMapStateStore = createStore({
	state,
	log: {
		name: '✏️ MapStateStore ✏️'
	}
})

export default createSelectors(useMapStateStore)

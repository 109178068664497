import React from 'react'
import styled from 'styled-components/macro'
import { motion, AnimatePresence } from 'framer-motion'

import Spinner from './Spinner'

const StyledPreloaderInside = styled(motion.aside)`
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
`
const StyledPreloaderInsideError = styled.div`
	display: flex;
	flex-flow: column;
	align-items: center;

	p {
		font-size: 30px;
		text-align: center;
	}
`

function PreloaderInside({
	children,
	loading = false,
	minHeight = '100%',
	error = null,
	errorMessage = 'Parece que hubo un error',
	...restProps
}) {
	const variants = {
		initial: {
			scale: 0.7,
			opacity: 0
		},
		opened: {
			scale: 1,
			opacity: 1,
			transition: {
				duration: 0.2
			}
		},
		closed: {
			scale: 0.7,
			opacity: 0,
			transition: {
				duration: 0.2
			}
		}
	}

	return (
		<>
			<AnimatePresence>
				{loading ? (
					<StyledPreloaderInside
						initial="initial"
						animate="opened"
						exit="closed"
						variants={variants}
						style={{ minHeight }}
					>
						{error ? (
							<StyledPreloaderInsideError>
								<p>{errorMessage}</p>
							</StyledPreloaderInsideError>
						) : (
							<Spinner
								size={28}
								strokeWidth={10}
								strokeColor="#969696"
								{...restProps}
							/>
						)}
					</StyledPreloaderInside>
				) : (
					children
				)}
			</AnimatePresence>
		</>
	)
}

export default PreloaderInside

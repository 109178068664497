import React from 'react'

import { useInfoPage } from '@hooks/info'
import useSelect from '@hooks/select'

import { Select } from '@components/form'

export default function SelectHoldings({
	name = 'holding_id',
	label = 'Explotaciones *',
	data = null,
	inverted = false,
	placeholder = 'Selecciona la explotación',
	...restProps
}) {
	const { useHoldings } = useInfoPage({
		categoryId: 'agriculture'
	})

	const { data: holdings } = useHoldings()

	const { select, selectValue } = useSelect({
		name,
		collection: holdings?.children,
		value: data ? data[name] : null
	})

	return (
		<Select
			options={select}
			name={name}
			label={label}
			defaultValue={selectValue}
			placeholder={placeholder}
			inverted={inverted}
			{...restProps}
		/>
	)
}

import React, { useEffect } from 'react'

import { useAuth } from '@context/auth'
import yup from '@yup'

import { Form, TextField } from '@components/form'
import { Button } from '@components/button'
import { Box } from '@components/layout'

import Auth from './Auth'

const schema = yup.object().shape({
	email: yup.string().required().email(),
	password: yup.string().required().min(8)
})

function Login() {
	const { login, status, resetStatus, error } = useAuth()

	function handleSubmit(values) {
		login(values)
	}

	useEffect(() => {
		if (status === 'success') {
			resetStatus()
		}
	}, [status])

	return (
		<Auth isLogoShown={true} isTitleBold>
			<Form
				loading={status === 'loading'}
				schema={schema}
				onSubmit={handleSubmit}
				hasPrompt={false}
			>
				<Box>
					<TextField
						type="email"
						name="email"
						label="Email"
						placeholder="Escribe tu email"
					/>
				</Box>
				<Box>
					<TextField
						type="password"
						name="password"
						label="Contraseña"
						placeholder="Escribe tu contraseña"
						hasVisibilityToggle
					/>
				</Box>
				<Box>
					<Button
						label="¿Has olvidado tu contraseña?"
						to="/auth/forgot-password"
						full
						center
					/>
				</Box>
				<Box>
					<Button
						label="Iniciar sesión"
						type="submit"
						theme="primary"
						size="large"
						full
						center
					/>
				</Box>
				{/* <Box>
					<Button label="Crear Cuenta nueva" theme="secondary" full center />
				</Box> */}
			</Form>
		</Auth>
	)
}

export default Login

import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { useAuth } from '@context/auth'
import { useNotifications } from '@notifications'
import yup from '@yup'

import { Form, TextField } from '@components/form'
import { Button } from '@components/button'
import { Text } from '@components/text'
import { Box } from '@components/layout'

import Auth from './Auth'

const schema = yup.object().shape({
	code: yup
		.string()
		.required()
		.integer('El código sólo puede contener números')
		.min(6, 'El código debe contener ${min} dígitos')
		.max(6, 'El código debe contener ${max} dígitos'),
	email: yup.string().required().email(),
	password: yup
		.string()
		.required()
		.min(8, 'La contraseña debe tener ${min} caracteres como mínimo'),
	passwordRepeat: yup
		.string()
		.required()
		.oneOf([yup.ref('password')], 'La contraseña no coincide')
})

function ResetPassword() {
	const navigate = useNavigate()
	const { notification } = useNotifications()
	const { resetPassword, status, resetStatus, error } = useAuth()

	function handleSubmit(values) {
		const { code, email, password } = values
		resetPassword({ code, email, password })
	}

	useEffect(() => {
		if (status === 'success') {
			resetStatus()
			notification({
				title: 'Contraseña actualizada',
				description: 'Úsala en el formulario de acceso para entrar',
				id: 'reset-password',
				type: 'success',
				timeout: 12000
			})
			navigate('/auth/login')
		}
	}, [status])

	return (
		<Auth title="Nueva contraseña" isTitleBold>
			<Form
				loading={status === 'loading'}
				schema={schema}
				hasPrompt={false}
				onSubmit={handleSubmit}
			>
				<Box>
					<Text size="medium" center>
						<p>
							Introduce el <strong>código</strong> que te hemos enviado al
							email.
							<br />A continuación, introduce tu <strong>email</strong> y la{' '}
							<strong>nueva contraseña</strong>
						</p>
					</Text>
				</Box>
				<Box>
					<TextField
						type="text"
						name="code"
						label="Código *"
						placeholder="Escribe el código recibido"
					/>
				</Box>
				<Box>
					<TextField
						type="email"
						name="email"
						label="Email *"
						placeholder="Escribe tu email"
					/>
				</Box>
				<Box>
					<TextField
						type="password"
						name="password"
						label="Nueva contraseña *"
						placeholder="Mínimo 8 caracteres"
						hasVisibilityToggle
						rules={{
							required: true
						}}
					/>
				</Box>
				<Box>
					<TextField
						type="password"
						name="passwordRepeat"
						label="Repite la nueva contraseña *"
						placeholder="Escribe la nueva contraseña de nuevo"
						hasVisibilityToggle
						rules={{
							required: true
						}}
					/>
				</Box>
				<Box>
					<Button
						label="Actualizar contraseña"
						type="submit"
						theme="primary"
						size="large"
						full
						center
					/>
				</Box>
				<Box>
					<Button label="Volver al inicio" to="/auth/login" full center />
				</Box>
			</Form>
		</Auth>
	)
}

export default ResetPassword

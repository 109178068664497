import React from 'react'

import Dialog from '@dialogs/Dialog'
import DialogPrompt from '@dialogs/DialogPrompt'
import DialogPlotsImport from '@dialogs/DialogPlotsImport'
import DialogSigpacSearch from '@dialogs/DialogSigpacSearch'
import DialogSigpacPlotsSearch from '@dialogs/DialogSigpacPlotsSearch'
import DialogMapNewPlot from '@dialogs/DialogMapNewPlot'
import DialogActivitiesNewJob from '@dialogs/DialogActivitiesNewJob'
import DialogActivitiesNewWorker from '@dialogs/DialogActivitiesNewWorker'
import DialogActivitiesNewMachine from '@dialogs/DialogActivitiesNewMachine'
import DialogActivitiesNewSeed from '@dialogs/DialogActivitiesNewSeed'
import DialogActivitiesNewFertilizer from '@dialogs/DialogActivitiesNewFertilizer'
import DialogActivitiesNewHarvest from '@dialogs/DialogActivitiesNewHarvest'
import DialogActivitiesNewWarehouse from '@dialogs/DialogActivitiesNewWarehouse'
import DialogActivitiesNewPlotsGroup from '@dialogs/DialogActivitiesNewPlotsGroup'
import DialogMachineryTask from '@dialogs/DialogMachineryTask'
import DialogCampaign from '@dialogs/DialogCampaign'
import DialogMapAddCampaign from '@dialogs/DialogMapAddCampaign'
import DialogAlert from './DialogAlert'
import DialogCostsNewPrice from './DialogCostsNewPrice'
import DialogCostsWhereApply from './DialogCostsWhereApply'
import DialogMapNewHarvest from './DialogMapNewHarvest'
import DialogMapNewGroup from './DialogMapNewGroup'
import DialogMapNewActivity from './DialogMapNewActivity'

const Dialogs = () => {
	return (
		<>
			<Dialog
				id="dialog-prompt"
				width={600}
				dismissable={false}
				hasCloseButton={false}
			>
				{modal => <DialogPrompt {...modal} />}
			</Dialog>
			<Dialog id="dialog-import-plots-status" width={600}>
				{modal => <DialogPlotsImport {...modal} />}
			</Dialog>
			<Dialog id="dialog-sigpac-search" width={700}>
				{modal => <DialogSigpacSearch {...modal} />}
			</Dialog>
			<Dialog id="dialog-sigpac-plots-search" width={700}>
				{modal => <DialogSigpacPlotsSearch {...modal} />}
			</Dialog>
			<Dialog id="dialog-map-new-plot" width={800} dismissable={false}>
				{modal => <DialogMapNewPlot {...modal} />}
			</Dialog>
			<Dialog id="dialog-activities-new-job" width={600}>
				{modal => <DialogActivitiesNewJob {...modal} />}
			</Dialog>
			<Dialog id="dialog-activities-new-worker" width={600}>
				{modal => <DialogActivitiesNewWorker {...modal} />}
			</Dialog>
			<Dialog id="dialog-activities-new-machine" width={600}>
				{modal => <DialogActivitiesNewMachine {...modal} />}
			</Dialog>
			<Dialog id="dialog-activities-new-seed" width={600}>
				{modal => <DialogActivitiesNewSeed {...modal} />}
			</Dialog>
			<Dialog id="dialog-activities-new-fertilizer" width={600}>
				{modal => <DialogActivitiesNewFertilizer {...modal} />}
			</Dialog>
			<Dialog id="dialog-activities-new-harvest" width={600}>
				{modal => <DialogActivitiesNewHarvest {...modal} />}
			</Dialog>
			<Dialog id="dialog-activities-new-warehouse" width={600}>
				{modal => <DialogActivitiesNewWarehouse {...modal} />}
			</Dialog>
			<Dialog id="dialog-activities-new-plots-group" width={600}>
				{modal => <DialogActivitiesNewPlotsGroup {...modal} />}
			</Dialog>
			<Dialog id="dialog-machinery-task" width={600}>
				{modal => <DialogMachineryTask {...modal} />}
			</Dialog>
			<Dialog id="dialog-campaign" width={600}>
				{modal => <DialogCampaign {...modal} />}
			</Dialog>
			<Dialog id="dialog-map-add-campaign" width={600}>
				{modal => <DialogMapAddCampaign {...modal} />}
			</Dialog>
			<Dialog id="dialog-alert" width={600}>
				{modal => <DialogAlert {...modal} />}
			</Dialog>
			<Dialog id="dialog-costs-add-price" width={800}>
				{modal => <DialogCostsNewPrice {...modal} />}
			</Dialog>
			<Dialog id="dialog-costs-where-apply" width={400}>
				{modal => <DialogCostsWhereApply {...modal} />}
			</Dialog>
			<Dialog id="dialog-map-new-harvest" width={600}>
				{modal => <DialogMapNewHarvest {...modal} />}
			</Dialog>
			<Dialog id="dialog-map-new-group" width={600}>
				{modal => <DialogMapNewGroup {...modal} />}
			</Dialog>
			<Dialog id="dialog-map-new-activity" width={600}>
				{modal => <DialogMapNewActivity {...modal} />}
			</Dialog>
		</>
	)
}

export default Dialogs

import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'

const PrivateRoute = ({
	redirectTo = '/auth/login',
	authenticated,
	status,
	children
}) => {
	const location = useLocation()

	if (!authenticated && status !== 'loading') {
		return <Navigate replace to={redirectTo} state={{ from: location }} />
	}
	return children
}

export default PrivateRoute

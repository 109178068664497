import React, { useCallback, useEffect, useMemo } from 'react'
import styled, { css } from 'styled-components/macro'
import { useQueryClient } from 'react-query'
import { useFormContext } from 'react-hook-form'
import { useSetState } from 'react-use'
import dayjs from 'dayjs'
import ReactHTMLParser from 'html-react-parser'

import yup from '@yup'

import { ReactComponent as ReturnIcon } from '@svgs/arrow-left.svg'

import { useUtils } from '@hooks/utils'
import useNumber from '@hooks/number'
import useSelect from '@hooks/select'

import { Button } from '@components/button'
import { Grid, GridItem } from '@components/grid'
import { Flex } from '@components/flex'
import { Text } from '@components/text'
import { Form, TextField, Textarea, Select } from '@components/form'
import { PreloaderCenter } from '@components/preloaders'

const StyledHistoricContainer = styled.div`
	width: 100%;
	height: 100%;
	position: relative;
`
const StyledHistoric = styled.section`
	width: 100%;
`
const StyledHistoricHeader = styled.header`
	width: 100%;
	margin-bottom: 10px;
	padding-bottom: 10px;
	border-bottom: 1px solid var(--c__grey-300);

	${({ $withButton }) =>
		!!$withButton &&
		css`
			display: flex;
			align-items: center;
			justify-content: space-between;
			gap: 10px;
		`}

	h3 {
		font-weight: 700;
		font-size: 16px;
		color: var(--c__grey-700);
	}

	button {
		padding: 0;
		width: 14px;
		height: 14px;
		color: var(--c__grey-700);

		svg {
			width: 100%;
			height: 100%;
			fill: currentColor;
		}
	}
`
const StyledHistoricBody = styled.div`
	width: 100%;
	height: 400px;
	overflow-y: auto;
	overflow-x: hidden;

	::-webkit-scrollbar {
		width: 8px;
	}

	::-webkit-scrollbar-track {
		background-color: rgba(0, 0, 0, 0.1);
		border-radius: 5px;
	}

	::-webkit-scrollbar-thumb {
		background-color: rgba(0, 0, 0, 0.2);
		border-radius: 5px;

		&:hover {
			background-color: rgba(0, 0, 0, 0.3);
		}
		&:active {
			background-color: rgba(0, 0, 0, 0.4);
		}
	}
`
const StyledHistoricEmpty = styled.aside`
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 15px;

	p {
		font-size: 14px;
		color: var(--c__grey-700);
		text-align: center;
	}
`
const StyledHistoricItem = styled.section`
	display: flex;
	flex-flow: column;
	gap: 20px;
	font-size: 18px;
	color: var(--c__grey-700);
	padding: 15px;
	border-bottom: 1px solid var(--c__grey-300);
	transition: all 150ms ease-out;
	cursor: pointer;
	position: relative;

	&:hover {
		background-color: var(--c__grey-200);
	}

	${({ $active }) =>
		!!$active &&
		css`
			padding-left: 20px;

			&:before {
				content: '';
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				width: 5px;
				background-color: var(--c__green);
			}
		`}

	header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 10px;

		& > div {
			display: flex;
			flex-flow: column;
			gap: 10px;

			p {
				font-size: 1em;
				font-weight: 700;
			}

			time {
				font-size: 0.7em;
			}
		}

		button {
			padding: 0;
			width: 20px;
			height: 20px;
			color: var(--c__grey-700);

			svg {
				width: 100%;
				height: 100%;
				fill: currentColor;
			}
		}
	}

	footer {
	}
`

const schema = yup.object().shape({
	cost: yup.string().required()
})

function HistoricItem({
	id,
	cost,
	date,
	unit,
	comment,
	slug,
	active,
	onEdit = () => {}
}) {
	const { format } = useNumber()
	const { parseNewLines } = useUtils()

	const parsedComment = useMemo(() => {
		return ReactHTMLParser(parseNewLines(comment))
	}, [comment])

	return (
		<StyledHistoricItem
			title="Editar precio"
			$active={active}
			onClick={e => onEdit({ id, cost, date, unit, comment, slug })}
		>
			<header>
				<div>
					<p>{`${format(cost)} ${unit}`}</p>
					<time>{dayjs(date).format('DD/MM/YYYY')}</time>
				</div>
			</header>
			{parsedComment !== '' && (
				<footer>
					<Text>{parsedComment}</Text>
				</footer>
			)}
		</StyledHistoricItem>
	)
}

function HistoricList({ items, onEdit = () => {} }) {
	return (
		<StyledHistoric>
			<StyledHistoricHeader>
				<h3>Histórico</h3>
			</StyledHistoricHeader>
			<StyledHistoricBody>
				{!items.length ? (
					<StyledHistoricEmpty>
						<p>
							No hay precios en el histórico, añade uno en el formulario
							anterior
						</p>
					</StyledHistoricEmpty>
				) : (
					items.map(item => (
						<HistoricItem key={item.id} {...item} onEdit={onEdit} />
					))
				)}
			</StyledHistoricBody>
		</StyledHistoric>
	)
}

function HistoricEditInner({ onDelete = () => {} }) {
	const { getValues } = useFormContext()
	const data = getValues()

	const queryClient = useQueryClient()
	const units = queryClient.getQueryData(['costs', 'units'])

	const parsedUnits = useMemo(() => {
		return units[data.slug].map(unit => ({
			id: unit,
			title: unit
		}))
	}, [data.slug, units])

	const { select: unitsSelect, selectValue: unitsSelectValue } = useSelect({
		name: 'unit',
		collection: parsedUnits,
		value: data?.unit
	})

	return (
		<Grid>
			<GridItem columnStart="span 8">
				<TextField name="cost" label="Precio" value={data?.cost} inverted />
			</GridItem>
			<GridItem columnStart="span 4">
				<Select
					options={unitsSelect}
					name="unit"
					label="Unidad"
					defaultValue={unitsSelectValue}
					placeholder="Unidad"
					inverted
				/>
			</GridItem>
			<GridItem columnStart="span 12">
				<Textarea
					name="comment"
					label="Comentario"
					placeholder="Escribe el comentario"
					defaultValue={data?.comment}
					height={150}
					inverted
				/>
			</GridItem>
			<GridItem columnStart="span 12">
				<Flex alignItems="center" justifyContent="space-between">
					<Button
						label="Eliminar"
						theme="danger"
						onClick={e => onDelete(data)}
					/>
					<Button label={'Actualizar'} type="submit" theme="secondary" />
				</Flex>
			</GridItem>
		</Grid>
	)
}

function HistoricEdit({
	item = null,
	onCancel = () => {},
	onSubmit = () => {},
	onDelete = () => {}
}) {
	const { formatObject } = useNumber()

	const handleError = useCallback(error => {
		console.log(error)
	}, [])

	return (
		<StyledHistoric>
			<StyledHistoricHeader $withButton>
				<h3>{dayjs(item?.date).format('DD/MM/YYYY')}</h3>
				<Button
					icon={<ReturnIcon />}
					title="Volver"
					onClick={e => onCancel()}
				/>
			</StyledHistoricHeader>
			<StyledHistoricBody>
				<Form
					onSubmit={onSubmit}
					onError={handleError}
					loading={false}
					schema={schema}
					hasPrompt={false}
					useFormProps={{
						mode: 'onChange',
						defaultValues: formatObject(item, ['cost'])
					}}
				>
					<HistoricEditInner onDelete={onDelete} />
				</Form>
			</StyledHistoricBody>
		</StyledHistoric>
	)
}

function Historic({
	loading = false,
	id,
	items = [],
	changeModeRef = null,
	onChangeMode = () => {},
	onUpdate = () => {},
	onDelete = () => {}
}) {
	const [state, setState] = useSetState({
		mode: 'list', // list | edit
		item: null
	})

	const handleEditHistoricItem = useCallback(
		item => {
			setState({
				mode: 'edit',
				item
			})
		},
		[setState]
	)

	const handleCancelEditHistoricItem = useCallback(() => {
		setState({
			mode: 'list',
			item: null
		})
	}, [setState])

	useEffect(() => {
		onChangeMode(state.mode)
	}, [state.mode])

	useEffect(() => {
		changeModeRef.current = handleCancelEditHistoricItem
	}, [])

	return (
		<StyledHistoricContainer>
			{!!loading && <PreloaderCenter hover />}
			{state.mode === 'list' ? (
				<HistoricList id={id} items={items} onEdit={handleEditHistoricItem} />
			) : (
				<HistoricEdit
					item={state.item}
					onCancel={handleCancelEditHistoricItem}
					onSubmit={onUpdate}
					onDelete={onDelete}
				/>
			)}
		</StyledHistoricContainer>
	)
}

export default Historic

import { useCallback } from 'react'
import { isDate } from 'lodash'
import dayjs from 'dayjs'

function useUtils() {
	const toSelect = useCallback(
		(collection, keys = { value: 'id', label: 'title' }) => {
			return collection.map(item => {
				let result = {
					value: item[keys.value || 'id'],
					label: item[keys.label || 'title']
				}

				if ('disabled' in item) {
					result = { ...result, disabled: item.disabled }
				} else if ('selected' in item) {
					result = { ...result, selected: item.selected }
				}

				return result
			})
		},
		[]
	)

	const parseNewLines = useCallback(text => {
		if (!text) return ''
		if (text === '') return ''

		const lines = text.split(/\r?\n/)

		return !lines.length
			? ''
			: lines.reduce((result, line) => {
					result += line === '' ? '<br/>' : `<p>${line}</p>`
					return result
			  }, '')
	}, [])

	const toSelectCategory = useCallback(collection => {
		return collection.map(({ id, plots, title }) => ({
			id,
			title,
			category: plots
		}))
	}, [])

	const getSelectValue = useCallback((options, defaultValue, multi = false) => {
		const isArray = Array.isArray(defaultValue) // multiselect
		let opt1
		if (isArray) {
			opt1 = defaultValue.map(value =>
				options.find(
					({ value: optionValue }) =>
						optionValue === value ||
						optionValue?.toString() === value?.toString()
				)
			)
		}

		const opt2 = options.find(
			({ value }) =>
				value === (defaultValue?.id || defaultValue?.value || defaultValue)
		)

		return isArray ? opt1 : opt2 || (multi ? [options[0]] : options[0])
	}, [])

	const collectionToSelect = useCallback(
		(collection, key = 'active') => {
			const select = toSelect(collection)
			const itemActive = collection.find(item => !!item[key])
			const selectActive = getSelectValue(select, itemActive)

			return { select, selectActive }
		},
		[toSelect, getSelectValue]
	)

	const exclude = useCallback((data, excludes) => {
		const out = {}
		Object.keys(data).forEach(key => {
			if (!excludes.includes(key)) {
				out[key] = data[key]
			}
		})

		return out
	}, [])

	const parseObjectDates = useCallback((data, format = 'YYYY-MM-DD') => {
		const out = {}
		Object.keys(data).forEach(key => {
			if (isDate(data[key])) {
				out[key] = dayjs(data[key]).format(format)
			}
		})
		// console.log('parseObjectDates',{ ...data, ...out });
		return { ...data, ...out }
	}, [])

	const parseCurrency = useCallback(value => {
		const number = Number(value)
		return number.toLocaleString('es-ES', {
			style: 'currency',
			currency: 'EUR',
			maximumSignificantDigits: value < 1 ? 4 : number.toString().length + 2
		})
	}, [])

	const joinWithCommas = useCallback((collection, key = 'title') => {
		if (collection.length === 0) return '-'
		if (collection.length === 1) return collection[0][key]
		return collection.reduce((result, item, index) => {
			return (result += `${item[key]}${
				index === collection.length - 1 ? '' : ', '
			}`)
		}, '')
	}, [])

	const parseZero = useCallback(number => {
		return number < 10 ? `0${number}` : number
	}, [])

	const parseHoursMinutes = useCallback(
		({ hours, minutes }) => {
			const hoursToMinutes = !hours || hours === '' ? 0 : Number(hours) * 60
			const parsedMinutes = !hours || hours === '' ? 0 : Number(minutes)
			const totalMinutes = parsedMinutes + hoursToMinutes

			const finalMinutes = totalMinutes % 60
			const finalHours = (totalMinutes - finalMinutes) / 60

			return `${parseZero(finalHours)}h ${parseZero(finalMinutes)}'`
		},
		[parseZero]
	)

	return {
		toSelect,
		toSelectCategory,
		getSelectValue,
		collectionToSelect,
		exclude,
		parseObjectDates,
		parseCurrency,
		joinWithCommas,
		parseHoursMinutes,
		parseNewLines
	}
}

export { useUtils }

import createStore from '@store/createStore'
import { createSelectors } from '@store/utils'

import { MAP_DEFAULTS } from '@constants'

const initialState = {
	id: null,
	sourceId: MAP_DEFAULTS.globalSource,
	position: [],
	lng: MAP_DEFAULTS?.lng,
	lat: MAP_DEFAULTS?.lat,
	zoom: MAP_DEFAULTS?.zoom,
	variant: null,
	painting: false,
	sigpac: [],
	bbox: {
		nelat: null,
		nelng: null,
		swlat: null,
		swlng: null
	}
}
const state = (set, get) => ({
	...initialState,
	reset: () => {
		set(state => {
			for (const key in initialState) {
				state[key] = initialState[key]
			}
		})
	},
	setSigpac: data => {
		set(state => {
			state.sigpac = data
		})
	},
	resetSigpac: () => {
		set(state => {
			state.sigpac = []
		})
	},
	deleteSigpacItem: id => {
		set(state => {
			if (state.sigpac.length) {
				state.sigpac = state.sigpac.filter(({ code }) => code !== id)
			}
		})
	},
	toggleSigpacItemUS: (id, us) => {
		set(state => {
			if (state.sigpac.length) {
				const item = state.sigpac.find(({ code }) => code === id)

				if (item) {
					item.us = us
				}
			}
		})
	}
})

const useMapPlaceStore = createStore({
	state,
	persist: {
		name: 'okcampo-map-place',
		partialize: ({ zoom, lng, lat }) => ({
			zoom,
			lng,
			lat
		})
	},
	log: {
		name: '📌 MapPlaceStore 📌'
	}
})

export default createSelectors(useMapPlaceStore)

import React, { useCallback, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import styled from 'styled-components/macro'

import yup from '@yup'

import { useUtils } from '@hooks/utils'
import { useUser } from '@hooks/user'
import useAlerts from '@hooks/alerts'
import useSelect from '@hooks/select'

import { Form, Textarea, Select } from '@components/form'
import { Grid, GridItem } from '@components/grid'
import { Flex } from '@components/flex'
import { Button } from '@components/button'
import { PreloaderInside } from '@components/preloaders'

const StyledAlert = styled.div`
	width: 100%;
	height: 100%;
`

const DialogAlertInner = ({ users = [], user, canShowUsersSelect = false }) => {
	const { getValues } = useFormContext()
	const { users: usersField } = getValues()

	/* const parsedUsers = useMemo(() => {
		if (!users?.length) return []

		const parsedUsers = users.map(({ id, name }) => ({ id, title: name }))

		if (usersField?.length === users?.length) {
			return parsedUsers
		} else {
			return [{ id: 'all', title: 'Todos los usuarios' }, ...parsedUsers]
		}
	}, [users, usersField]) */

	const parsedUsers = useMemo(() => {
		if (!users?.length) return []

		return users
			.map(({ id, name, surname }) => ({ id, title: `${name} ${surname}` }))
			.filter(({ id }) => id !== user?.id)
	}, [users, usersField])

	const { select: usersSelect, selectValue: usersSelectValue } = useSelect({
		name: 'users',
		collection: parsedUsers,
		value: []
		/* onChange: ({ watched }) => {
			if (!watched) return
			if (watched.includes('all')) {
				const ids = users.map(({ id }) => id)
				reset({ users: ids })
			}
		} */
	})

	return (
		<Grid>
			{canShowUsersSelect && (
				<GridItem columnStart="1" columnEnd="-1">
					<Select
						options={usersSelect}
						name="users"
						label="Usuarios a los que enviar la alerta"
						defaultValue={usersSelectValue}
						noOptionsMessage={() => 'No hay opciones'}
						inverted
						isMulti
					/>
				</GridItem>
			)}
			<GridItem columnStart="1" columnEnd="-1">
				<Textarea
					name="text"
					label="Mensaje"
					placeholder="Escribe el mensaje"
					defaultValue={null}
					inverted
				/>
			</GridItem>
			<GridItem columnStart="1" columnEnd="-1">
				<Flex justifyContent="flex-end" alignItems="center">
					<Button
						label={'Crear alerta'}
						type="submit"
						theme="primary"
						size="large"
					/>
				</Flex>
			</GridItem>
		</Grid>
	)
}

const DialogAlert = ({ closeModal }) => {
	const { exclude } = useUtils()

	const { data: user } = useUser()
	const { useCreate, useFetchUsers } = useAlerts()

	const { data: users, status: statusUsers } = useFetchUsers(user)
	const { mutateAsync: createAlert, statusCreate } = useCreate()

	const handleSubmit = useCallback(
		async data => {
			await createAlert(data)

			closeModal()
		},
		[createAlert]
	)

	const handleError = useCallback(error => {
		console.log(error)
	}, [])

	const canShowUsersSelect = useMemo(
		() => user?.role?.group === 'admin',
		[user]
	)

	const schema = useMemo(() => {
		const shape = canShowUsersSelect
			? {
					text: yup.string().required(),
					users: yup.array().selectMulti()
			  }
			: {
					text: yup.string().required()
			  }
		return yup.object().shape(shape)
	}, [canShowUsersSelect])

	return (
		<StyledAlert>
			<Form
				onSubmit={handleSubmit}
				onError={handleError}
				loading={statusCreate === 'loading'}
				schema={schema}
				hasPrompt={false}
				onFilterState={form => {
					return exclude(form, ['slug'])
				}}
				useFormProps={{
					mode: 'onChange'
				}}
			>
				<PreloaderInside loading={statusUsers === 'loading'} minHeight={300}>
					<DialogAlertInner
						data={null}
						user={user}
						users={users?.children}
						canShowUsersSelect={canShowUsersSelect}
						onCancel={() => closeModal()}
					/>
				</PreloaderInside>
			</Form>
		</StyledAlert>
	)
}

export default DialogAlert

import React, { useMemo } from 'react'
import styled from 'styled-components/macro'

import { ReactComponent as DeleteIcon } from '@svgs/delete.svg'

import { useInfo } from '@hooks/info'
import useNumber from '@hooks/number'
import useSelect from '@hooks/select'

import useMapPlaceStore from '@map/store/place'

import { Select } from '@components/form'
import { Button } from '@components/button'

import { Table } from '@dashboard/table'
import { CellField } from '@dashboard/templates/tables/base'

const StyledDialogMapNewPlotTable = styled.div`
	width: 100%;
`

const StyledDialogMapNewPlotTableSelect = styled.div`
	width: 100%;
`

const StyledDialogMapNewPlotTableDelete = styled.div`
	width: 100%;

	button {
		color: var(--c__grey-700);
		width: 20px;
		height: 20px;
		padding: 0;

		&:hover {
			color: var(--c__grey-700);
			opacity: 0.7;
		}

		svg {
			width: 100% !important;
			height: 100% !important;
			fill: currentColor;
		}
	}
`

function useTableSigpac({ sigpacUses, onDelete = () => {} }) {
	const { format } = useNumber()

	return useMemo(
		() => [
			{
				Header: '',
				accessor: 'delete',
				width: 50,
				canResize: false,
				Cell: data => (
					<StyledDialogMapNewPlotTableDelete>
						<Button
							icon={<DeleteIcon />}
							title="Eliminar recinto Sigpac"
							onClick={() => onDelete(data.row.original?.code)}
						/>
					</StyledDialogMapNewPlotTableDelete>
				)
			},
			{
				Header: 'Código Sigpac',
				accessor: 'code',
				canResize: false,
				Cell: data => <CellField value={data.row.original?.code} />
			},
			{
				Header: 'Total (ha)',
				accessor: 'ha_total',
				canResize: false,
				Cell: data => (
					<CellField
						value={`${format(data.row.original?.ha_total)} ha`}
						// value={`${format(data.row.original?.ha_total?.toFixed(3))} ha`}
					/>
				)
			},
			/* {
				Header: 'Usada (ha)',
				accessor: 'ha_used',
				canResize: false,
				Cell: data => <CellField value={data.row.original.ha_used.toString()} />
			}, */
			{
				Header: 'Uso',
				accessor: 'us',
				canResize: false,
				Cell: data => (
					<TableSigpacSelect
						id={data.row.original?.code}
						value={data.row.original.us}
						sigpacUses={sigpacUses}
					/>
				)
			}
		],
		[]
	)
}

function useTableSigpacBasic({ sigpacUses }) {
	const { format } = useNumber()

	return useMemo(
		() => [
			{
				Header: 'Código Sigpac',
				accessor: 'code',
				canResize: false,
				Cell: data => <CellField value={data.row.original?.code} />
			},
			{
				Header: 'Total (ha)',
				accessor: 'ha_total',
				canResize: false,
				Cell: data => (
					<CellField
						value={`${format(data.row.original?.ha_total)} ha`}
						// value={`${format(data.row.original?.ha_total?.toFixed(3))} ha`}
					/>
				)
			},
			{
				Header: 'Uso',
				accessor: 'us',
				canResize: false,
				Cell: data => <CellField value={sigpacUses[data.row.original?.us]} />
			}
		],
		[]
	)
}

function TableSigpacSelect({ id, value, sigpacUses }) {
	const toggleSigpacItemUS = useMapPlaceStore.use.toggleSigpacItemUS()

	const parsedUses = useMemo(() => {
		return Object.keys(sigpacUses).map(key => ({
			id: key,
			title: `${key} - ${sigpacUses[key]}`
		}))
	}, [sigpacUses])

	const { select: sigpacUsSelect, selectValue: sigpacUsSelectValue } =
		useSelect({
			name: `sigpac_us_${id}`,
			collection: parsedUses,
			value,
			onChange: ({ watched }) => {
				toggleSigpacItemUS(id, watched)
			}
		})

	return (
		<StyledDialogMapNewPlotTableSelect>
			<Select
				key={`sigpac_us_${id}`}
				options={sigpacUsSelect}
				name={`sigpac_us_${id}`}
				placeholder="Selecciona uso"
				defaultValue={sigpacUsSelectValue}
				value={sigpacUsSelectValue}
				size="small"
			/>
		</StyledDialogMapNewPlotTableSelect>
	)
}

export default function MapSigpac({ data }) {
	const deleteSigpacItem = useMapPlaceStore.use.deleteSigpacItem()

	const { data: info } = useInfo()
	const { sigpacUses } = info

	const sigpacTableColumns = useTableSigpac({
		sigpacUses,
		onDelete: deleteSigpacItem
	})

	return (
		<StyledDialogMapNewPlotTable>
			<Table
				columns={sigpacTableColumns}
				data={data}
				renderStatus={false}
				renderFilters={false}
				paginationPageSize={data?.length}
				height={300}
			/>
		</StyledDialogMapNewPlotTable>
	)
}

export function MapSigpacBasic({ data }) {
	const { data: info } = useInfo()
	const { sigpacUses } = info

	const sigpacTableColumns = useTableSigpacBasic({
		sigpacUses
	})

	return (
		<StyledDialogMapNewPlotTable>
			<Table
				columns={sigpacTableColumns}
				data={data}
				renderStatus={false}
				renderFilters={false}
				paginationPageSize={data?.length}
				height={300}
			/>
		</StyledDialogMapNewPlotTable>
	)
}

import React, { useCallback } from 'react'
import isEmpty from 'lodash/isEmpty'
import flattenDeep from 'lodash/flattenDeep'
import { useQuery } from 'react-query'

import { FETCH_MENU } from '@constants/endpoints'

import useAjax from '@hooks/ajax'
import useStore from '@store/store'

function useMenuQuery(config = {}) {
	const { get } = useAjax()
	const { set } = useStore()

	const fetchUser = useCallback(() => get(FETCH_MENU), [get])

	return useQuery('menu', fetchUser, {
		staleTime: Infinity,
		onSuccess: data => {
			set(state => {
				state.menu = data.map(item => ({ ...item, active: false }))
			})
		},
		...config
	})
}

function useMenu() {
	const { status } = useMenuQuery()
	const menu = useStore(({ menu }) => menu)
	const set = useStore(({ set }) => set)

	const toggleMenu = useCallback(
		id => {
			set(state => {
				state.menu = state.menu.map(item => {
					let active = !id ? false : item.id === id && !item.active
					return { ...item, active }
				})
			})
		},
		[set]
	)

	const getMenuItemByPath = useCallback(
		path => {
			const parent = path.split('/').filter(o => !isEmpty(o))[0]
			return menu.filter(item => item.slug === parent)
		},
		[menu]
	)

	const getFlattenMenu = useCallback(() => {
		return [
			...menu,
			...menu.reduce((result, item) => result.concat(item.children), [])
		]
	}, [menu])

	return {
		status,
		menu,
		toggleMenu,
		getMenuItemByPath,
		getFlattenMenu
	}
}

export default useMenu

import React, { useState, useCallback, useMemo } from 'react'
import styled from 'styled-components/macro'

import yup from '@yup'

import useActivities from '@hooks/activities'
import useSelect from '@hooks/select'

import { Form, TextField, Select } from '@components/form'
import { Button } from '@components/button'
import { Grid, GridItem } from '@components/grid'
import { Flex } from '@components/flex'
import SelectHoldings from '@components/ui/SelectHoldings'

const StyledActivitiesPanelNewJob = styled.div`
	width: 100%;
	height: 100%;
`

const schema = yup.object().shape({
	title: yup.string().required(),
	job_type: yup.string().required('Elige una opción').nullable()
})

const ActivitiesPanelNewJobInner = ({ onCancel = () => {} }) => {
	const { getActiveActivityChildren } = useActivities()
	const activityChildren = getActiveActivityChildren()
	const activityChildrenFiltered = useMemo(
		() => activityChildren.filter(({ default_value }) => !!default_value),
		[activityChildren]
	)
	const { select: typesSelect, selectValue: typesSelectValue } = useSelect({
		name: 'job_type',
		collection: activityChildrenFiltered,
		value: null
	})

	return (
		<Grid>
			<GridItem columnStart="1" columnEnd="-1">
				<TextField
					name="title"
					label="Nombre *"
					placeholder="Escribe el nombre"
					value=""
					inverted
				/>
			</GridItem>
			<GridItem columnStart="1" columnEnd="-1">
				<Select
					options={typesSelect}
					name="job_type"
					label="Tipo *"
					placeholder="Selecciona un tipo"
					defaultValue={typesSelectValue}
					inverted
				/>
			</GridItem>
			<GridItem columnStart="1" columnEnd="-1">
				<SelectHoldings name="holding_id" label="Explotación *" inverted />
			</GridItem>
			<GridItem columnStart="1" columnEnd="-1">
				<Flex justifyContent="flex-end" alignItems="center">
					<Button
						label={'Guardar'}
						type="submit"
						theme="primary"
						size="large"
					/>
				</Flex>
			</GridItem>
		</Grid>
	)
}

const DialogActivitiesNewJob = ({ item, closeModal }) => {
	const [loading, setLoading] = useState(false)
	const { getActiveActivityChildren, useActivitiesCreateChild } =
		useActivities()
	const activityChildren = getActiveActivityChildren()

	const { mutateAsync } = useActivitiesCreateChild(item.id)

	const handleSubmit = async data => {
		if (data?.job_type) {
			setLoading(true)

			const currentItem = activityChildren.find(
				item => item.id === data.job_type
			)

			await mutateAsync({
				title: data.title,
				holding_id: data.holding_id,
				type: currentItem?.type
			})

			setLoading(false)
			closeModal()
		}
	}

	const handleError = useCallback(error => {
		console.log(error)
	}, [])

	return (
		<StyledActivitiesPanelNewJob>
			<Form
				onSubmit={handleSubmit}
				onError={handleError}
				loading={loading}
				schema={schema}
				hasPrompt={false}
			>
				<ActivitiesPanelNewJobInner />
			</Form>
		</StyledActivitiesPanelNewJob>
	)
}

export default DialogActivitiesNewJob

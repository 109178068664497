import React, { useCallback } from 'react'
import styled, { css } from 'styled-components/macro'
import { useForm, FormProvider } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import pipe from 'ramda/es/pipe'
import { isArray } from 'lodash'

import { NUMBER_PARSE_EXCEPTIONS, MULTISELECT_ALL_KEY } from '@constants'

import yup from '@yup'

import { useUtils } from '@hooks/utils'
import useNumber from '@hooks/number'

import { Spinner } from '@components/preloaders'

const StyledForm = styled.form`
	width: 100%;
	position: relative;
	transition: opacity 150ms ease-out;

	${({ $loading, $disabled }) =>
		(!!$loading || !!$disabled) &&
		css`
			opacity: 0.4;
			cursor: not-allowed;
			pointer-events: none;
		`}

	.preloader-icon {
		position: absolute;
		top: 50%;
		left: 50%;
		z-index: 10;
		transform: translate(-50%, -50%);
	}
`

function FormInner({
	children,
	loading = false,
	disabled = false,
	schema = yup.object().shape({}),
	useFormProps = {},
	onSubmit = () => {},
	onError = () => {},
	...restProps
}) {
	const { onFilterState, ...restPropsFiltered } = restProps
	const methods = useForm({
		resolver: yupResolver(schema),
		...useFormProps
	})
	const { exclude, parseObjectDates } = useUtils()
	const { parseNumber } = useNumber()

	const { handleSubmit, ...restMethods } = methods

	const filterFormState = useCallback(
		form => {
			const excludes = ['city', 'province', 'state']
			return exclude(form, excludes)
		},
		[exclude]
	)

	const filterParseDates = useCallback(
		form => {
			if (!Object.keys(form).length) return form

			return parseObjectDates(form)
		},
		[parseObjectDates]
	)

	const filterNumbers = useCallback(
		form => {
			if (!Object.keys(form).length) return form

			let out = {}

			Object.keys(form).forEach(key => {
				const value = form[key]

				out = {
					...out,
					[key]: NUMBER_PARSE_EXCEPTIONS.includes(key)
						? value
						: key.endsWith('_id')
						? value
						: parseNumber(value)
				}
			})

			return out
		},
		[parseNumber]
	)

	const filterSelectAllMultiselect = useCallback(
		form => {
			if (!Object.keys(form).length) return form

			return Object.keys(form).reduce((result, key) => {
				const value = form[key]

				if (isArray(value) && value?.includes(MULTISELECT_ALL_KEY)) {
					return {
						...result,
						[key]: value.filter(val => val !== MULTISELECT_ALL_KEY)
					}
				} else {
					return result
				}
			}, form)
		},
		[parseNumber]
	)

	const handleOnFilterState = useCallback(
		form => {
			return onFilterState ? restProps.onFilterState(form) : form
		},
		[onFilterState, restProps]
	)

	return (
		<FormProvider {...methods}>
			<StyledForm
				className="form"
				$loading={loading}
				$disabled={disabled}
				onSubmit={handleSubmit(
					pipe(
						filterFormState,
						handleOnFilterState,
						filterParseDates,
						filterNumbers,
						filterSelectAllMultiselect,
						onSubmit
					),
					onError
				)}
				{...restMethods}
				{...restPropsFiltered}
			>
				{loading && (
					<Spinner size={28} strokeWidth={10} strokeColor="#969696" />
				)}
				{children}
			</StyledForm>
		</FormProvider>
	)
}

function Form({ children, hasPrompt = true, ...restProps }) {
	return (
		<FormInner {...restProps}>
			{/*! !hasPrompt && <PromptForm /> */}
			{children}
		</FormInner>
	)
}

export default Form

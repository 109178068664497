import dayjs from 'dayjs'
import { ViewMode } from 'gantt-task-react'

import userFieldsBg from '@images/map/types/user-fields.png'

const initialState = {
	menu: [],
	breadcrumb: [],
	formsTabs: {},
	promptStatus: 'active', // active | disabled
	currentTable: {},
	alerts: {
		opened: false
	},
	// costs results report
	report: {
		filters: {
			opened: false,
			state: {
				category: 'total', // tab activo. Por defecto: "total"
				plots: [],
				groups: [],
				harvests: [],
				campaigns: [],
				jobs: []
			}
		}
	},
	campaigns: {
		plots: [],
		collection: [],
		ganttById: {},
		ganttOrder: [],
		view: ViewMode.Year
	},
	activities: {
		collection: [],
		defaultsById: [],
		plotsById: [],
		plagues: [],
		campaigns: [],
		models: {},
		selects: {},
		phytosanitaries: {},
		canRequestEdit: false,
		formState: {
			date: dayjs().format('YYYY-MM-DD')
		},
		ajaxParams: {
			jobs_selected: []
		}
	},
	map: {
		campaigns: [],
		filters: [],
		plots: [],
		enclosures: [],
		styles: {
			layers: [
				{
					id: 'mis-campos',
					image: userFieldsBg,
					title: 'Mis parcelas',
					active: false
				},
				{
					id: 'recintos-sigpac',
					image: userFieldsBg,
					title: 'Recintos SIGPAC',
					active: false
				}
			],
			types: [
				{
					id: 'mapa',
					image: userFieldsBg,
					title: 'Mapa',
					path: 'seresinertes/ckfwckzjk058d19ny693f2z2e',
					active: true
				},
				{
					id: 'satelite',
					image: userFieldsBg,
					title: 'Satélite',
					path: 'seresinertes/cke9nzyw25jfi1arq7ma5k35s',
					active: false
				}
				/* {
					id: nanoid(),
					image: userFieldsBg,
					title: 'Satélite (Mapbox)',
					active: false
				},
				{
					id: nanoid(),
					image: userFieldsBg,
					title: 'Satélite (SIGPAC)',
					active: false
				} */
			]
		}
	}
}

export default initialState

import React, { useState, useEffect } from 'react'
import styled from 'styled-components/macro'

import yup from '@yup'

import SVG from '@components/SVG'
import { Text } from '@components/text'
import { Button } from '@components/button'
import { Form, TextField } from '../components/form'
import useMap from '@hooks/map'
import useMapbox from '@hooks/mapbox'

const schema = yup.object().shape({
	PRO: yup.string().required().integer(),
	MUN: yup.string().required().integer(),
	ZON: yup.string().required().integer(),
	AGR: yup.string().required().integer(),
	POL: yup.string().required().integer(),
	PAR: yup.string().required().integer(),
	REC: yup.string().required().integer()
})

const StyledDialogSigpacSearch = styled.div`
	width: 100%;
	height: 100%;
`

const StyledFilterListContent = styled.ul`
	width: 100%;
	border-radius: var(--border__radius--small);
	background-color: var(--c__grey-200);
	padding: 10px 0;
	margin-top: 15px;
	height: 300px;
	overflow-y: auto;
`
const StyledFilterListContentItem = styled.li`
	padding: 10px 20px;
	font-size: 15px;
	color: var(--c__grey-700);
	background-color: var(--c__grey-200);
	border-bottom: 1px solid var(--c__grey-200);
	transition: background-color 150ms ease-out, color 150ms ease-out,
		opacity 150ms ease-out;
	cursor: pointer;

	&:hover:not(.selected) {
		background-color: var(--c__grey-300);
	}

	&.selected {
		background-color: var(--c__green);
		color: var(--c__white);

		&:hover {
			opacity: 0.8;
		}

		span {
			color: var(--c__white) !important;
		}
	}

	span {
		font-weight: 800;
		color: var(--c__green);
	}
`

const StyledSigpacInputs = styled.div`
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
	gap: 10px;

	& > div {
		min-width: 80px;
	}
`

const StyledSigpacInputsActions = styled.div`
	padding-top: 20px;
	margin-top: 20px;
	border-top: 1px solid var(--c__grey-300);
	display: flex;
	align-items: center;
	justify-content: flex-end;
`
const DialogSearchSigpacSecondStep = ({
	loading = false,
	onSubmit = () => {},
	onError = () => {},
	onBack = () => {}
}) => {
	return (
		<>
			<Text>
				<p>Introduce el código SIGPAC para buscar un recinto</p>
			</Text>
			<Form
				onSubmit={onSubmit}
				onError={onError}
				loading={loading}
				schema={schema}
				hasPrompt={false}
			>
				<StyledSigpacInputs>
					<TextField
						type="number"
						name="PRO"
						placeholder="PRO"
						inverted
						showError={false}
						defaultValue={21}
					/>
					<TextField
						type="number"
						name="MUN"
						placeholder="MUN"
						inverted
						showError={false}
						defaultValue={37}
					/>
					<TextField
						type="number"
						name="AGR"
						placeholder="AGR"
						inverted
						showError={false}
						defaultValue={0}
					/>
					<TextField
						type="number"
						name="ZON"
						placeholder="ZON"
						inverted
						showError={false}
						defaultValue={0}
					/>
					<TextField
						type="number"
						name="POL"
						placeholder="POL"
						inverted
						showError={false}
						defaultValue={3}
					/>
					<TextField
						type="number"
						name="PAR"
						placeholder="PAR"
						inverted
						showError={false}
						defaultValue={7}
					/>
					<TextField
						type="number"
						name="REC"
						placeholder="REC"
						inverted
						showError={false}
						defaultValue={2}
					/>
				</StyledSigpacInputs>
				<StyledSigpacInputsActions>
					<Button
						type="submit"
						label="Buscar"
						theme="primary"
						size="large"
						icon={<SVG name={'loupe'} width={22} height={22} />}
					/>
				</StyledSigpacInputsActions>
			</Form>
		</>
	)
}

const DialogSigpacPlotsSearch = ({
	provinces,
	mapObject,
	drawObject,
	closeModal,
	setDismissable
}) => {
	const { useMapSigpacSearch, useMapTileset } = useMap()
	const { drawEnclosure, addTileset, addSigpacFeature } = useMapbox()
	const [loading, setLoading] = useState(false)
	const [province, setProvince] = useState(null)
	const [sigpacCode, setSigpacCode] = useState({})
	const [provinceId, setProvinceId] = useState(null)

	const { data: enclosure } = useMapSigpacSearch(sigpacCode)

	const handleClickListItem = province => {
		setProvince(province)
	}

	const handleSubmit = data => {
		setLoading(true)
		setDismissable(false)
		setSigpacCode(data)
		// setProvinceId(province.id)
	}

	useEffect(() => {
		if (enclosure) {
			setLoading(false)
			setDismissable(true)

			console.log(enclosure)

			const layer = {
				type: 'fill',
				layout: {},
				paint: {
					'fill-color': `#16313b`, // blue color fill
					'fill-opacity': 0.5
				}
			}
			/* drawEnclosure(
				mapObject,
				enclosure.geometry,
				`source-${enclosure.code}`,
				`layer-${enclosure.code}`,
				layer
			) */

			addSigpacFeature(mapObject, drawObject, enclosure)

			closeModal()
		}
	}, [enclosure])

	return (
		<StyledDialogSigpacSearch>
			<DialogSearchSigpacSecondStep
				province={province}
				loading={loading}
				onBack={() => setProvince(null)}
				onSubmit={handleSubmit}
			/>
		</StyledDialogSigpacSearch>
	)
}

export default DialogSigpacPlotsSearch

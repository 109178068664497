import React, { useState, useCallback, useMemo, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { range, isString, isEmpty, isArray } from 'lodash'
import cx from 'classnames'

import { cssVar } from 'polished'
import { matchSorter } from 'match-sorter'
import {
	useTable,
	useResizeColumns,
	useFlexLayout,
	useSortBy,
	useFilters,
	useGlobalFilter,
	usePagination,
	useRowState,
	useExpanded
} from 'react-table'

import makeExpandedData from './makeData'

import { ReactComponent as SortIcon } from '@svgs/arrow-down.svg'

import Tooltip from '@components/Tooltip'
import { TablePagination } from '@dashboard/table'

const StyledTableContainer = styled.div`
	display: block;
`
const StyledTable = styled.figure`
	border-spacing: 0;
	width: 100%;
	font-size: 12px;
	color: var(--c__grey-700);
	transition: opacity 150ms ease-out;

	@media (min-width: 568px) {
		font-size: 13px;
	}
	@media (min-width: 768px) {
		font-size: 14px;
	}

	${({ $loading }) =>
		$loading &&
		css`
			opacity: 0.4;
			pointer-events: none;
		`}

	.table__inner {
		display: block;
		max-width: 100%;
		overflow-x: auto;
		min-height: 200px;

		@media (min-width: 768px) {
			min-height: 300px;
		}

		::-webkit-scrollbar {
			height: 8px;
			margin: 5px;
		}

		::-webkit-scrollbar-track {
			background-color: rgba(0, 0, 0, 0.1);
			border-radius: 5px;
		}

		::-webkit-scrollbar-thumb {
			background-color: rgba(0, 0, 0, 0.2);
			border-radius: 5px;

			&:hover {
				background-color: rgba(0, 0, 0, 0.3);
			}
			&:active {
				background-color: rgba(0, 0, 0, 0.4);
			}
		}
	}

	.thead {
		/*overflow-y: auto;
		overflow-x: hidden;*/
		font-size: 1em;
	}

	.tbody {
		font-size: 1em;
		width: 100%;

		::-webkit-scrollbar {
			width: 8px;
			margin: 5px;
		}

		::-webkit-scrollbar-track {
			background-color: rgba(0, 0, 0, 0.1);
			border-radius: 5px;
		}

		::-webkit-scrollbar-thumb {
			background-color: rgba(0, 0, 0, 0.2);
			border-radius: 5px;

			&:hover {
				background-color: rgba(0, 0, 0, 0.3);
			}
			&:active {
				background-color: rgba(0, 0, 0, 0.4);
			}
		}
	}

	.tr {
		font-size: 1em;
		width: 100%;

		&:last-child {
			.td {
				border-bottom: 0;
			}
		}

		&:hover {
			.td {
				background-color: var(--c__grey-200) !important;

				${({ $theme }) =>
					$theme === 'light' &&
					css`
						background-color: var(--c__grey-100) !important;
					`}
			}
		}
	}

	.th {
		font-weight: 700;
		position: relative;
		display: flex;
		flex-flow: row wrap;
		align-items: center;
		justify-content: space-between;
		gap: 10px;
		transition: opacity 150ms ease-out;
		cursor: default;
		font-size: 1em;
		border-bottom: 1px solid var(--c__grey);

		${({ $theme }) =>
			$theme === 'light' &&
			css`
				background-color: var(--c__white);
				border-color: var(--c__grey-200);
			`}

		&--can-sort {
			cursor: pointer;
		}
		&--sorting {
			color: var(--c__green);
		}

		&__trigger {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			z-index: 1;
		}

		.arrow-sorting {
			width: 14px;
			height: 14px;
			color: var(--c__green);
			display: block;
			align-self: center;

			&--active {
				svg {
					transform: rotate(180deg);
				}
			}

			svg {
				width: 100%;
				height: 100%;
				fill: currentColor;
				margin-top: -2px;
				transition: transform 150ms ease-out;
			}
		}

		.th__title {
			color: currentColor;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
		}

		.resizer {
			right: 0;
			background: var(--c__grey);
			width: 4px;
			height: 100%;
			position: absolute;
			top: 0;
			z-index: 1;
			touch-action: none;

			&:hover {
				background: var(--c__grey-400);
			}

			&.isResizing {
				background: var(--c__green);
			}
		}
	}

	.td {
		background-color: var(--c__grey-100);
		transition: background 150ms ease-out;
		display: flex;
		align-items: center;
		font-size: 1em;
		border-bottom: 1px solid var(--c__grey);

		${({ $theme }) =>
			$theme === 'light' &&
			css`
				background-color: var(--c__white);
				border-color: var(--c__grey-200);
			`}

		span {
			color: currentColor;
			font-size: 1em;
		}

		a {
			color: var(--c__grey-700);
			font-weight: 700;
			transition: all 150ms ease-out;
			text-decoration: underline;

			&:hover {
				color: var(--c__green-600);
				text-decoration: underline;
			}
		}

		sub,
		sup {
			margin-left: 0.3em;
		}
	}

	.th,
	.td {
		margin: 0;
		padding: 12px;
		position: relative;
		word-break: break-word;
		align-items: end;

		@media (min-width: 768px) {
			padding: 15px;
		}

		:last-child {
			border-right: 0;
		}
	}
	@media (max-width: ${cssVar('--mq__xl', '0')}) {
		.th,
		.td {
			flex: 100 0 auto !important;
			width: 100px !important;
			span {
				font-size: 0.85em;
			}
			a {
				font-size: 0.85em;
			}
		}
	}
`
const StyledFilters = styled.header`
	width: 100%;
	margin-bottom: 40px;
`
const StyledFiltersPlot = styled.header`
	width: 100%;
	position: relative;
	z-index: 2;

	input {
		height: 30px;
	}
`
const StyledTableStatus = styled.div`
	margin-bottom: 15px;

	@media (min-width: 768px) {
		margin-bottom: 30px;
	}

	p {
		font-size: 12px;
		color: var(--c__grey-500);

		@media (min-width: 768px) {
			font-size: 14px;
		}

		strong {
			font-weight: 700;
		}
	}
	@media (max-width: ${cssVar('--mq__xl', '0')}) {
		p {
			font-size: 12px;
		}
	}
`
const StyledTableEmpty = styled.aside`
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 30px;

	p {
		font-size: 16px;
		font-weight: 700;
		color: var(--c__grey-700);
		text-align: center;
	}
`

const StyledTableEmptyData = styled.aside`
	width: 100%;
	height: 100%;
	display: flex;
	flex-flow: column;
	align-items: center;
	justify-content: center;
	gap: 20px;
	padding: 30px;
	min-height: 150px;
	border-radius: 10px;
	background-color: var(--c__white);
	box-shadow: var(--dashboard__shadow);

	p {
		font-size: 16px;
		font-weight: 700;
		color: var(--c__grey-700);
		text-align: center;
	}
`

// const headerProps = (props, { column }) => getStyles(props, column.align)

// const cellProps = (props, { cell }) => getStyles(props, cell.column.align)

const getStyles = (props, align = 'left') => [
	props,
	{
		style: {
			justifyContent:
				align === 'right'
					? 'flex-end'
					: align === 'center'
					? 'center'
					: 'flex-start',
			alignItems: 'flex-start',
			display: 'flex'
		}
	}
]

const getAlign = (align = 'left') => ({
	justifyContent:
		align === 'right'
			? 'flex-end'
			: align === 'center'
			? 'center'
			: 'flex-start',
	alignItems: 'flex-start',
	display: 'flex'
})

function searchFilterFn(rows, id, filterValue) {
	return matchSorter(rows, filterValue, { keys: [row => row.values[id]] })
}
searchFilterFn.autoRemove = val => !val

// --- This could be inlined into SubRowAsync, this this lets you reuse it across tables ---
function SubRows({
	row,
	rowProps,
	visibleColumns,
	data,
	loading,
	onTDClick = () => {}
}) {
	if (loading) {
		return <div key={row.id}>Loading...</div>
	}

	// error handling here :)

	return (
		<>
			{data.map((x, i) => {
				return (
					<div
						className="tr"
						{...rowProps}
						key={`${rowProps.key}-expanded-${i}`}
						style={{
							display: 'flex',
							flex: '1 0 auto',
							minWidth: '0px',
							border: '1px solid silver',
							marginLeft: 10
						}}
					>
						{row.cells.map(cell => {
							return (
								<div
									key={`cell_${cell?.id}`}
									className="td"
									onClick={() => onTDClick(cell)}
								>
									{cell.render('Cell')}
								</div>
							)
						})}
					</div>
				)
			})}
		</>
	)
}

function SubRowAsync({ row, rowProps, visibleColumns, onTDClick = () => {} }) {
	const [loading, setLoading] = useState(true)
	const [data, setData] = useState([])

	useEffect(() => {
		const timer = setTimeout(() => {
			setData(makeExpandedData(3))
			setLoading(false)
		}, 500)

		return () => {
			clearTimeout(timer)
		}
	}, [])

	return (
		<SubRows
			key={row.id}
			row={row}
			rowProps={rowProps}
			visibleColumns={visibleColumns}
			data={data}
			loading={loading}
			onTDClick={onTDClick}
		/>
	)
}

// -----------------------------------------------------------------------------------------

function TableInner({
	columns = [],
	data = [],
	defaultColumn = [],
	rowProps = () => {},
	headerStyles = () => {},
	cellStyles = () => {},
	onTRClick = () => {},
	onTDClick = () => {},
	renderHeader = true,
	renderStatus = true,
	renderFilters = null,
	initialPage = 0,
	theme = 'default',
	loading = false,
	paginationPageSize = 20,
	height = null,
	urlBaseExpndedRow = false
}) {
	const [initialRows, setInitialRows] = useState(data)

	const finalHeaderProps = useCallback(
		(props, { column }) => {
			return [
				props,
				{
					style: {
						...getAlign(column.align),
						...(headerStyles ? headerStyles(column) : {})
					}
				}
			]
		},
		[headerStyles]
	)

	const finalCellProps = useCallback(
		(props, { cell }) => {
			return [
				props,
				{
					style: {
						...getAlign(cell.column.align),
						...(cellStyles ? cellStyles(cell) : {})
					}
				}
			]
		},
		[cellStyles]
	)

	const searchMatch = useCallback((a, b) => {
		if (!a) return false
		if (!b) return false
		return a.toString().toLowerCase().includes(b.toLowerCase())
	}, [])

	const rowsFilteredByColumnSearch = useCallback(
		(rows, id, filterValue) => {
			return filterValue.length === 0
				? rows
				: rows.filter(row => {
						const rowValue = row.original[id]

						if (isArray(rowValue)) {
							const titles = rowValue.map(({ title }) => title)

							return titles.some(title => {
								return searchMatch(title, filterValue)
							})
						} else {
							if (id[0] === 'area' && !!row.original?.plots?.length) {
								const sum = row.original.plots.reduce((result, plot) => {
									return (result += Number(plot?.area))
								}, 0)
								return searchMatch(sum.toFixed(2), filterValue)
							} else if (id[0] === 'time') {
								return searchMatch(
									`${rowValue?.hours}:${rowValue?.minutes}`,
									filterValue
								)
							} else {
								return searchMatch(rowValue, filterValue)
							}
						}
				  })
		},
		[searchMatch]
	)

	const filterTypes = useMemo(
		() => ({
			search: rowsFilteredByColumnSearch
		}),
		[rowsFilteredByColumnSearch]
	)

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		setFilter,
		rows,
		prepareRow,
		state,
		setGlobalFilter,
		pagination,
		page,
		pageOptions,
		gotoPage,
		setPageSize,
		preGlobalFilteredRows,
		state: { expanded, pageIndex, pageSize, globalFilter },
		visibleColumns
	} = useTable(
		{
			columns,
			data,
			defaultColumn,
			filterTypes,
			defaultCanSort: false,
			initialState: {
				pageSize: paginationPageSize,
				pageIndex: initialPage,
				rowState: {
					loading
				}
			}
		},
		useFilters,
		useGlobalFilter,
		useSortBy,
		useExpanded,
		useRowState,
		usePagination,
		useResizeColumns,
		useFlexLayout
	)

	const getShowingRange = useCallback(total => {
		const min = 10
		const max = 51
		const step = 10
		return range(Math.min(min, total), Math.min(max, total), step)
	}, [])

	const handleChangePagination = pageNumber => {
		gotoPage(pageNumber)
	}

	const renderShowingRange = useCallback(
		total => {
			const renderRange = () => {
				const showingRange = getShowingRange(total)
				if (!showingRange.length) {
					return null
				} else {
					return (
						<select
							value={pageSize}
							onChange={e => {
								setPageSize(Number(e.target.value))
							}}
						>
							{showingRange.map(pageSize => (
								<option key={pageSize} value={pageSize}>
									{pageSize}
								</option>
							))}
							<option value={initialRows.length}>{initialRows.length}</option>
						</select>
					)
				}
			}

			const rangeContent = renderRange()
			const totalRows =
				!!globalFilter && globalFilter !== '' ? rows.length : initialRows.length

			return (
				<p>
					Viendo {rangeContent || null}
					{rangeContent ? ' / ' : null}
					<strong>{totalRows}</strong> {totalRows <= 1 ? 'fila' : 'filas'}
				</p>
			)
		},
		[getShowingRange, initialRows, rows, pageSize, setPageSize]
	)

	// Create a function that will render our row sub components
	const renderRowSubComponent = React.useCallback(
		({ row, rowProps, visibleColumns, urlBaseExpndedRow }) => {
			/* return (
        <TableExpandedRow
          urlBaseExpndedRow={urlBaseExpndedRow}
          baseKeySend={["costs/results"]}
        />
      ); */
			console.log('URL', urlBaseExpndedRow + '/' + row.id)

			// console.log('ROW ===>',rowData);
			return (
				<SubRowAsync
					row={row}
					rowProps={rowProps}
					visibleColumns={visibleColumns}
					onTDClick={onTDClick}
				/>
			)
		},
		[]
	)

	useEffect(() => {
		setInitialRows(data)
	}, [data])

	return (
		<StyledTableContainer>
			{renderFilters && (
				<StyledFilters>
					{renderFilters({ state, setGlobalFilter, setFilter })}
				</StyledFilters>
			)}
			<>
				{renderStatus && (
					<StyledTableStatus>
						{renderShowingRange(initialRows.length)}
					</StyledTableStatus>
				)}
				<StyledTable
					{...getTableProps()}
					className="table"
					$theme={theme}
					$loading={loading}
				>
					<div className="table__inner">
						{renderHeader && (
							<div className="thead">
								{headerGroups.map(headerGroup => {
									const { key: headerGroupKey, ...restHeaderGroupProps } =
										headerGroup.getHeaderGroupProps()
									return (
										<div
											key={headerGroupKey}
											{...restHeaderGroupProps}
											className="tr"
										>
											{headerGroup.headers.map(column => {
												const { key: columnKey, ...restColumnProps } =
													column.getHeaderProps(finalHeaderProps)
												const title = isString(column?.Header)
													? column?.Header
													: ''

												return (
													<div
														key={columnKey}
														{...restColumnProps}
														className={cx({
															th: true,
															'th--can-sort': column.canSort,
															'th--sorting': column.isSorted
														})}
													>
														<Tooltip position="bottom" text={title}>
															<span
																className="th__trigger"
																{...column.getSortByToggleProps()}
																title=""
															></span>
														</Tooltip>
														<span className="th__title">
															{column.render('Header')}
														</span>
														{column.isSorted && column.canSort && (
															<span
																className={cx({
																	'arrow-sorting': true,
																	'arrow-sorting--active': column.isSortedDesc
																})}
															>
																<SortIcon />
															</span>
														)}
														{!isEmpty(defaultColumn) && (
															<StyledFiltersPlot>
																{column.canFilter ? (
																	<span>{column.render('Filter')}</span>
																) : null}
															</StyledFiltersPlot>
														)}
														{column.canResize && (
															<div
																{...column.getResizerProps()}
																className={`resizer ${
																	column.isResizing ? 'isResizing' : ''
																}`}
															/>
														)}
													</div>
												)
											})}
										</div>
									)
								})}
							</div>
						)}
						<div
							className="tbody"
							{...getTableBodyProps()}
							style={{
								overflowY: height ? 'auto' : 'visible',
								overflowX: height ? 'hidden' : 'visible',
								height: height || 'auto',
								paddingBottom: height ? 30 : 0
							}}
						>
							{page.length ? (
								page.map(row => {
									prepareRow(row)
									return (
										<div key={row.id}>
											<div
												key={row.id}
												{...row.getRowProps(rowProps(row))}
												className="tr"
												onClick={() => onTRClick(row)}
											>
												{row.cells.map(cell => {
													const { key: cellKey, ...restCellProps } =
														cell.getCellProps(finalCellProps)
													return (
														<div
															key={cellKey}
															{...restCellProps}
															className="td"
															onClick={() => onTDClick(cell)}
														>
															{cell.render('Cell')}
														</div>
													)
												})}
											</div>
											{/* We could pass anything into this */}
											{row.isExpanded &&
												!!urlBaseExpndedRow &&
												renderRowSubComponent({
													row,
													rowProps,
													visibleColumns,
													urlBaseExpndedRow
												})}
										</div>
									)
								})
							) : (
								<StyledTableEmpty>
									<p>No se encontraron resultados</p>
								</StyledTableEmpty>
							)}
						</div>
					</div>
				</StyledTable>
				<TablePagination
					initialPage={pageIndex + 1}
					numberOfPages={pageOptions.length}
					onChange={handleChangePagination}
				/>
			</>
		</StyledTableContainer>
	)
}

const TableEmpty = ({ emptyMessage }) => {
	return (
		<StyledTableEmptyData>
			{emptyMessage ? emptyMessage() : <p>No hay datos en esta tabla</p>}
		</StyledTableEmptyData>
	)
}

const Table = ({ emptyMessage = null, data, ...restProps }) => {
	if (!data || !data?.length) return <TableEmpty emptyMessage={emptyMessage} />
	return <TableInner data={data} {...restProps} />
}

export default Table

import React, { createContext, useContext, useCallback, useMemo } from 'react'
import { useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'

import {
	LOGIN,
	FORGOT_PASSWORD,
	RESET_PASSWORD,
	SWITCH_ACCOUNT
} from '@constants/endpoints'

import createStore from '@store/createStore'
import { createSelectors } from '@store/utils'

import useAjax from '@hooks/ajax'
import { getToken, setToken, removeToken } from '@auth/token'

const AuthContext = createContext()
AuthContext.displayName = 'AuthContext'

const statusTypes = {
	IDLE: 'idle',
	LOADING: 'loading',
	SUCCESS: 'success',
	ERROR: 'error'
}

const initialState = {
	status: statusTypes.IDLE,
	error: null,
	token: getToken()
}

const createAuthStore = createStore({
	state: initialState,
	log: {
		name: '🗝️ AuthStore 🗝️'
	}
})

const useAuthStore = createSelectors(createAuthStore)

function AuthProvider(props) {
	const queryClient = useQueryClient()
	const navigate = useNavigate()

	const set = useAuthStore.use.set()
	const status = useAuthStore.use.status()
	const error = useAuthStore.use.error()
	const token = useAuthStore.use.token()

	const { post, cancel } = useAjax()

	const login = useCallback(
		async data => {
			try {
				console.log(data)
				set({ status: statusTypes.LOADING })
				const { token } = await post(LOGIN, { data })

				setToken(token)
				set({ token, status: statusTypes.SUCCESS })

				navigate('/')
			} catch (error) {
				set({ status: statusTypes.ERROR, error: error.response })
			}
		},
		[set, post]
	)

	const switchAccount = useCallback(
		async data => {
			try {
				console.log(data)
				set({ status: statusTypes.LOADING })
				const { token } = await post(SWITCH_ACCOUNT, { data })

				queryClient.clear()
				removeToken()

				setToken(token)

				const { protocol, host } = window.location
				const refreshURL = `${protocol}//${host}`

				window.location.assign(refreshURL)
			} catch (error) {
				set({ status: statusTypes.ERROR, error: error.response })
			}
		},
		[set, post]
	)

	const logout = useCallback(() => {
		queryClient.clear()
		cancel('Ajax cancelled by logout')
		removeToken()
		set({ token: null })
	}, [cancel])

	const forgotPassword = useCallback(
		async data => {
			try {
				set({ status: statusTypes.LOADING })
				await post(FORGOT_PASSWORD, { data })
				set({ status: statusTypes.SUCCESS })
			} catch (error) {
				set({ status: statusTypes.ERROR, error: error.response })
			}
		},
		[set, post]
	)

	const resetPassword = useCallback(
		async data => {
			try {
				set({ status: statusTypes.LOADING })
				await post(RESET_PASSWORD, { data })
				set({ status: statusTypes.SUCCESS })
			} catch (error) {
				console.log(error)
				set({ status: statusTypes.ERROR, error: error.response })
			}
		},
		[set, post]
	)

	const resetStatus = useCallback(() => {
		set({ status: statusTypes.IDLE, error: null })
	}, [])

	const value = useMemo(
		() => ({
			status,
			token,
			login,
			switchAccount,
			logout,
			forgotPassword,
			resetPassword,
			resetStatus,
			error
		}),
		[
			status,
			token,
			login,
			switchAccount,
			logout,
			forgotPassword,
			resetPassword,
			resetStatus,
			error
		]
	)

	/* if (status === statusTypes.LOADING) {
		return <PreloaderTopBar />
	} */

	return <AuthContext.Provider value={value} {...props} />
}

function useAuth() {
	const context = useContext(AuthContext)
	if (context === undefined) {
		throw new Error(`useAuth must be used within a AuthProvider`)
	}
	return context
}

export { AuthProvider, useAuth }

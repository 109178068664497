import React, { useCallback, useEffect } from 'react'
import styled from 'styled-components/macro'
import { useFormContext, useWatch } from 'react-hook-form'
import { useDebouncedCallback } from 'use-debounce'

import { ReactComponent as SearchIcon } from '@svgs/loupe.svg'

import { TextField } from '@components/form'

const StyledTableSearch = styled.section`
	width: 100%;
	height: 40px;
	position: relative;

	@media (max-width: 768px) {
		max-width: none !important;
	}

	svg {
		width: 20px;
		height: 20px;
		fill: var(--c__grey-500);
		position: absolute;
		top: 50%;
		left: 15px;
		z-index: 1;
		transform: translateY(-50%);
	}

	input {
		padding-left: 50px;
	}
`

function TableSearch({ debounceMs = 150, onChange = () => {} }) {
	const { control } = useFormContext()
	const search = useWatch({
		control,
		name: 'search',
		defaultValue: ''
	})

	const { callback } = useDebouncedCallback(
		useCallback(
			value => {
				onChange(value)
			},
			[onChange]
		),
		debounceMs
	)

	useEffect(() => {
		callback(search)
	}, [callback, search])

	return (
		<StyledTableSearch className="table-search">
			<SearchIcon />
			<TextField
				type="search"
				name="search"
				placeholder="Escribe lo que deseas buscar"
				size="small"
				rules={{
					required: true
				}}
			/>
		</StyledTableSearch>
	)
}

export default TableSearch

import React from 'react'
import styled from 'styled-components/macro'

import { Spinner } from '@components/preloaders'

const StyledCardPlaceholder = styled.aside`
	width: 100%;
	height: 100%;
	min-height: var(--card__height);
	background-color: var(--c__white);
	border-radius: var(--border__radius);
	box-shadow: var(--card__shadow);
	display: flex;
	align-items: center;
	justify-content: center;
`
function CardPlaceholder({ children, loading }) {
	if (loading) {
		return (
			<StyledCardPlaceholder>
				<Spinner size={28} strokeWidth={10} strokeColor="#969696" />
			</StyledCardPlaceholder>
		)
	} else {
		return children
	}
}

export default CardPlaceholder

import React, { useCallback } from 'react'
import styled from 'styled-components/macro'

import yup from '@yup'

import useSelect from '@hooks/select'
import useCRUD from '@hooks/crud'
import { useInfoPage } from '@hooks/info'

import { Form, TextField, Select, Checkbox } from '@components/form'
import { Button } from '@components/button'
import { Grid, GridItem } from '@components/grid'
import { Flex } from '@components/flex'
import { PreloaderInside } from '@components/preloaders'

const StyledDialogMapNewHarvest = styled.div`
	width: 100%;
	height: 100%;
`

const schema = yup.object().shape({
	title: yup.string().required(),
	quantity: yup.string().required(),
	campaign_id: yup.string().required(),
	holding_id: yup.string().required()
})

const DialogMapNewHarvestInner = ({
	data,
	units,
	holdings,
	campaigns,
	onCancel = () => {}
}) => {
	// holding
	const { select: holdingsSelect, selectValue: holdingsSelectValue } =
		useSelect({
			name: 'holding_id',
			collection: holdings,
			value: null
		})

	// campaign
	const { select: campaignsSelect, selectValue: campaignsSelectValue } =
		useSelect({
			name: 'campaign_id',
			collection: campaigns,
			value: null
		})

	// units harvest
	const { select: unitsSelect, selectValue: unitsSelectValue } = useSelect({
		name: 'unit',
		collection: units,
		value: null
	})

	return (
		<Grid>
			<GridItem columnStart="1" columnEnd="-1">
				<TextField
					name="title"
					placeholder="Escribe el nombre"
					label="Nombre de la cosecha*"
					value={null}
					inverted
				/>
			</GridItem>
			<GridItem columnStart="1" columnEnd="-1">
				<Select
					options={campaignsSelect}
					name="campaign_id"
					label="Campañas *"
					placeholder="Selecciona una campaña"
					defaultValue={campaignsSelectValue}
					inverted
				/>
			</GridItem>
			<GridItem columnStart="1" columnEnd="-1">
				<Select
					options={holdingsSelect}
					name="holding_id"
					label="Explotación *"
					placeholder="Selecciona una explotación"
					defaultValue={holdingsSelectValue}
					inverted
				/>
			</GridItem>
			<GridItem columnStart="span 6">
				<TextField
					name="quantity"
					placeholder="Escribe la cantidad"
					label="Cantidad *"
					value={null}
					inverted
				/>
			</GridItem>
			<GridItem columnStart="span 6">
				<Select
					options={unitsSelect}
					name="unit"
					label="Unidades"
					placeholder="Selecciona una unidad"
					defaultValue={unitsSelectValue}
					inverted
				/>
			</GridItem>
			<GridItem columnStart="1" columnEnd="-1">
				<Checkbox
					name="irrigation"
					checked={false}
					label="Es de tipo regadío"
				/>
			</GridItem>
			<GridItem columnStart="1" columnEnd="-1">
				<Checkbox name="dry" checked={false} label="Es de tipo secano" />
			</GridItem>
			<GridItem columnStart="1" columnEnd="-1">
				<Checkbox
					name="greenhouse"
					checked={false}
					label="Es de tipo invernadero / cultivo protegido"
				/>
			</GridItem>

			<GridItem columnStart="1" columnEnd="-1">
				<Flex justifyContent="space-between" alignItems="center">
					<Button
						label={'Cancelar'}
						theme="secondary"
						size="large"
						onClick={onCancel}
					/>
					<Button
						label={'Guardar'}
						type="submit"
						theme="primary"
						size="large"
					/>
				</Flex>
			</GridItem>
		</Grid>
	)
}

const DialogMapNewHarvest = ({ area, closeModal }) => {
	const { useCampaigns, useHoldings } = useInfoPage({
		type: 'agriculture'
	})

	const { useRead: useHarvests } = useCRUD({
		baseKey: ['info/harvests'],
		url: 'info/harvests'
	})

	const { data: campaigns, status: campaignsStatus } = useCampaigns()
	const { data: holdings, status: holdingsStatus } = useHoldings()
	const { data: harvests, status: harvestsStatus } = useHarvests()

	const handleSubmit = async data => {
		console.log(data)
	}

	const handleError = useCallback(error => {
		console.log(error)
	}, [])

	return (
		<StyledDialogMapNewHarvest>
			<Form
				onSubmit={handleSubmit}
				onError={handleError}
				loading={false}
				schema={schema}
				useFormProps={{
					mode: 'onChange'
					// defaultValues: formatObject(item, ['harvest_area'])
				}}
			>
				<PreloaderInside
					loading={
						campaignsStatus === 'loading' ||
						holdingsStatus === 'loading' ||
						harvestsStatus === 'loading'
					}
					minHeight={300}
				>
					<DialogMapNewHarvestInner
						campaigns={campaigns?.children}
						holdings={holdings?.children}
						units={harvests?.units_harvest}
						onCancel={() => closeModal()}
					/>
				</PreloaderInside>
			</Form>
		</StyledDialogMapNewHarvest>
	)
}

export default DialogMapNewHarvest

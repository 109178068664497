import { useCallback, useState, useEffect } from 'react'
import useInterval from 'use-interval'

function useTimer({ autoStart = true, limit = 5, onStop = () => {} } = {}) {
	const [timer, setTimer] = useState(0)
	const [timerRunning, setTimerRunning] = useState(autoStart)

	const startTimer = useCallback(() => {
		setTimerRunning(true)
	}, [setTimerRunning])

	const pauseTimer = useCallback(() => {
		setTimerRunning(false)
	}, [setTimerRunning])

	const stopTimer = useCallback(() => {
		setTimer(0)
		setTimerRunning(false)
	}, [setTimerRunning])

	useInterval(
		() => {
			setTimer(timer + 1)
		},
		timerRunning ? 1000 : null
	)

	useEffect(() => {
		if (timer >= limit) {
			setTimerRunning(false)
			onStop()
		}
	}, [timer])

	useEffect(() => {
		return () => stopTimer()
	}, [])

	return { startTimer, pauseTimer, stopTimer, timer, timerRunning }
}

export default useTimer

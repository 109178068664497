import React from 'react'
import styled from 'styled-components/macro'

import Footer from './Footer'

const StyledMain = styled.main`
	width: 100%;
	flex: 1;

	@media (min-width: 1024px) {
		display: grid;
		grid-template-rows: auto var(--app__footer-height);
	}

	& > article {
		width: 100%;
		height: 100%;
	}
`

const LayoutMain = ({ children }) => {
	return (
		<StyledMain>
			<article>{children}</article>
			<Footer />
		</StyledMain>
	)
}

export default LayoutMain

export const cardAnimation = {
	hidden: {
		opacity: 0,
		y: 30
	},
	visible: custom => ({
		opacity: 1,
		y: 0,
		transition: { delay: custom * 0.05, duration: 0.5, ease: 'circOut' }
	})
}

export const cardIntroAnimation = {
	hidden: {
		opacity: 0,
		y: 30
	},
	visible: {
		opacity: 1,
		y: 0,
		transition: { duration: 0.2, ease: 'circOut' }
	}
}

export const scaleIn = {
	hidden: {
		opacity: 0,
		scale: 0.5
	},
	visible: {
		opacity: 1,
		scale: 1,
		transition: { duration: 0.2, ease: 'circOut' }
	}
}

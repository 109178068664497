import React from 'react'
import styled from 'styled-components/macro'
import { useDeviceSelectors } from 'react-device-detect'

import { ReactComponent as Firma } from '@svgs/firma.svg'

const StyledFooter = styled.footer`
	background-color: var(--c__green-700);

	& > div {
		height: 100%;
		padding: var(--grid__gap);
		display: flex;
		gap: 30px;
		flex-flow: column;
		justify-content: center;
		align-items: center;

		@media (min-width: 1024px) {
			flex-flow: row;
			justify-content: space-between;
			gap: 20px;
			padding: 0 var(--grid__gap);
		}
	}
`
const StyledFooterLinks = styled.ul`
	display: flex;
	flex-flow: column;
	align-items: center;
	gap: 15px;

	@media (min-width: 768px) {
		flex-flow: row;
		gap: 0;
	}

	li {
		@media (min-width: 768px) {
			padding: 0 20px;
			border-right: 1px solid var(--c__green);
		}

		&:last-child {
			border-right: none;
		}

		a {
			font-size: 14px;
			color: var(--c__white);
			transition: opacity 150ms ease-out;

			&:hover {
				opacity: 0.7;
			}
		}
	}
`

const StyledFooterMetaLink = styled.p`
	font-size: 14px;
	color: var(--c__white);
	display: flex;
	align-items: center;

	span,
	strong,
	svg {
		display: inline-block;
	}
	span {
		margin-right: 5px;
	}
`
const StyledFooterFirma = styled.figure`
	width: 40px;
	height: 30px;
	fill: var(--c__white);
`

const Footer = () => {
	const [selectors] = useDeviceSelectors()
	const { isMobile, isDesktop } = selectors

	return (
		<StyledFooter>
			<div>
				<StyledFooterMetaLink>
					<span>Necesitas ayuda</span>
					<strong>649 132 597</strong>
				</StyledFooterMetaLink>
				{!isMobile && (
					<StyledFooterLinks>
						<li>
							<a
								href="https://okcampo.com/condiciones-de-uso-de-okcampo"
								title="Términos y condiciones"
								target="_blank"
								rel="noopener noreferrer"
							>
								Términos y condiciones
							</a>
						</li>
						<li>
							<a
								href="https://okcampo.com/politica-de-privacidad"
								title="Política de privacidad"
								target="_blank"
								rel="noopener noreferrer"
							>
								Política de privacidad
							</a>
						</li>
						<li>
							<a
								href="https://okcampo.com/"
								title="Contáctanos"
								target="_blank"
								rel="noopener noreferrer"
							>
								Contáctanos
							</a>
						</li>
					</StyledFooterLinks>
				)}

				<StyledFooterFirma>
					<Firma />
				</StyledFooterFirma>
			</div>
		</StyledFooter>
	)
}

export default Footer

import React, { useCallback, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import styled from 'styled-components/macro'
import { useFormContext } from 'react-hook-form'
import { omit } from 'lodash'

import yup from '@yup'

import useSelect from '@hooks/select'
import { useUtils } from '@hooks/utils'
import useCampaigns from '@hooks/campaigns'
import { useInfoPage } from '@hooks/info'
import useNumber from '@hooks/number'

import {
	Form,
	TextField,
	Datepicker,
	useDatepickerRange
} from '@components/form'
import { PreloaderInside } from '@components/preloaders'
import { Button } from '@components/button'
import { Grid, GridItem } from '@components/grid'
import { Text } from '@components/text'
import { Flex } from '@components/flex'

const StyledCampaign = styled.div`
	width: 100%;
	height: 100%;
`

const schema = yup.object().shape({})

const CampaignInner = ({ selectPlots, onDelete = () => {} }) => {
	const { getValues, reset } = useFormContext()
	const data = getValues()

	const { rangeStart, rangeEnd } = useDatepickerRange({
		nameStart: 'begin_date',
		nameEnd: 'end_date'
	})

	const { select: plotsSelect, selectValue: plotsSelectValue } = useSelect({
		name: 'plots',
		collection: selectPlots?.children,
		value: data?.plots
	})

	useEffect(() => {
		reset()
	}, [])

	return (
		<Grid>
			<GridItem columnStart="span 12">
				<TextField
					name="title"
					label="Nombre *"
					placeholder="Escribe el nombre"
					value={data?.title}
					inverted
				/>
			</GridItem>
			{/* <GridItem columnStart="span 12">
				<Select
					options={plotsSelect}
					name="plots"
					label="Parcelas *"
					defaultValue={plotsSelectValue}
					isMulti={true}
					inverted
				/>
			</GridItem> */}
			<GridItem columnStart="span 6">
				<Datepicker
					name="begin_date"
					label="Inicio de campaña"
					defaultValue={data?.begin_date}
					inverted
					selectsStart
					startDate={rangeStart}
					endDate={rangeEnd}
				/>
			</GridItem>
			<GridItem columnStart="span 6">
				<Datepicker
					name="end_date"
					label="Fin de campaña"
					defaultValue={data?.end_date}
					inverted
					selectsEnd
					minDate={rangeStart}
					startDate={rangeStart}
					endDate={rangeEnd}
				/>
			</GridItem>
			<GridItem columnStart="1" columnEnd="-1">
				<Flex justifyContent={'space-between'} alignItems="center">
					<Button
						label={'Eliminar campaña'}
						type="button"
						theme="danger"
						onClick={e => onDelete(data?.id)}
					/>
					<Button
						label={'Actualizar campaña'}
						type="submit"
						theme="primary"
						size="large"
					/>
				</Flex>
			</GridItem>
			{!!data?.id && (
				<GridItem columnStart="1" columnEnd="-1">
					<Flex justifyContent="center">
						<Text>
							<p>
								<strong>Consejo: </strong>
								Para introducir más detalles haz{' '}
								<Link to={`/agriculture/campaigns/${data?.id}/edit`}>
									click aquí
								</Link>
							</p>
						</Text>
					</Flex>
				</GridItem>
			)}
		</Grid>
	)
}

const DialogCampaign = ({ item, closeModal }) => {
	console.log('item', item)
	const location = useLocation()
	const { exclude } = useUtils()
	const { formatObject } = useNumber()

	const { useUpdateCampaign, useDeleteCampaign } = useCampaigns()

	const { useFormData, usePlots } = useInfoPage({
		categoryId: 'agriculture',
		pageId: 'campaigns'
	})

	const { data: selectData, status: statusData } = useFormData()
	const { data: selectPlots, status: statusPlots } = usePlots()

	const { mutateAsync: updateCampaign, status: statusUpdate } =
		useUpdateCampaign({ id: item.id })
	const { mutateAsync: deleteCampaign, status: statusDelete } =
		useDeleteCampaign({ id: item.id })

	const handleDelete = useCallback(
		async id => {
			await deleteCampaign(id)
			closeModal()
		},
		[deleteCampaign, closeModal]
	)

	const handleSubmit = useCallback(
		async formData => {
			console.log('formData', formData)
			console.log('put', {
				...omit(item, ['slug', 'ganttId']),
				...omit(formData, ['slug', 'ganttId'])
			})
			await updateCampaign({
				...omit(item, ['slug', 'ganttId']),
				...omit(formData, ['slug', 'ganttId'])
			})
			closeModal()
		},
		[item, closeModal, updateCampaign]
	)

	const handleError = useCallback(error => {
		console.log(error)
	}, [])

	useEffect(() => {
		return () => closeModal()
	}, [location])

	return (
		<StyledCampaign>
			<Form
				onSubmit={handleSubmit}
				onError={handleError}
				loading={statusUpdate === 'loading' || statusDelete === 'loading'}
				schema={schema}
				hasPrompt={false}
				onFilterState={form => {
					console.log(form)
					return exclude(form, ['slug'])
				}}
				useFormProps={{
					mode: 'onChange',
					defaultValues: formatObject(item, ['harvest_area'])
				}}
			>
				<PreloaderInside
					loading={statusData === 'loading' || statusPlots === 'loading'}
					minHeight={300}
				>
					<CampaignInner
						data={item}
						closeModal={closeModal}
						selectData={selectData}
						selectPlots={selectPlots}
						onDelete={handleDelete}
					/>
				</PreloaderInside>
			</Form>
		</StyledCampaign>
	)
}

export default DialogCampaign

import React from 'react'
import styled from 'styled-components/macro'

import useMenu from '@hooks/menu'
import { PreloaderInside } from '@components/preloaders'

import MenuItem from './MenuItem'

const StyledMenu = styled.ul`
	width: 100%;
	padding: 20px;
`
//quite arriba esto height: calc(100vh - var(--dashboard__toolbar-height) - 171px); overflow-y: auto;

function Menu() {
	const { status, menu, toggleMenu } = useMenu()

	const handleToggle = id => {
		toggleMenu(id)
	}

	return (
		<StyledMenu>
			<PreloaderInside loading={status === 'loading'}>
				{menu.map(item => {
					return (
						<MenuItem
							key={item.id}
							item={item}
							opened={item.active}
							onToggle={handleToggle}
						/>
					)
				})}
			</PreloaderInside>
		</StyledMenu>
	)
}

export default Menu

import { useRef, useCallback } from 'react'
import mapboxgl from 'mapbox-gl'
import { round } from 'lodash'
import * as turf from '@turf/turf'

import { useMapContext } from '@map/context'

function useMapbox() {
	const hoverRef = useRef(null)

	const { map: mapObject } = useMapContext()

	const drawEnclosure = (mapObject, geometry, sourceId, layerId, layer) => {
		mapObject.flyTo({
			center: geometry.coordinates[0][0],
			zoom: 14
		})

		if (!mapObject.getSource(sourceId)) {
			mapObject.addSource(sourceId, {
				type: 'geojson',
				data: {
					type: 'Feature',
					geometry
				}
			})
		}
		if (!mapObject.getLayer(layerId))
			mapObject.addLayer({ ...layer, source: sourceId, id: layerId })
	}

	const drawEnclosuresCollection = useCallback(
		({ collection, layer }) => {
			if (collection.length) {
				collection.forEach(item => {
					const sourceId = `source-${item.id}`
					const layerId = `layer-${item.id}`

					if (!mapObject.getSource(sourceId)) {
						mapObject.addSource(sourceId, {
							type: 'geojson',
							data: {
								type: 'Feature',
								geometry: item.geometry
							}
						})
					}
					if (!mapObject.getLayer(layerId))
						mapObject.addLayer({ ...layer, source: sourceId, id: layerId })
				})
			} else {
				return null
			}
		},
		[mapObject]
	)

	const addPlotMap = (mapObject, { filter_item_id, features, color }) => {
		const layer = {
			type: 'fill',
			layout: {},
			paint: {
				// 'fill-color': `#${color}`, // blue color fill
				'fill-color': `#91ba5b`, // blue color fill
				'fill-opacity': 0.5
			}
		}

		features.map(({ id, geometry }) => {
			const sourceId = `source-${filter_item_id}-${id}`
			const layerId = `layer-${filter_item_id}-${id}`

			drawEnclosure(mapObject, geometry, sourceId, layerId, layer)
		})
	}

	const removePlotMap = (mapObject, { filter_item_id, enclosures }) => {
		const removeLayerSource = (map, layerId, sourceId) => {
			if (map.getLayer(layerId)) map.removeLayer(layerId)
			if (map.getSource(sourceId)) map.removeSource(sourceId)
		}

		enclosures.map(id =>
			removeLayerSource(
				mapObject,
				`layer-${filter_item_id}-${id}`,
				`source-${filter_item_id}-${id}`
			)
		)
	}

	const mouseMoveListener = (e, mapObject, source, layer, promoteId) => {
		if (e.features.length > 0) {
			if (hoverRef.current) {
				mapObject.setFeatureState(
					{
						source,
						id: hoverRef.current,
						sourceLayer: layer
					},
					{ hover: false }
				)
			}

			hoverRef.current = e.features[0].properties[promoteId]

			if (hoverRef.current) {
				mapObject.setFeatureState(
					{
						source,
						id: hoverRef.current,
						sourceLayer: layer
					},
					{ hover: true }
				)
			}

			mapObject.getCanvas().style.cursor = 'pointer'
		}
	}

	const mouseLeaveListener = (e, mapObject, source, layer) => {
		if (hoverRef.current) {
			mapObject.setFeatureState(
				{
					source,
					id: hoverRef.current,
					sourceLayer: layer
				},
				{ hover: false }
			)

			mapObject.getCanvas().style.cursor = ''
		}
		hoverRef.current = null
	}

	const mouseClickListener = (e, mapObject) => {
		const popup = new mapboxgl.Popup()
			.setLngLat(e.lngLat)
			.setHTML(e.features[0].properties?.CODE)
			.addTo(mapObject)
		console.log(popup)
	}

	const fitFeature = (geometry, map, options = {}) => {
		const bbox = turf.bbox(geometry)
		map.fitBounds(bbox, { padding: 100, ...options })
	}

	const addFeature = (map, draw, feature) => {
		draw.add(feature)
		draw.changeMode('direct_select', { featureId: feature?.id })
		fitFeature(feature?.geometry, map)
	}

	const addSigpacFeature = (map, draw, feature) => {
		draw.add(feature?.geometry)
		map.flyTo({
			essential: true,
			center: feature?.geometry.coordinates[0][0],
			zoom: 50,
			speed: 3
		})
	}

	const polygonToFeature = (polygon, properties = {}) =>
		turf.feature(polygon, properties)

	const deleteFeatures = draw => {
		draw.deleteAll()
	}

	const getArea = (geojson, decimals = 2) => {
		const area = turf.area(geojson)
		return {
			area: round(area, decimals),
			has: round(area / 10000, decimals)
		}
	}

	const freeDraw = useCallback((map, draw) => {
		map.getCanvas().style.cursor = 'default'
		draw.changeMode('draw_polygon')
	}, [])

	const cancelFreeDraw = useCallback((map, draw) => {
		map.getCanvas().style.cursor = 'pointer'
		draw.changeMode('simple_select')
	}, [])

	const addTileset = (mapObject, id, layer) => {
		const sourceTiles = `mapbox://seresinertes.${id}`
		const source = `province-${id}`
		const promoteId = 'CODE'

		mapObject.off('mousemove')
		mapObject.off('mouseleave')

		mapObject.addSource(source, {
			type: 'vector',
			url: sourceTiles,
			promoteId
		})

		mapObject.addLayer({
			id: `tiles-events-${id}`,
			type: 'fill',
			source,
			layout: {
				visibility: 'visible'
			},
			'source-layer': layer,
			paint: {
				'fill-opacity': 0
			}
		})

		mapObject.addLayer({
			id: `tiles-fills-${id}`,
			type: 'fill',
			source,
			layout: {
				visibility: 'visible'
			},
			'source-layer': layer,
			paint: {
				'fill-color': '#77c611',
				'fill-opacity': [
					'case',
					['boolean', ['feature-state', 'hover'], false],
					1,
					0
				]
			}
		})

		mapObject.addLayer({
			id: `tiles-borders-${id}`,
			type: 'line',
			source,
			layout: {
				visibility: 'visible'
			},
			'source-layer': layer,
			paint: {
				'line-width': 1,
				'line-opacity': [
					'case',
					['boolean', ['feature-state', 'hover'], false],
					1,
					0.5
				],
				'line-color': [
					'case',
					['boolean', ['feature-state', 'click'], false],
					'#fff',
					'#77c611'
				]
			}
		})

		mapObject.on('mousemove', `tiles-events-${id}`, e =>
			mouseMoveListener(e, mapObject, source, layer, promoteId)
		)
		mapObject.on('mouseleave', `tiles-events-${id}`, e =>
			mouseLeaveListener(e, mapObject, source, layer)
		)
		mapObject.on('click', `tiles-events-${id}`, e =>
			mouseClickListener(e, mapObject)
		)
	}

	/* const togglePlotMapbox = useCallback(
		plot => {
			if (plot.mapbox)
				plot.mapbox.map(({ mapbox }) =>
					mapbox.map(({ layerId, sourceId }) =>
						removeEnclosureInMap(map, layerId, sourceId)
					)
				)

			set(({ map }) => map.enclosures.splice(0, 1, plot))
		},
		[map, set]
	) */

	return {
		drawEnclosure,
		drawEnclosuresCollection,
		addPlotMap,
		addTileset,
		removePlotMap,
		addFeature,
		addSigpacFeature,
		deleteFeatures,
		getArea,
		fitFeature,
		freeDraw,
		cancelFreeDraw,
		polygonToFeature
	}
}

export default useMapbox

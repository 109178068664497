import createStore from '@store/createStore'
import { createSelectors } from '@store/utils'

import userFieldsBg from '@images/map/types/user-fields.png'

import { MAP_DEFAULTS } from '@constants'

const styles = {
	layers: [
		{
			id: 'mis-campos',
			image: userFieldsBg,
			title: 'Mis parcelas',
			active: false
		},
		{
			id: 'recintos-sigpac',
			image: userFieldsBg,
			title: 'Recintos SIGPAC',
			active: true
		}
	],
	types: [
		{
			id: 'mapa',
			image: userFieldsBg,
			title: 'Mapa',
			path: 'seresinertes/ckfwckzjk058d19ny693f2z2e',
			active: true
		},
		{
			id: 'satelite',
			image: userFieldsBg,
			title: 'Satélite',
			path: 'seresinertes/cke9nzyw25jfi1arq7ma5k35s',
			active: false
		}
	]
}

const state = {
	campaigns: [],
	filters: [],
	styles,
	lng: MAP_DEFAULTS.lng,
	lat: MAP_DEFAULTS.lat,
	zoom: MAP_DEFAULTS.zoom,
	activeCampaign: MAP_DEFAULTS.activeCampaign,
	activeFilters: MAP_DEFAULTS.activeFilters
}

const useMapStore = createStore({
	state,
	log: {
		name: '🗺️ MapStore 🗺️'
	}
})

export default createSelectors(useMapStore)

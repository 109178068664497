import React, { useEffect, useCallback } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { useDebouncedCallback } from 'use-debounce'

import { Form, TextField } from '@components/form'

const TableSearchColumnInner = ({
	filterValue,
	setFilter,
	placeholder,
	debounceTimeout
}) => {
	const { control } = useFormContext()
	const search = useWatch({
		control,
		name: 'search',
		defaultValue: filterValue || ''
	})

	const { callback } = useDebouncedCallback(
		useCallback(
			search => {
				setFilter(search)
			},
			[setFilter]
		),
		debounceTimeout
	)

	useEffect(() => {
		callback(search)
	}, [callback, search])

	return (
		<TextField
			type="search"
			name="search"
			placeholder={placeholder}
			size="small"
			value={search}
		/>
	)
}
const TableSearchColumn = ({
	column,
	placeholder = 'Buscar',
	debounceTimeout = 300
}) => {
	return (
		<Form hasPrompt={false}>
			<TableSearchColumnInner
				{...column}
				placeholder={placeholder}
				debounceTimeout={debounceTimeout}
			/>
		</Form>
	)
}

export default TableSearchColumn

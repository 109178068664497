import React from 'react'
import styled from 'styled-components/macro'

import bgSrc from '@images/category/header-campo.png'

import { StyledCard } from '@components/cards'
import { cardIntroAnimation } from './animations'

const StyledCardPageIntro = styled(StyledCard)`
	background-image: url(${bgSrc});
	background-repeat: no-repeat;
	background-size: auto 50%;
	background-position: center top;
	background-color: var(--c__green);
	width: 100%;
	height: var(--card__height--feat);
	position: relative;
	padding: var(--card__gutter);
	transition: all 150ms ease-out;
	box-shadow: var(--card__shadow);
	display: flex;
	align-items: center;

	@media (min-width: 568px) {
		background-size: auto 50%;
		background-position: right top;
	}
	@media (min-width: 1024px) {
		background-size: auto 100%;
		background-position: right top;
	}

	h1 {
		font-size: 30px;
		line-height: 1.2;
		color: var(--c__white);
		margin-left: var(--card__intro-gutter);

		@media (min-width: 768px) {
			font-size: 36px;
		}
		@media (min-width: 1024px) {
			font-size: 46px;
		}
	}
`

function CardPageIntro({ title, animated = true }) {
	return (
		<StyledCardPageIntro
			initial="hidden"
			animate="visible"
			variants={animated ? cardIntroAnimation : {}}
		>
			<h1>{title}</h1>
		</StyledCardPageIntro>
	)
}

export default CardPageIntro

import { useCallback } from 'react'
import { isBoolean, isString } from 'lodash'
import { format, parse } from 'numerable'
import { es } from 'numerable/locale'
import isNumber from 'is-number'

const locales = {
	es: {
		locale: es,
		pattern: '0,0[.]0[000000]'
	}
}

// más adelante si hay que meter idiomas, esto cambiará a un valor en el store
const currentLocale = 'es'

function useNumber() {
	const formatFn = useCallback(
		(value, pattern = locales[currentLocale].pattern, options = {}) => {
			const a = format(Number(value), pattern, {
				locale: locales[currentLocale].locale,
				rounding: number => number,
				...options
			})
			return a
		},
		[]
	)

	const parseFn = useCallback((value, options = {}) => {
		return parse(value, {
			locale: locales[currentLocale].locale,
			defaultPattern: locales[currentLocale].pattern,
			rounding: null,
			...options
		})
	}, [])

	const parseNumber = useCallback(
		value => {
			if (isBoolean(value)) return value
			if (isString(value)) {
				const isSpanishNumberRegex = /^\d+(,?\d+)?$/g
				const selectAllDotsRegex = /(\.*)/gi

				if (isSpanishNumberRegex.test(value.replace(selectAllDotsRegex, ''))) {
					return isNumber(parseFn(value)) ? parseFn(value) : value
				} else {
					return isNumber(value) ? Number(value) : value
				}
			} else {
				return value
			}
		},
		[parseFn]
	)

	const formatObject = useCallback(
		(data, keys = []) => {
			if (!data) return null

			let out = {}

			Object.keys(data).forEach(key => {
				const value = data[key]

				out = {
					...out,
					[key]: keys.length && keys.includes(key) ? formatFn(value) : value
				}
			})

			return out
		},
		[formatFn]
	)

	const parseObject = useCallback(
		(data, keys = []) => {
			if (!data) return null

			let out = {}

			Object.keys(data).forEach(key => {
				const value = data[key]

				out = {
					...out,
					[key]: keys.length && keys.includes(key) ? parseFn(value) : value
				}
			})

			return out
		},
		[parseFn]
	)

	return {
		format: formatFn,
		parse: parseFn,
		parseNumber,
		formatObject,
		parseObject
	}
}

export default useNumber

import yup from '@yup'

export const numberValidation = yup
	.number()
	.transform(function (value, originalValue) {
		if (this.isType(value)) return value
		if (!originalValue || !originalValue.trim()) {
			return null
		}
		return originalValue
	})
	.nullable(true)
	.typeError('Debe ser un número')
	.positive('Debe ser un número positivo')

export const numberValidationPositiveWithZero = yup
	.number()
	.transform(function (value, originalValue) {
		if (this.isType(value)) return value
		if (!originalValue || !originalValue.trim()) {
			return null
		}
		return originalValue
	})
	.nullable(true)
	.typeError('Debe ser un número')
	.moreThan(-1, 'No puede ser un número negativo')

export const numberValidationMoreThanZero = yup
	.number()
	.transform(function (value, originalValue) {
		if (this.isType(value)) return value
		if (!originalValue || !originalValue.trim()) {
			return null
		}
		return originalValue
	})
	.nullable(true)
	.typeError('Debe ser un número')
	.moreThan(0, 'Debe ser mayor que cero')

import React, { useCallback, useMemo, useEffect } from 'react'
import styled, { css } from 'styled-components/macro'
import { useNavigate } from 'react-router-dom'
import { omitBy, first } from 'lodash'
import cx from 'classnames'

import yup from '@yup'

import useCRUD from '@hooks/crud'
import useMapbox from '@hooks/mapbox'
import useNumber from '@hooks/number'
import useSelect from '@hooks/select'
import useSigpac from '@hooks/sigpac'

import useMapPlaceStore from '@map/store/place'

import createStore from '@store/createStore'

import {
	Form,
	TextField,
	Select,
	Checkbox,
	Description
} from '@components/form'
import { Button } from '@components/button'
import { Box } from '@components/layout'
import { Grid, GridItem } from '@components/grid'
import { PreloaderInside } from '@components/preloaders'

import MapSigpac from '@map/components/MapSigpac'

const StyledDialogMapNewPlot = styled.div`
	width: 100%;
	height: 100%;
`
const StyledDialogMapNewPlotNav = styled.nav`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 30px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
	border-radius: var(--border__radius--small);
`
const StyledDialogMapNewPlotNavItem = styled.button`
	background-color: var(--c__white);
	padding: 10px 20px;
	text-align: center;
	font-size: 14px;
	border-right: 1px solid var(--c__grey-200);
	transition: background 150ms ease-out;
	flex: 1;

	&:hover {
		background-color: var(--c__grey-200);
	}

	&:first-child {
		border-radius: var(--border__radius--small) 0 0 var(--border__radius--small);
	}
	&:last-child {
		border-radius: 0 var(--border__radius--small) var(--border__radius--small) 0;
		border-right: none;
	}

	${({ $selected }) =>
		!!$selected &&
		css`
			background-color: var(--c__green) !important;
			color: var(--c__white);
		`}
`
const StyledDialogMapNewPlotFooter = styled.footer`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 30px;
`
const StyledDialogMapNewPlotTabItem = styled.section`
	display: block;

	&.hidden {
		display: none;
	}
`

const defaultTabsStoreState = [
	{
		id: 'datos-principales',
		title: 'Datos principales',
		selected: true
	},
	{
		id: 'caracteristicas',
		title: 'Características',
		selected: false
	},
	{
		id: 'sigpac',
		title: 'Sigpac',
		selected: false
	}
]

const useTabsStore = createStore({
	state: (set, get) => ({
		tabs: defaultTabsStoreState,
		resetTabs: () =>
			set(state => {
				state.tabs = defaultTabsStoreState
			}),
		getSelectedTab: () => get().tabs.find(({ selected }) => selected),
		toggleTab: id =>
			set(state => {
				const selectedTab = state.tabs.find(tab => tab.id === id)
				const restTabs = state.tabs.filter(tab => tab.id !== id)

				selectedTab.selected = true
				restTabs.forEach(tab => {
					tab.selected = false
				})
			})
	}),
	log: {
		name: '🔀 TabsStore 🔀'
	}
})

const schema = yup.object().shape({
	title: yup.string().required(),
	holding_id: yup.string().required()
})

const Tab1 = ({ data, selectHoldings }) => {
	const { format } = useNumber()

	const { select: holdingsSelect, selectValue: holdingsSelectValue } =
		useSelect({
			name: 'holding_id',
			collection: selectHoldings.children,
			value: data?.holding?.id
		})

	return (
		<Grid>
			<GridItem columnStart="1" columnEnd="-1">
				<TextField
					name="title"
					label="Nombre/Paraje *"
					placeholder="Escribe el nombre"
					value={data?.title}
					inverted
				/>
			</GridItem>
			<GridItem columnStart="1" columnEnd="-1">
				<TextField
					name="area"
					label="Superficie"
					inverted
					value={format(data?.area)}
					suffix="has"
				/>
			</GridItem>
			<GridItem columnStart="1" columnEnd="-1">
				<Select
					options={holdingsSelect}
					name="holding_id"
					label="Explotacion *"
					placeholder="Selecciona una explotación"
					defaultValue={data?.holding ? holdingsSelectValue : null}
					inverted
				/>
			</GridItem>
		</Grid>
	)
}

const Tab2 = ({ plotsData, data = {} }) => {
	const { format } = useNumber()

	// irrigation type
	const {
		select: irrigationTypeSelect,
		selectValue: irrigationTypeSelectValue
	} = useSelect({
		name: 'irrigation_type',
		collection: plotsData?.irrigation_type,
		value: data?.irrigation_type
	})

	// specific zones
	const { select: specificZonesSelect, selectValue: specificZonesSelectValue } =
		useSelect({
			name: 'specific_zone',
			collection: plotsData?.specific_zone,
			value: data?.specific_zone
		})

	// certification system
	const {
		select: certificationSystemSelect,
		selectValue: certificationSystemSelectValue
	} = useSelect({
		name: 'certification_system',
		collection: plotsData?.certification_system,
		value: data?.certification_system
	})

	return (
		<>
			<Box>
				<Grid>
					<GridItem columnStart="span 12">
						<Checkbox
							name="irrigation"
							checked={!!data?.irrigation}
							label="Es de tipo regadío"
						/>
					</GridItem>
					<GridItem columnStart="span 12">
						<Checkbox
							name="permanent_cultivation"
							checked={!!data?.permanent_cultivation}
							label="Es cultivo permanente"
						/>
					</GridItem>
					<GridItem columnStart="span 12">
						<Checkbox
							name="vulnerable_zone"
							checked={!!data?.vulnerable_zone}
							label="Zona vulnerable a la contaminación por nitratos"
						/>
					</GridItem>
				</Grid>
			</Box>
			<Box>
				<Grid>
					<GridItem columnStart="span 6">
						<Select
							options={irrigationTypeSelect}
							name="irrigation_type"
							label="Tipo de riego"
							defaultValue={irrigationTypeSelectValue}
							inverted
						/>
					</GridItem>
					<GridItem columnStart="span 6">
						<Select
							options={specificZonesSelect}
							name="specific_zone"
							label="Zona específica"
							defaultValue={specificZonesSelectValue}
							inverted
						/>
					</GridItem>
					<GridItem columnStart="span 12">
						<Select
							options={certificationSystemSelect}
							name="certification_system"
							label="Sistema de Certificación"
							defaultValue={certificationSystemSelectValue}
							inverted
						/>
					</GridItem>
				</Grid>
			</Box>
			<Box>
				<Grid>
					<GridItem columnStart="span 12">
						<Checkbox
							name="plot_water"
							checked={!!data?.plot_water}
							label="Punto de captación de agua en la parcela"
						/>
						<Description>
							<p>
								Explicación de cómo denominar las ZONA REGABLE, COMUNIDAD DE
								REGANTES, NOMBRE, CANAL DE CAPTACIÓN...
							</p>
						</Description>
					</GridItem>
					<GridItem columnStart="span 12">
						<TextField
							type="text"
							name="name_water"
							label="Denominación de los puntos de captación de agua"
							placeholder="Introduzca la denominación"
							value={data?.name_water}
							inverted
						/>
					</GridItem>
					<GridItem columnStart="span 12">
						<TextField
							type="text"
							name="distance_water"
							label="Distancia a los puntos de captación de agua"
							placeholder="Introduzca la distancia en metros"
							value={format(data?.distance_water)}
							inverted
						/>
					</GridItem>
				</Grid>
			</Box>
		</>
	)
}

const Tab3 = ({ data }) => {
	return (
		<Grid>
			<GridItem columnStart="1" columnEnd="-1">
				<MapSigpac data={data} />
			</GridItem>
		</Grid>
	)
}

const DialogMapNewPlotInner = ({
	isCreating,
	isDrawing,
	id,
	data,
	sigpac,
	tabs,
	plotsData,
	selectedTab,
	feature,
	closeModal,
	selectHoldings
}) => {
	const resetSigpac = useMapPlaceStore.use.resetSigpac()

	const navigate = useNavigate()
	const { getArea } = useMapbox()

	const { useCreate } = useCRUD({
		baseKey: ['plots', { type: 'agriculture' }],
		url: 'plots'
	})
	const { useUpdate, queryClient } = useCRUD({
		baseKey: ['plots', { type: 'agriculture' }, { id }],
		url: `plots`
	})

	const { mutate: createPlot, status: statusCreatePlot } = useCreate({
		config: {
			onSuccess: () => {
				closeModal()
				navigate(`/agriculture/fields/plots`)
			}
		}
	})
	const { mutate: updatePlot, status: statusUpdatePlot } = useUpdate({
		config: {
			onSuccess: () => {
				queryClient.invalidateQueries(['map', 'plots', id])
				closeModal()
				resetSigpac()
			}
		}
	})

	const area = useMemo(
		() => (isCreating ? getArea(feature)?.has : data?.area),
		[isCreating, feature]
	)

	const formData = useMemo(
		() => ({
			...data,
			area
			// holding: data?.plots?.holding
		}),
		[data, area]
	)

	const handleSubmit = useCallback(
		async data => {
			// quitamos del formState los sigpacs generados por los selects
			const formData = omitBy(data, (_, key) => !!key.startsWith('sigpac_us_'))

			if (isCreating) {
				createPlot({
					...formData,
					code: isDrawing ? first(sigpac)?.code : feature?.id,
					geometry: feature?.geometry,
					sigpac
				})
			} else {
				updatePlot({ ...formData, id, sigpac })
			}
		},
		[feature, sigpac, isCreating, id]
	)

	const handleError = useCallback(error => {
		console.log(error)
	}, [])

	return (
		<StyledDialogMapNewPlot>
			<Form
				onSubmit={handleSubmit}
				onError={handleError}
				schema={schema}
				loading={
					statusCreatePlot === 'loading' || statusUpdatePlot === 'loading'
				}
				useFormProps={{
					mode: 'onChange'
					// defaultValues: formatObject(item, ['harvest_area'])
				}}
			>
				<>
					<StyledDialogMapNewPlotTabItem
						className={cx([selectedTab.id !== tabs[0].id && 'hidden'])}
					>
						<Tab1
							key={tabs[0].id}
							isCreating={isCreating}
							data={formData}
							selectHoldings={selectHoldings}
						/>
					</StyledDialogMapNewPlotTabItem>
					<StyledDialogMapNewPlotTabItem
						className={cx([selectedTab.id !== tabs[1].id && 'hidden'])}
					>
						<Tab2
							key={tabs[1].id}
							isCreating={isCreating}
							plotsData={plotsData}
							data={data}
						/>
					</StyledDialogMapNewPlotTabItem>
					<StyledDialogMapNewPlotTabItem
						className={cx([selectedTab.id !== tabs[2].id && 'hidden'])}
					>
						<Tab3 key={tabs[2].id} isCreating={isCreating} data={sigpac} />
					</StyledDialogMapNewPlotTabItem>
					<StyledDialogMapNewPlotFooter>
						<Button
							label={'Cancelar'}
							theme="secondary"
							size="large"
							onClick={() => closeModal()}
						/>
						<Button
							label={isCreating ? 'Guardar' : 'Actualizar'}
							type="submit"
							theme="primary"
							size="large"
						/>
					</StyledDialogMapNewPlotFooter>
				</>
			</Form>
		</StyledDialogMapNewPlot>
	)
}

const DialogMapNewPlot = ({
	isCreating = false,
	isDrawing = false,
	id,
	feature = null,
	closeModal
}) => {
	const setSigpac = useMapPlaceStore.use.setSigpac()
	const sigpac = useMapPlaceStore.use.sigpac()
	const resetSigpac = useMapPlaceStore.use.resetSigpac()

	const tabs = useTabsStore(({ tabs }) => tabs)
	const toggleTab = useTabsStore(({ toggleTab }) => toggleTab)
	const resetTabs = useTabsStore(({ resetTabs }) => resetTabs)
	const getSelectedTab = useTabsStore(({ getSelectedTab }) => getSelectedTab)
	const selectedTab = getSelectedTab()

	const { useSigpacByPlot, useSigpacByFeature } = useSigpac()
	// const { useMapPlotCustom } = useMap()
	// const { data: dataPlot, status: statusPlot } = useMapPlotCustom(id)

	const { status: statusSigpacPlot } = useSigpacByPlot(id)
	const { status: statusSigpacFeature } = useSigpacByFeature(feature)

	const { useRead: useReadPlot } = useCRUD({
		baseKey: ['plots', { type: 'agriculture' }, { id }],
		url: `plots/${id}`
	})

	const { useRead: useInfoPlots } = useCRUD({
		baseKey: ['info/plots', { type: 'agriculture' }],
		url: 'info/plots'
	})

	const { useRead: useHoldings } = useCRUD({
		baseKey: ['holdings', { type: 'agriculture' }],
		url: 'holdings'
	})

	const { data: plotSingle, status: statusPlotSingle } = useReadPlot({
		config: {
			enabled: !!id,
			staleTime: 0,
			cacheTime: 0,
			onSuccess: data => {
				if ('sigpac' in data && data?.sigpac?.length) {
					setSigpac(data?.sigpac)
				}
			}
		}
	})

	const { data: plotsData, status: statusPlotsData } = useInfoPlots({
		ajax: {
			params: {
				type: 'agriculture'
			}
		}
	})

	const { data: selectHoldings, status: statusHoldings } = useHoldings({
		ajax: {
			params: {
				type: 'agriculture'
			}
		}
	})

	const isLoading = useMemo(() => {
		return (
			statusHoldings === 'loading' ||
			statusPlotSingle === 'loading' ||
			statusSigpacPlot === 'loading' ||
			statusPlotsData === 'loading' ||
			statusSigpacFeature === 'loading'
		)
	}, [
		statusHoldings,
		statusPlotSingle,
		statusSigpacPlot,
		statusPlotsData,
		statusSigpacFeature
	])

	useEffect(() => {
		return () => {
			resetTabs()
			resetSigpac()
		}
	}, [])

	return (
		<StyledDialogMapNewPlot>
			<PreloaderInside loading={isLoading} minHeight={300}>
				<>
					<StyledDialogMapNewPlotNav>
						{tabs.map(({ id, title }) => (
							<StyledDialogMapNewPlotNavItem
								key={id}
								$selected={selectedTab?.id === id}
								onClick={() => toggleTab(id)}
							>
								{title}
							</StyledDialogMapNewPlotNavItem>
						))}
					</StyledDialogMapNewPlotNav>
					<DialogMapNewPlotInner
						data={plotSingle}
						sigpac={sigpac}
						id={id}
						tabs={tabs}
						selectedTab={selectedTab}
						plotsData={plotsData}
						feature={feature}
						isCreating={isCreating}
						isDrawing={isDrawing}
						selectHoldings={selectHoldings}
						closeModal={closeModal}
					/>
				</>
			</PreloaderInside>
		</StyledDialogMapNewPlot>
	)
}

export default DialogMapNewPlot

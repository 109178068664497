import React, { useEffect, useState, useCallback, useMemo } from 'react'
import styled, { css } from 'styled-components/macro'
import { useFormContext, useController } from 'react-hook-form'

import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai'

import Label from './Label'
import ErrorMessage from './ErrorMessage'

const StyledTextFieldContainer = styled.div``
const StyledTextFieldInner = styled.div`
	width: 100%;
	position: relative;
`
const StyledTextField = styled.input`
	width: 100%;
	height: var(--textfield__height);
	line-height: 50px;
	padding: 0 15px;
	font-size: 14px;
	color: var(--c__grey-700);
	border-radius: var(--border__radius--small);
	border: 1px solid var(--c__white);
	background-color: var(--c__white);
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
	outline: none;
	transition: all 150ms ease-out;

	@media (min-width: 768px) {
		font-size: 16px;
	}

	&:focus:not([readonly]) {
		box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
	}

	&[readonly] {
		cursor: default;
	}

	&[type='number'] {
		padding-right: 0;
	}

	${({ $inverted }) =>
		!!$inverted &&
		css`
			border-color: var(--c__grey-200);
			background-color: var(--c__grey-200);
			box-shadow: none;

			&:focus:not([readonly]) {
				box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
			}
		`}

	${({ disabled }) =>
		!!disabled &&
		css`
			opacity: 0.4;
			pointer-events: none;
		`}

	${({ readOnly }) =>
		!!readOnly &&
		css`
			border-color: var(--c__grey-200);
			background-color: var(--c__grey-200);
		`}

	${({ error }) =>
		!!error &&
		css`
			border-color: var(--c__error);
			background-color: var(--c__error);
			color: var(--c__white);

			&::placeholder {
				color: var(--c__white);
			}
		`}

	${({ $size }) =>
		$size === 'small' &&
		css`
			height: var(--textfield__height--small);
			font-size: 14px;
			border-radius: var(--border__radius--small);
		`}
`
const StyledTextFieldSuffix = styled.div`
	position: absolute;
	top: 4px;
	right: 4px;
	bottom: 4px;
	z-index: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0 10px;
	border-radius: var(--border__radius--small);
	font-size: 13px;
	font-weight: 700;
	color: var(--c__grey-700);
	background-color: var(--c__grey-200);

	${({ $inverted, $error }) =>
		(!!$inverted || $error) &&
		css`
			background-color: var(--c__white);
		`}
`
const StyledTextFieldValueToggle = styled.div`
	position: absolute;
	top: 4px;
	right: 4px;
	bottom: 4px;
	z-index: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0 10px;
	color: var(--c__grey-500);

	${({ $error }) =>
		$error &&
		css`
			color: var(--c__white);
		`}

	button {
		width: 30px;
		height: 30px;
		font-size: 13px;
		font-weight: 700;
		color: inherit;

		svg {
			width: 100%;
			height: 100%;
		}
	}
`

function TextField({
	name = '',
	type = 'text',
	placeholder = '',
	value = '',
	label = null,
	rules = {},
	size = 'default', // default | small
	showError = true,
	readOnly = false,
	disabled = false,
	autoFocus = false,
	inverted = false,
	errorMessage = null,
	suffix = null,
	hasVisibilityToggle = false,
	onChange = () => {},
	onBlur = () => {},
	...restProps
}) {
	const [valueVisible, setValueVisible] = useState(false)

	const {
		control,
		setValue,
		formState: { errors }
	} = useFormContext()

	const { field } = useController({
		name,
		control,
		rules,
		defaultValue: value || ''
	})
	const { value: fieldValue, ...restField } = field

	const error = !!errors[name]
	const customErrorMessage = errors ? errors[name]?.message : null

	const handleToggleValueVisibility = useCallback(() => {
		setValueVisible(!valueVisible)
	}, [valueVisible])

	const finalType = useMemo(() => {
		return hasVisibilityToggle && valueVisible ? 'text' : type
	}, [hasVisibilityToggle, valueVisible])

	useEffect(() => {
		value && value !== '' && setValue(name, value || '')
	}, [value, name, setValue])

	return (
		<StyledTextFieldContainer>
			{label && <Label htmlFor={name} title={label} />}
			<StyledTextFieldInner>
				<StyledTextField
					type={finalType}
					name={name}
					error={error}
					placeholder={placeholder}
					$size={size}
					$inverted={inverted}
					id={name}
					readOnly={readOnly}
					disabled={disabled}
					autoFocus={autoFocus}
					{...restProps}
					{...restField}
					value={fieldValue || ''}
					onChange={e => {
						field.onChange(e.target.value)
						onChange(e.target.value, e.target)
					}}
					onBlur={e => {
						field.onBlur(e.target.value)
						onBlur(e.target.value, e.target)
					}}
				/>
				{!!suffix && (
					<StyledTextFieldSuffix $inverted={inverted} $error={error}>
						<span>{suffix}</span>
					</StyledTextFieldSuffix>
				)}
				{!!hasVisibilityToggle && (
					<StyledTextFieldValueToggle $error={error}>
						<button type="button" onClick={handleToggleValueVisibility}>
							{valueVisible ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
						</button>
					</StyledTextFieldValueToggle>
				)}
			</StyledTextFieldInner>
			{showError && error && (
				<ErrorMessage message={customErrorMessage || errorMessage} />
			)}
		</StyledTextFieldContainer>
	)
}

export default TextField

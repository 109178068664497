import { random } from 'lodash'
import { nanoid } from 'nanoid'
import namor from 'namor'

const range = len => {
	const arr = []
	for (let i = 0; i < len; i++) {
		arr.push(i)
	}
	return arr
}

const holdingNames = [
	'Cereal de Espelta',
	'Agropecuaria',
	'Granja de leche',
	'Cultivo de borraja',
	'Cultivo de caparrones',
	'Cultivo de olivos',
	'Cultivo de vid'
]

const newPerson = () => {
	return {
		id: nanoid(),
		name: holdingNames[random(0, holdingNames.length - 1)],
		dni: random(6666666, 999999),
		surface: random(10, 300),
		holdings: random(2, 50)
	}
}
const generateRowData = () => {
	const statusChance = Math.random()
	return {
		id: nanoid(),
		plot: namor.generate({ words: 1, numbers: 0 }),
		crop: namor.generate({ words: 1, numbers: 0 }),
		campaign: namor.generate({ words: 1, numbers: 0 }),
		total_cost: (Math.random() * 1000).toFixed(2),
		pac: '-',
		income: (Math.random() * 100).toFixed(2),
		margin: (Math.random() * 100).toFixed(2),
		machine: (0).toFixed(2),
		staff: (Math.random() * 100).toFixed(2),
		seeds_plants: (Math.random() * 100).toFixed(2),
		fertilizer: (0).toFixed(2),
		phytos: (0).toFixed(2),
		all_products: (0).toFixed(2),
		roi: Math.floor(Math.random() * 100)
	}
}
export function makeExpandedData(...lens) {
	const makeDataLevel = (depth = 0) => {
		const len = lens[depth]
		return range(len).map(() => generateRowData())
	}

	return makeDataLevel()
}

export default function makeData(...lens) {
	const makeDataLevel = (depth = 0) => {
		const len = lens[depth]
		return range(len).map(d => {
			return {
				...newPerson(),
				subRows: lens[depth + 1] ? makeDataLevel(depth + 1) : undefined
			}
		})
	}

	return makeDataLevel()
}

import React from 'react'
import styled, { css } from 'styled-components/macro'

const StyledBox = styled.section`
	margin-bottom: var(--layout__box-gutter);

	${({ $nested }) =>
		!!$nested &&
		css`
			padding-left: 30px;
		`}

	${({ $size }) =>
		!!$size &&
		$size !== 'normal' &&
		css`
			margin-bottom: ${`var(--layout__box-gutter--${$size})`};
		`}
`

function Box({
	children,
	size = 'normal', // small | normal | medium | large | huge
	nested = false,
	fullHeight = false
}) {
	return (
		<StyledBox
			className="box"
			$size={size}
			$nested={nested}
			style={{ height: fullHeight ? '100%' : 'auto' }}
		>
			{children}
		</StyledBox>
	)
}

export default Box

import createStore from '@store/createStore'
import { createSelectors } from '@store/utils'

const initialState = {
	campaigns: [],
	filters: [],
	activeAccordionItem: null,
	activeTool: null
}
const state = (set, get) => ({
	...initialState,
	reset: () => {
		set(state => {
			for (const key in initialState) {
				state[key] = initialState[key]
			}
		})
	}
})

const useMapToolsStore = createStore({
	state,
	persist: {
		name: 'okcampo-map-tools',
		partialize: ({ campaigns }) => ({
			campaigns
		})
	},
	log: {
		name: '🔧 MapToolsStore 🔧'
	}
})

export default createSelectors(useMapToolsStore)

// export const BASE_URL = 'http://localhost:3001'
// export const BASE_URL = 'https://okcampo-api-dev.herokuapp.com'
export const AGROPTIMA_API = 'https://app.agroptima.com/es/api/v9/sigpac/bounds'
export const USER_TOKEN_KEY = 'okcampo-token'
export const BASE_URL = process.env.REACT_APP_BASE_URL
export const MAP_ACCESS_TOKEN = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN

export const MULTISELECT_ALL_KEY = '<SELECT_ALL>'
export const SELECT_EMPTY_OPTION = '<EMPTY>'

export const CATEGORIES_KEY = 'categories'
export const SLUGS_LEVEL_1 = ['agriculture', 'cattle-raising', 'users', 'benja']
export const SLUGS_LEVEL_2 = [
	'holdings',
	'machinery',
	'staff',
	'customers',
	'jobs',
	'warehouses',
	'harvests'
]
export const ADMIN_PROTECTED_ROUTES = ['users']
export const SUPERADMIN_PROTECTED_ROUTES = ['accounts', 'access-list']
export const ACTIVITIES_FILTERED_BY_HOLDINGS = ['plots', 'harvests']
export const ACTIVITIES_VARIABLES_WITH_UNITS = [
	'fertilizers',
	'seeds',
	'phytosanitaries'
]
export const ACTIVITIES_VARIABLES_EDIT = [
	'fertilizers',
	'harvests',
	'seeds',
	'phytosanitaries'
]
export const ACTIVITIES_JOBS_TO_REMOVE_WHEN_UNIQUE_ACTIVE = [
	'fertilizers',
	'harvests',
	'seeds',
	'quimic',
	'plots',
	'plots_groups'
]
export const ACTIVITIES_JOBS_TO_REMOVE_WHEN_NOT_UNIQUE_ACTIVE = [
	'warehouses',
	'machinery',
	'phytosanitaries',
	'seeds'
]
export const NUMBER_PARSE_EXCEPTIONS = [
	'NIF',
	'nif',
	'id',
	'text',
	'year',
	'registration',
	'password',
	'inscription_roma',
	'model',
	'n_rgseaa',
	'warehouse',
	'dni_cif'
]

export const PROMPT_STORAGE_KEY = 'okcampo-prompt-status'

export const MAP_DEFAULTS = {
	promoteId: 'code',
	globalSource: 'global',
	plotsSource: 'plots',
	lng: -3.70379,
	lat: 40.416775,
	zoom: 5.5,
	minZoom: 6,
	maxZoom: 17,
	minZoomFetch: 13.5,
	maxBounds: [
		[-28, 25],
		[22, 52]
	],
	activeStyleType: 'satelite',
	activeCampaign: new Date().getFullYear().toString(),
	activeFilters: []
}

export const MAP_MULTISELECT_TOOLS = ['activities', 'campaigns', 'groups']

export const MAP_TOOLS = {
	ACTIVITIES: 'activities',
	DRAW: 'draw',
	CAMPAIGN: 'campaign'
}

export const MAP_TILESET_MIN_ZOOM = 14.3
export const MAP_DRAW_STYLES = [
	{
		id: 'gl-draw-polygon-fill-inactive',
		type: 'fill',
		filter: [
			'all',
			['==', 'active', 'false'],
			['==', '$type', 'Polygon'],
			['!=', 'mode', 'static']
		],
		paint: {
			'fill-color': '#f8f8f8',
			'fill-outline-color': '#f8f8f8',
			'fill-opacity': 0.1
		}
	},
	{
		id: 'gl-draw-polygon-fill-active',
		type: 'fill',
		filter: ['all', ['==', 'active', 'true'], ['==', '$type', 'Polygon']],
		paint: {
			'fill-color': '#a5c731',
			'fill-outline-color': '#a5c731',
			'fill-opacity': 0.5
		}
	},
	{
		id: 'gl-draw-polygon-midpoint',
		type: 'circle',
		filter: ['all', ['==', '$type', 'Point'], ['==', 'meta', 'midpoint']],
		paint: {
			'circle-radius': 4,
			'circle-color': '#fff'
		}
	},
	{
		id: 'gl-draw-polygon-stroke-inactive',
		type: 'line',
		filter: [
			'all',
			['==', 'active', 'false'],
			['==', '$type', 'Polygon'],
			['!=', 'mode', 'static']
		],
		layout: {
			'line-cap': 'round',
			'line-join': 'round'
		},
		paint: {
			'line-color': '#f8f8f8',
			'line-width': 2
		}
	},
	{
		id: 'gl-draw-polygon-stroke-active',
		type: 'line',
		filter: ['all', ['==', 'active', 'true'], ['==', '$type', 'Polygon']],
		layout: {
			'line-cap': 'round',
			'line-join': 'round'
		},
		paint: {
			'line-color': '#fff',
			// 'line-dasharray': [0.2, 2],
			'line-width': 2
		}
	},
	{
		id: 'gl-draw-line-inactive',
		type: 'line',
		filter: [
			'all',
			['==', 'active', 'false'],
			['==', '$type', 'LineString'],
			['!=', 'mode', 'static']
		],
		layout: {
			'line-cap': 'round',
			'line-join': 'round'
		},
		paint: {
			'line-color': '#f8f8f8',
			'line-width': 2
		}
	},
	{
		id: 'gl-draw-line-active',
		type: 'line',
		filter: ['all', ['==', '$type', 'LineString'], ['==', 'active', 'true']],
		layout: {
			'line-cap': 'round',
			'line-join': 'round'
		},
		paint: {
			'line-color': '#a5c731',
			// 'line-dasharray': [0.2, 2],
			'line-width': 2
		}
	},
	{
		id: 'gl-draw-polygon-and-line-vertex-stroke-inactive',
		type: 'circle',
		filter: [
			'all',
			['==', 'meta', 'vertex'],
			['==', '$type', 'Point'],
			['!=', 'mode', 'static']
		],
		paint: {
			'circle-radius': 8,
			'circle-color': '#fff'
		}
	},
	{
		id: 'gl-draw-polygon-and-line-vertex-inactive',
		type: 'circle',
		filter: [
			'all',
			['==', 'meta', 'vertex'],
			['==', '$type', 'Point'],
			['!=', 'mode', 'static']
		],
		paint: {
			'circle-radius': 6,
			'circle-color': '#a5c731'
		}
	},
	{
		id: 'gl-draw-point-point-stroke-inactive',
		type: 'circle',
		filter: [
			'all',
			['==', 'active', 'false'],
			['==', '$type', 'Point'],
			['==', 'meta', 'feature'],
			['!=', 'mode', 'static']
		],
		paint: {
			'circle-radius': 8,
			'circle-opacity': 1,
			'circle-color': '#fff'
		}
	},
	{
		id: 'gl-draw-point-inactive',
		type: 'circle',
		filter: [
			'all',
			['==', 'active', 'false'],
			['==', '$type', 'Point'],
			['==', 'meta', 'feature'],
			['!=', 'mode', 'static']
		],
		paint: {
			'circle-radius': 6,
			'circle-color': '#f8f8f8'
		}
	},
	{
		id: 'gl-draw-point-stroke-active',
		type: 'circle',
		filter: [
			'all',
			['==', '$type', 'Point'],
			['==', 'active', 'true'],
			['!=', 'meta', 'midpoint']
		],
		paint: {
			'circle-radius': 5,
			'circle-color': '#fff'
		}
	},
	{
		id: 'gl-draw-point-active',
		type: 'circle',
		filter: [
			'all',
			['==', '$type', 'Point'],
			['!=', 'meta', 'midpoint'],
			['==', 'active', 'true']
		],
		paint: {
			'circle-radius': 8,
			'circle-color': '#fff'
		}
	},
	{
		id: 'gl-draw-polygon-fill-static',
		type: 'fill',
		filter: ['all', ['==', 'mode', 'static'], ['==', '$type', 'Polygon']],
		paint: {
			'fill-color': '#404040',
			'fill-outline-color': '#404040',
			'fill-opacity': 0.8
		}
	},
	{
		id: 'gl-draw-polygon-stroke-static',
		type: 'line',
		filter: ['all', ['==', 'mode', 'static'], ['==', '$type', 'Polygon']],
		layout: {
			'line-cap': 'round',
			'line-join': 'round'
		},
		paint: {
			'line-color': '#404040',
			'line-width': 2
		}
	},
	{
		id: 'gl-draw-line-static',
		type: 'line',
		filter: ['all', ['==', 'mode', 'static'], ['==', '$type', 'LineString']],
		layout: {
			'line-cap': 'round',
			'line-join': 'round'
		},
		paint: {
			'line-color': '#404040',
			'line-width': 2
		}
	},
	{
		id: 'gl-draw-point-static',
		type: 'circle',
		filter: ['all', ['==', 'mode', 'static'], ['==', '$type', 'Point']],
		paint: {
			'circle-radius': 8,
			'circle-color': '#404040'
		}
	}
]

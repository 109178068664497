import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'
import { subscribeWithSelector } from 'zustand/middleware'

import pipe from 'ramda/es/pipe'

import initialState from './initialState'
import { log } from './middlewares'
import { createSelectors } from './utils'

export const createStore = pipe(log, immer, subscribeWithSelector, create)

const useStore = createStore((set, get) => ({
	...initialState,
	set: fn => set(fn),
	get: fn => get(fn)
}))

export default createSelectors(useStore)

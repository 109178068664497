import React, { useEffect } from 'react'
import { ReactQueryDevtools } from 'react-query/devtools'
import { useLocation } from 'react-router-dom'

import { useAuth } from '@context/auth'
import { useUser } from '@hooks/user'

import LayoutMain from '@layout/LayoutMain'
import { RouterApp } from './router'

const App = () => {
	const location = useLocation()
	const { token, logout } = useAuth()
	const { data: user, status } = useUser({
		enabled: !!token
	})

	useEffect(() => {
		if (location.state?.logout) {
			logout()
		}
	}, [location, logout])

	return (
		<LayoutMain>
			<RouterApp authenticated={!!user?.token} status={status} />
			{process.env.NODE_ENV !== 'production' && (
				<ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
			)}
		</LayoutMain>
	)
}

export default App

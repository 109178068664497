import React, { useCallback } from 'react'
import styled from 'styled-components/macro'
import { Link } from 'react-router-dom'

import yup from '@yup'

import useActivities from '@hooks/activities'

import { Text } from '@components/text'
import { Form, TextField } from '@components/form'
import { Button } from '@components/button'
import { Grid, GridItem } from '@components/grid'
import { Flex } from '@components/flex'

const StyledActivitiesPanelNewHarvest = styled.div`
	width: 100%;
	height: 100%;
`

const schema = yup.object().shape({
	title: yup.string().required()
})

const ActivitiesPanelNewHarvestInner = () => {
	return (
		<Grid>
			<GridItem columnStart="span 12">
				<TextField
					name="title"
					placeholder="Escribe el nombre"
					label="Nombre *"
					inverted
				/>
			</GridItem>
			<GridItem columnStart="1" columnEnd="-1">
				<Flex justifyContent="flex-end" alignItems="center">
					<Button
						label={'Guardar'}
						type="submit"
						theme="primary"
						size="large"
					/>
				</Flex>
			</GridItem>
			<GridItem columnStart="1" columnEnd="-1">
				<Flex justifyContent="center">
					<Text>
						<p>
							<strong>Consejo: </strong>
							Para introducir más detalles haz{' '}
							<Link to="/agriculture/harvests" target="_blank">
								click aquí
							</Link>
						</p>
					</Text>
				</Flex>
			</GridItem>
		</Grid>
	)
}

const DialogActivitiesNewHarvest = ({ item, closeModal }) => {
	const { useActivitiesCreateChild } = useActivities()

	const { mutateAsync, status: statusCreate } = useActivitiesCreateChild(
		item.id,
		{
			url: 'info/activities/harvests'
		}
	)

	const handleSubmit = async data => {
		console.log('submit', data)

		await mutateAsync(data)

		closeModal()
	}

	const handleError = useCallback(error => {
		console.log(error)
	}, [])

	return (
		<StyledActivitiesPanelNewHarvest>
			<Form
				onSubmit={handleSubmit}
				onError={handleError}
				loading={statusCreate === 'loading'}
				schema={schema}
				hasPrompt={false}
			>
				<ActivitiesPanelNewHarvestInner />
			</Form>
		</StyledActivitiesPanelNewHarvest>
	)
}

export default DialogActivitiesNewHarvest

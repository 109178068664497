import React, { useMemo } from 'react'

import { ReactComponent as ActivitiesIcon } from '@svgs/activities-color.svg'
import { ReactComponent as CommentsIcon } from '@svgs/comments.svg'
import { ReactComponent as CustomersIcon } from '@svgs/customers.svg'
import { ReactComponent as DateIcon } from '@svgs/date.svg'
import { ReactComponent as FertilizersIcon } from '@svgs/fertilizers.svg'
import { ReactComponent as FiltersIcon } from '@svgs/filters.svg'
import { ReactComponent as HarvestsIcon } from '@svgs/harvests.svg'
import { ReactComponent as HoldingsIcon } from '@svgs/holdings.svg'
import { ReactComponent as JobsIcon } from '@svgs/jobs.svg'
import { ReactComponent as MachineryIcon } from '@svgs/machinery.svg'
import { ReactComponent as PhytosanitariesIcon } from '@svgs/phytosanitaries.svg'
import { ReactComponent as PlotsIcon } from '@svgs/plots.svg'
import { ReactComponent as ProductsIcon } from '@svgs/products.svg'
import { ReactComponent as QuimicIcon } from '@svgs/quimic.svg'
import { ReactComponent as SeedsIcon } from '@svgs/seeds.svg'
import { ReactComponent as StaffIcon } from '@svgs/staff.svg'
import { ReactComponent as StarIcon } from '@svgs/star.svg'
import { ReactComponent as TimeIcon } from '@svgs/time.svg'
import { ReactComponent as WarehousesIcon } from '@svgs/warehouses.svg'
import { ReactComponent as WheatIcon } from '@svgs/wheat.svg'
import { ReactComponent as CloseIcon } from '@svgs/close.svg'

const Icons = ({ id }) => {
	const render = useMemo(() => {
		switch (id) {
			case 'activities':
				return <ActivitiesIcon />
			case 'comments':
				return <CommentsIcon />
			case 'customers':
				return <CustomersIcon />
			case 'date':
				return <DateIcon />
			case 'fertilizers':
				return <FertilizersIcon />
			case 'filters':
				return <FiltersIcon />
			case 'harvests':
				return <HarvestsIcon />
			case 'holdings':
				return <HoldingsIcon />
			case 'jobs':
				return <JobsIcon />
			case 'machinery':
				return <MachineryIcon />
			case 'phytosanitaries':
				return <PhytosanitariesIcon />
			case 'plots':
				return <PlotsIcon />
			case 'products':
				return <ProductsIcon />
			case 'quimic':
				return <QuimicIcon />
			case 'seeds':
				return <SeedsIcon />
			case 'staff':
				return <StaffIcon />
			case 'star':
				return <StarIcon />
			case 'time':
				return <TimeIcon />
			case 'warehouses':
				return <WarehousesIcon />
			case 'wheat':
				return <WheatIcon />
			case 'close':
				return <CloseIcon />
			default:
				return null
		}
	}, [id])

	return render
}

export default Icons

export {
	ActivitiesIcon,
	CommentsIcon,
	CustomersIcon,
	DateIcon,
	FertilizersIcon,
	FiltersIcon,
	HarvestsIcon,
	HoldingsIcon,
	JobsIcon,
	MachineryIcon,
	PhytosanitariesIcon,
	PlotsIcon,
	ProductsIcon,
	QuimicIcon,
	SeedsIcon,
	StaffIcon,
	TimeIcon,
	WarehousesIcon,
	WheatIcon
}

import React, { useState } from 'react'
import styled, { css } from 'styled-components/macro'

import {
	Dropdown as DropdownComponent,
	DropdownPanel
} from '@components/dropdown'

const StyledDropdownTrigger = styled.div`
	width: 25px;
	height: 20px;
	color: var(--c__grey-500);
	position: relative;
	cursor: pointer;
	transition: all 150ms ease-out;

	${({ $opened }) =>
		!!$opened &&
		css`
			color: var(--c__grey-700);
		`}

	${({ $isFeat }) =>
		!!$isFeat &&
		css`
			color: var(--c__green);
		`}
    
	${({ $opened, $isFeat }) =>
		!!$isFeat &&
		!!$opened &&
		css`
			color: var(--c__green-600);
		`}

	&:hover {
		opacity: 0.7;
	}

	svg {
		width: 100%;
		height: 100%;
		fill: currentColor;
	}
`

function Dropdown({ children, icon, isFeat = false, ...restProps }) {
	const [opened, setOpened] = useState(false)

	return (
		<DropdownComponent
			opened={opened}
			position="bottom-right"
			width={200}
			onClose={() => {
				!!opened && setOpened(false)
			}}
			trigger={() => (
				<StyledDropdownTrigger
					$opened={opened}
					$isFeat={isFeat}
					onClick={e => setOpened(!opened)}
				>
					{icon}
				</StyledDropdownTrigger>
			)}
			{...restProps}
		>
			<DropdownPanel>{children}</DropdownPanel>
		</DropdownComponent>
	)
}

export default Dropdown

import React from 'react'
import styled from 'styled-components/macro'

import { Spinner } from '@components/preloaders'

const StyledCardEmpty = styled.aside`
	width: 100%;
	display: flex;
	justify-content: center;

	& > div {
		width: 100%;
		max-width: 600px;
		background-color: var(--c__white);
		border-radius: 10px;
		padding: 40px 20px;
		display: flex;
		flex-flow: column;
		align-items: center;
		gap: 20px;
		color: var(--c__grey-700);
		box-shadow: var(--dashboard__shadow);

		h3 {
			font-size: 26px;
			font-weight: 700;
			text-align: center;
			line-height: 1.2;
		}

		p {
			font-size: 16px;
			text-align: center;
			max-width: 500px;
		}

		footer {
			display: flex;
			justify-content: center;
			margin-top: 20px;
		}
	}
`
function CardEmpty({ title = null, description = null, footer = null }) {
	return (
		<StyledCardEmpty>
			<div>
				{!!title && <h3>{title}</h3>}
				{!!description && <p>{description}</p>}
				{!!footer && <footer>{footer}</footer>}
			</div>
		</StyledCardEmpty>
	)
}

export default CardEmpty

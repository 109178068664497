import React, { useRef } from 'react'
import styled from 'styled-components'
import classNames from 'classnames'
import { motion, AnimatePresence } from 'framer-motion'

const StyledSidePanelOverlay = styled(motion.aside)`
	width: 100%;
	height: 100%;
	position: fixed;
	overflow: hidden;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 99998;
	outline: medium none;
	background-color: var(--sidepanel__overlay-background);
	backface-visibility: hidden;
	transform-style: preserve-3d;
	perspective: 1200px;
`

function SidePanelOverlay({ isOpened = false, dismissable = false }) {
	const overlay = useRef()

	const variants = {
		initial: {
			opacity: 0
		},
		open: {
			opacity: 1,
			transition: {
				duration: 0.2
			}
		},
		close: {
			opacity: 0,
			transition: {
				duration: 0.1
			}
		}
	}

	return (
		<AnimatePresence>
			{isOpened && (
				<StyledSidePanelOverlay
					ref={overlay}
					id="sidepanel-overlay"
					className={classNames({
						sidepanel__overlay: true,
						'sidepanel__overlay--dismissable': !!dismissable
					})}
					initial={variants.initial}
					animate={variants.open}
					exit={variants.close}
				/>
			)}
		</AnimatePresence>
	)
}

export default SidePanelOverlay

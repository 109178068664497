import { useCallback } from 'react'

import { useModal } from '@components/modal'

export default function useDialogs() {
	const modalPrompt = useModal('dialog-prompt')
	const modalSigpacSearch = useModal('dialog-sigpac-search')
	const modalImportPlotsStatus = useModal('dialog-import-plots-status')
	const modalSigpacPlotsSearch = useModal('dialog-sigpac-plots-search')
	const modalMapNewPlot = useModal('dialog-map-new-plot')
	const modalActivitiesNewJob = useModal('dialog-activities-new-job')
	const modalActivitiesNewWorker = useModal('dialog-activities-new-worker')
	const modalActivitiesNewMachine = useModal('dialog-activities-new-machine')
	const modalActivitiesNewSeed = useModal('dialog-activities-new-seed')
	const modalActivitiesNewFertilizer = useModal(
		'dialog-activities-new-fertilizer'
	)
	const modalActivitiesNewHarvest = useModal('dialog-activities-new-harvest')
	const modalActivitiesNewWarehouse = useModal(
		'dialog-activities-new-warehouse'
	)
	const modalActivitiesNewPlotsGroup = useModal(
		'dialog-activities-new-plots-group'
	)
	const modalMachineryTask = useModal('dialog-machinery-task')
	const modalCampaign = useModal('dialog-campaign')
	const modalMapAddCampaign = useModal('dialog-map-add-campaign')
	const modalMapNewHarvest = useModal('dialog-map-new-harvest')
	const modalMapNewGroup = useModal('dialog-map-new-group')
	const modalMapNewActivity = useModal('dialog-map-new-activity')
	const modalAlert = useModal('dialog-alert')
	const modalCostsAddPrice = useModal('dialog-costs-add-price')
	const modalCostWhereApply = useModal('dialog-costs-where-apply')

	// Router Prompt
	const createModalPrompt = useCallback(
		props => {
			modalPrompt.openModal({ headerTitle: `Cambios sin guardar`, ...props })
		},
		[modalPrompt]
	)
	// Sigpac
	const createSigpacSearchDialog = useCallback(
		panel => {
			modalSigpacSearch.openModal({
				headerTitle: 'Buscar recinto Sigpac',
				...panel
			})
		},
		[modalSigpacSearch]
	)
	const createSigpacPlotsSearchDialog = useCallback(
		panel => {
			modalSigpacPlotsSearch.openModal({
				headerTitle: 'Buscar recinto Sigpac',
				...panel
			})
		},
		[modalSigpacPlotsSearch]
	)

	// Crear nueva parcela desde el mapa
	const createMapNewPlot = useCallback(
		panel => {
			modalMapNewPlot.openModal({
				headerTitle: panel?.isCreating ? 'Crear parcela' : 'Editar parcela',
				...panel
			})
		},
		[modalMapNewPlot]
	)

	const createImportPlotsStatus = useCallback(
		data => {
			modalImportPlotsStatus.openModal({
				headerTitle: 'Estado de la importación de parcelas',
				data
			})
		},
		[modalImportPlotsStatus]
	)

	const createActivitiesNewJob = useCallback(
		item => {
			modalActivitiesNewJob.openModal({
				headerTitle: `Crear ${item.title.singular}`,
				item
			})
		},
		[modalActivitiesNewJob]
	)

	const createActivitiesNewWorker = useCallback(
		item => {
			modalActivitiesNewWorker.openModal({
				headerTitle: `Crear ${item.title.singular}`,
				item
			})
		},
		[modalActivitiesNewWorker]
	)

	const createActivitiesNewMachine = useCallback(
		item => {
			modalActivitiesNewMachine.openModal({
				headerTitle: `Crear ${item.title.singular}`,
				item
			})
		},
		[modalActivitiesNewMachine]
	)

	const createActivitiesNewSeed = useCallback(
		item => {
			modalActivitiesNewSeed.openModal({
				headerTitle: `Crear ${item.title.singular}`,
				item
			})
		},
		[modalActivitiesNewSeed]
	)

	const createActivitiesNewFertilizer = useCallback(
		item => {
			modalActivitiesNewFertilizer.openModal({
				headerTitle: `Crear ${item.title.singular}`,
				item
			})
		},
		[modalActivitiesNewFertilizer]
	)

	const createActivitiesNewHarvest = useCallback(
		item => {
			modalActivitiesNewHarvest.openModal({
				headerTitle: `Crear ${item.title.singular}`,
				item
			})
		},
		[modalActivitiesNewHarvest]
	)

	const createActivitiesNewWarehouse = useCallback(
		(item, parent) => {
			modalActivitiesNewWarehouse.openModal({
				headerTitle: `Crear almacén`,
				item,
				parent
			})
		},
		[modalActivitiesNewWarehouse]
	)

	const createActivitiesNewPlotsGroup = useCallback(
		(item, parent) => {
			modalActivitiesNewPlotsGroup.openModal({
				headerTitle: `Crear grupo de parcelas`,
				item,
				parent
			})
		},
		[modalActivitiesNewPlotsGroup]
	)

	const createMachineryTask = useCallback(
		(item, parent) => {
			modalMachineryTask.openModal({
				headerTitle: item?.isEdit ? item?.data?.title : `Tarea de la máquina`,
				item,
				parent
			})
		},
		[modalMachineryTask]
	)

	const createCampaign = useCallback(
		item => {
			modalCampaign.openModal({
				headerTitle: item?.isEdit ? item?.data?.title : `Campaña`,
				item
			})
		},
		[modalCampaign]
	)

	const createMapAddCampaign = useCallback(
		item => {
			modalMapAddCampaign.openModal({
				headerTitle: 'Añadir campaña',
				...item
			})
		},
		[modalMapAddCampaign]
	)

	const createMapNewHarvest = useCallback(
		item => {
			modalMapNewHarvest.openModal({
				headerTitle: 'Crear cosecha',
				...item
			})
		},
		[modalMapNewHarvest]
	)

	const createMapNewGroup = useCallback(
		item => {
			modalMapNewGroup.openModal({
				headerTitle: 'Crear grupo de parcelas',
				...item
			})
		},
		[modalMapNewGroup]
	)

	const createMapNewActivity = useCallback(
		item => {
			modalMapNewActivity.openModal({
				headerTitle: 'Crear nueva actividad',
				...item
			})
		},
		[modalMapNewActivity]
	)

	const createAlert = useCallback(
		item => {
			modalAlert.openModal({
				headerTitle: 'Crear alerta',
				item
			})
		},
		[modalAlert]
	)

	const createCostsAddPrice = useCallback(
		data => {
			modalCostsAddPrice.openModal({
				headerTitle: data?.title,
				data
			})
		},
		[modalCostsAddPrice]
	)

	const createCostsWhereApply = useCallback(
		data => {
			modalCostWhereApply.openModal({
				headerTitle: data?.title,
				data
			})
		},
		[modalCostWhereApply]
	)

	return {
		createModalPrompt,
		createSigpacSearchDialog,
		createSigpacPlotsSearchDialog,
		createImportPlotsStatus,
		createMapNewPlot,
		createActivitiesNewJob,
		createActivitiesNewWorker,
		createActivitiesNewMachine,
		createActivitiesNewSeed,
		createActivitiesNewFertilizer,
		createActivitiesNewHarvest,
		createActivitiesNewWarehouse,
		createActivitiesNewPlotsGroup,
		createMachineryTask,
		createCampaign,
		createMapAddCampaign,
		createMapNewHarvest,
		createMapNewGroup,
		createMapNewActivity,
		createAlert,
		createCostsAddPrice,
		createCostsWhereApply
	}
}

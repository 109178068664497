import React from 'react'
import { Preloader, Oval } from 'react-preloader-icon'

function Spinner(props) {
	return (
		<Preloader
			use={Oval}
			size={32}
			strokeWidth={15}
			strokeColor="#fff"
			duration={600}
			{...props}
		/>
	)
}

export default Spinner

import { useCallback } from 'react'
import { useQueries } from 'react-query'
import * as turf from '@turf/turf'

import { MAP_DEFAULTS, BASE_URL } from '@constants'

import useMapStore from '@map/store/map'
import useMapPlaceStore from '@map/store/place'
import useMapStateStore from '@map/store/state'

import useCRUD from '@hooks/crud'
import useAjax from '@hooks/ajax'
import useStorage from '@hooks/storage'
import useMapbox from '@hooks/mapbox'
import useMapTools from '@map/hooks/tools'
import { useMapContext } from '@map/context'
import { useNotifications } from '@notifications'

function useMap() {
	const { map, container } = useMapContext()
	const { drawEnclosure, addPlotMap, removePlotMap } = useMapbox()
	const { notification } = useNotifications()

	const [mapSettings, setMapSettings] = useStorage('okcampo-map-settings', {
		lng: MAP_DEFAULTS.lng,
		lat: MAP_DEFAULTS.lat,
		zoom: MAP_DEFAULTS.zoom,
		// activeStyleType: MAP_DEFAULTS.activeStyleType,
		activeCampaign: MAP_DEFAULTS.activeCampaign,
		activeFilters: MAP_DEFAULTS.activeFilters
	})

	const set = useMapStore.use.set()
	const campaigns = useMapStore.use.campaigns()
	const filters = useMapStore.use.filters()
	const styles = useMapStore.use.styles()

	const updatePlace = useMapPlaceStore.use.updateState()

	const setPlots = useMapStateStore.use.setPlots()
	const plots = useMapStateStore.use.plots()
	const currentZoom = useMapStateStore.use.currentZoom()

	const { parsePlotsColorsByFilters } = useMapTools()

	const getMapStylesLayers = useCallback(() => styles.layers, [styles])
	const getMapStylesTypes = useCallback(() => styles.types, [styles])
	const getMapStyleTypeActive = useCallback(
		() => styles.types.filter(style => !!style.active)[0],
		[styles]
	)

	const getLocalStorageActiveFilters = useCallback(
		() =>
			mapSettings.activeFilters.map(activeFilter => ({
				...activeFilter,
				filter_item_id: activeFilter.id
			})),
		[mapSettings]
	)

	const drawSigpacEnclosure = useCallback(
		(geometry, code) => {
			const layer = {
				type: 'fill',
				layout: {},
				paint: {
					'fill-color': `#FFFFFF`, // blue color fill
					'fill-opacity': 0.5
				}
			}
			drawEnclosure(map, geometry, `source-${code}`, `layer-${code}`, layer)
		},
		[map]
	)

	// localStorage Map Settings
	const saveMapSettings = useCallback(
		settings => {
			setMapSettings({ ...mapSettings, ...settings })
		},
		[mapSettings, setMapSettings]
	)

	const getMapSettings = useCallback(() => mapSettings, [mapSettings])

	const getMapCampaigns = useCallback(() => campaigns, [campaigns])

	// filters
	const getMapFilters = useCallback(() => filters, [filters])

	const getMapFiltersItemById = useCallback(
		id => filters.find(item => item.id === id),
		[filters]
	)

	const getMapFiltersItemCount = useCallback(
		id => {
			const filter = getMapFiltersItemById(id)
			return filter ? filter.items.length : 0
		},
		[getMapFiltersItemById]
	)

	const getMapFiltersActives = useCallback(() => {
		let out = []

		if (!filters) return false
		if (!filters.length) return false

		filters.forEach(filter => {
			filter.items.forEach(item => {
				if (item.active) {
					out = [...out, item]
				}
			})
		})

		return out
	}, [filters])

	const getMapFiltersItems = useCallback(() => {
		let out = []

		if (!filters) return false
		if (!filters.length) return false

		filters.forEach(filter => {
			filter.items.forEach(item => {
				out = [...out, item]
			})
		})

		return out
	}, [filters])

	const hasMapFiltersActives = useCallback(() => {
		const actives = getMapFiltersActives()
		return actives ? !!actives.length : false
	}, [getMapFiltersActives])

	const getMapFiltersItemActives = useCallback(
		id => {
			const filter = getMapFiltersItemById(id)
			return filter ? filter.items.filter(({ active }) => !!active) : null
		},
		[getMapFiltersItemById]
	)

	const getMapFiltersItemTitle = useCallback(
		id => {
			const filter = getMapFiltersItemById(id)
			const count = getMapFiltersItemCount(id)
			return count === 1 ? filter?.name.singular : filter?.name.plural
		},
		[getMapFiltersItemById, getMapFiltersItemCount]
	)

	const changeActiveFilters = useCallback(() => {
		const activeFilters = getMapFiltersActives()?.map(({ id, plot_id }) => ({
			id,
			plot_id
		}))

		saveMapSettings({
			activeFilters
		})

		return activeFilters
	}, [getMapFiltersActives])

	const emptyMapFiltersItem = useCallback(() => {
		set(state => {
			state.filters = state.filters?.map(filter => ({
				...filter,
				items: filter.items?.map(item => ({
					...item,
					active: false
				}))
			}))
			state.plots = state.plots.map(plot => ({
				...plot,
				filters: plot.filters.map(filter => {
					removePlotMap(map, {
						filter_item_id: filter.id,
						enclosures: plot.items.map(({ id }) => id)
					})
					return { ...filter, active: false }
				})
			}))
		})

		saveMapSettings({ activeFilters: [] })
	}, [saveMapSettings])

	const changeMapActiveCampaign = useCallback(
		year => {
			emptyMapFiltersItem()

			saveMapSettings({ activeCampaign: year })
		},
		[saveMapSettings, emptyMapFiltersItem]
	)

	// campaigns
	const toggleMapCampaigns = useCallback(year => {
		set(state => {
			state.campaigns = state.campaigns.map(item => ({
				...item,
				active: item.year === year
			}))
		})
	}, [])

	const toggleMapFiltersItem = useCallback(
		({ filterId, id }) => {
			set(state => {
				state.filters = state.filters.map(filter =>
					filter.id === filterId
						? {
								...filter,
								items: filter.items.map(item => {
									if (item.id === id) {
										return { ...item, active: !item.active }
									} else return item
								})
						  }
						: filter
				)
			})
		},
		[mapSettings, getMapFiltersActives]
	)

	const toggleMapPlot = useCallback(
		({ plot_id, filter_item_id }) => {
			let newPlot
			set(state => {
				state.plots = state.plots.map(plot => {
					if (plot.id === plot_id) {
						newPlot = {
							...plot,
							filters: plot.filters.map(filter =>
								filter.id === filter_item_id
									? {
											...filter,
											active: !filter.active
									  }
									: filter
							)
						}
						return newPlot
					} else return plot
				})
			})

			return newPlot
		},
		[mapSettings]
	)

	// layers
	const toggleMapStylesLayer = useCallback(
		id => {
			set(({ styles }) => {
				const currentLayer = styles.layers.find(layer => layer.id === id)

				if (currentLayer) {
					currentLayer.active = !currentLayer?.active
				}
			})
		},
		[set]
	)

	const toggleMapStylesType = useCallback(
		id => {
			set(({ styles }) => {
				styles.types.forEach(type => {
					type.active = type.id === id
				})

				saveMapSettings({ activeStyleType: id })
			})
		},
		[set]
	)

	const parseBBoxCoords = useCallback(data => {
		if (!data) return null
		return {
			nelat: data.getNorthEast().lat,
			nelng: data.getNorthEast().lng,
			swlat: data.getSouthWest().lat,
			swlng: data.getSouthWest().lng
		}
	}, [])

	const getBBoxOfTwoPoints = useCallback(data => {
		const coords = {
			nelat: data.getNorthEast().lat,
			nelng: data.getNorthEast().lng,
			swlat: data.getSouthWest().lat,
			swlng: data.getSouthWest().lng
		}

		const pointNE = turf.point([coords.nelng, coords.nelat])
		const pointSW = turf.point([coords.swlng, coords.swlat])
		const bbox = turf.bbox(turf.featureCollection([pointNE, pointSW]))
		const bboxPolygon = turf.bboxPolygon(bbox)

		return bboxPolygon
	}, [])

	const geolocate = useCallback(() => {
		if (container) {
			container.querySelector('.mapboxgl-ctrl-geolocate').click()
		}
	}, [container])

	const getCurrentPosition = useCallback(() => {
		return { ...map.getCenter(), zoom: map.getZoom() }
	}, [map])

	const saveCurrentPosition = useCallback(() => {
		updatePlace(getCurrentPosition())

		notification({
			id: 'saved-geoposition',
			type: 'success',
			title: 'Posición inicial guardada'
		})
	}, [getCurrentPosition, saveMapSettings, notification])

	// queries
	const useMapProvinces = () => {
		const { useRead } = useCRUD({
			baseKey: ['map', 'provinces'],
			url: 'map/provinces'
		})
		return useRead()
	}

	const useMapCampaigns = () => {
		const baseKey = ['map', 'campaigns']

		const { useRead } = useCRUD({
			baseKey,
			url: 'map/campaigns'
		})
		return useRead({
			config: {
				staleTime: Infinity,
				refetchOnWindowFocus: false,
				onSuccess: data => {
					set(state => {
						state.campaigns = data.map(item => ({
							...item,
							active: item.year === mapSettings.activeCampaign
						}))
					})
				}
			}
		})
	}

	const useMapCampaignsYear = year => {
		const baseKey = ['map', 'campaigns', { year }]
		const { activeFilters } = mapSettings
		const activeFiltersIds = activeFilters.length
			? activeFilters.map(({ id }) => id)
			: []

		const { useRead } = useCRUD({
			baseKey,
			url: 'map/campaigns'
		})

		return useRead({
			config: {
				refetchOnWindowFocus: false,
				staleTime: Infinity,
				onSuccess: data => {
					if (data) {
						if (parseInt(mapSettings.activeCampaign) !== year) {
							changeMapActiveCampaign(year)
						}

						set(state => {
							state.campaigns = state.campaigns.map(item => ({
								...item,
								active: item.year === year
							}))

							state.filters = data.map(filter => ({
								...filter,
								items: filter.items.map(item => {
									return {
										...item,
										active: activeFiltersIds.find(id => id === item.id)
									}
								})
							}))
						})
					}

					return Promise.resolve(data)
				}
			},
			ajax: {
				params: {
					year
				}
			}
		})
	}

	const useMapCampaignsFilter = ({ plot_id, filter_item_id }) => {
		const baseKey = ['map', 'campaigns', 'filter', { plot_id }]

		const { useRead } = useCRUD({
			baseKey,
			url: 'map/campaigns/filter'
		})

		return useRead({
			config: {
				enabled: !!plot_id,
				onSuccess: data => {
					let plotActive = []
					set(state => {
						const filtersItems = []

						state.filters.forEach(filter =>
							filter.items.forEach(item => {
								if (item.plot_id === plot_id)
									filtersItems.push({
										id: item.id,
										color: item.color,
										active: item.id === filter_item_id
									})
							})
						)

						if (!state.plots.find(plot => plot.id === plot_id)) {
							plotActive = { ...data, filters: filtersItems }
							state.plots.push(plotActive)
						}
					})

					addPlotMap(map, {
						filter_item_id,
						features: plotActive.items,
						color: plotActive?.filters.find(
							filter => filter.id === filter_item_id
						)?.color
					})
				}
			},
			ajax: {
				params: {
					plot_id
				}
			}
		})
	}

	const useMapCampaignsFilterCollection = plots => {
		const { get } = useAjax()

		const queryFn = useCallback(
			options => get(`${BASE_URL}/map/campaigns/filter`, options?.ajax),
			[get]
		)

		return useQueries(
			!plots.length
				? []
				: plots.map(plot => {
						const queryKey = ({ plot_id }) => [
							'map',
							'campaigns',
							'filter',
							{ plot_id }
						]
						const config = {
							onSuccess: data => {
								let plotActive = []
								set(state => {
									const filtersItems = []

									state.filters.forEach(filter => {
										filter.items.forEach(item => {
											if (item.plot_id === plot.plot_id)
												filtersItems.push({
													id: item.id,
													color: item.color,
													active: item.id === plot.filter_item_id
												})
										})
									})

									if (
										!state.plots.find(
											plotFinded => plotFinded.id === plot.plot_id
										)
									) {
										plotActive = { ...data, filters: filtersItems }
										state.plots.push(plotActive)
									}
								})

								addPlotMap(map, {
									filter_item_id: plot.filter_item_id,
									features: plotActive.items,
									color: plotActive?.filters.find(
										filter => filter.id === plot.filter_item_id
									)?.color
								})
							}
						}
						return {
							queryKey,
							queryFn: () =>
								queryFn({
									ajax: {
										params: {
											plot_id: plot.plot_id
										}
									}
								}),
							...config
						}
				  })
		)
	}

	const useMapTileset = params => {
		const baseKey = ['map', 'features']

		const { useRead } = useCRUD({
			baseKey,
			url: 'map/features'
		})

		return useRead({
			config: {
				enabled: false,
				refetchOnWindowFocus: false,
				staleTime: 0,
				cacheTime: 0
			},
			ajax: {
				params: { ...params, zoom: currentZoom },
				filterResponse: data => {
					// filtramos los recintos quitando los plots del user para que no interfieran en los eventos de los tooltip
					if (data?.features) {
						const plotsIds = plots?.features
							? plots?.features?.map(({ id }) => id)
							: []

						return {
							...data,
							features: data?.features?.filter(
								({ id }) => !plotsIds.includes(id)
							)
						}
					}

					return (
						data || {
							type: 'FeatureCollection',
							features: []
						}
					)
				}
			}
		})
	}

	const useMapPlots = (params = {}) => {
		const baseKey = ['map', 'plots']

		const { useRead } = useCRUD({
			baseKey,
			url: 'map/plots'
		})

		return useRead({
			config: {
				enabled: false,
				refetchOnWindowFocus: false,
				staleTime: 0,
				cacheTime: 0,
				onSuccess: data => {
					setPlots(parsePlotsColorsByFilters(data))
				}
			},
			ajax: {
				params
			}
		})
	}

	const useMapPlotCustom = id => {
		const baseKey = ['map', 'plots', id]

		const { useRead } = useCRUD({
			baseKey,
			url: `map/plots/${id}`
		})

		return useRead({
			config: {
				enabled: !!id,
				refetchOnWindowFocus: false,
				staleTime: 0,
				cacheTime: 0
			}
		})
	}

	return {
		saveMapSettings,
		getMapSettings,
		toggleMapCampaigns,
		getMapStylesLayers,
		getMapStylesTypes,
		getMapStyleTypeActive,
		getMapCampaigns,
		getLocalStorageActiveFilters,
		getMapFilters,
		getMapFiltersItemById,
		getMapFiltersItemCount,
		getMapFiltersItemActives,
		getMapFiltersItems,
		getMapFiltersItemTitle,
		hasMapFiltersActives,
		toggleMapStylesLayer,
		toggleMapStylesType,
		toggleMapFiltersItem,
		toggleMapPlot,
		changeActiveFilters,
		emptyMapFiltersItem,
		parseBBoxCoords,
		geolocate,
		getCurrentPosition,
		saveCurrentPosition,
		useMapProvinces,
		useMapCampaigns,
		useMapCampaignsYear,
		useMapCampaignsFilter,
		useMapCampaignsFilterCollection,
		useMapTileset,
		useMapPlots,
		useMapPlotCustom,
		drawSigpacEnclosure
	}
}

export default useMap

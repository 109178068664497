import React from 'react'
import { Routes, Route } from 'react-router-dom'

import PrivateRoute from '@router/PrivateRoute'
import PublicRoute from '@router/PublicRoute'
import AuthRouter from '@auth/AuthRouter'
import Dashboard from '@dashboard/Dashboard'

import NotFound from '@not-found/NotFound'

const RouterApp = ({ authenticated = false, status }) => {
	return (
		<Routes>
			<Route
				path="/*"
				element={
					<PrivateRoute authenticated={authenticated} status={status}>
						<Dashboard />
					</PrivateRoute>
				}
			/>
			<Route
				path="/auth/*"
				element={
					<PublicRoute authenticated={authenticated} status={status}>
						<AuthRouter />
					</PublicRoute>
				}
			/>

			<Route path="*" element={<NotFound />} />
		</Routes>
	)
}

export default RouterApp

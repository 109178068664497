import React, { useMemo } from 'react'
import styled, { css } from 'styled-components/macro'

import { useFormContext, useController } from 'react-hook-form'
import Label from './Label'
import ErrorMessage from './ErrorMessage'

import DatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import es from 'date-fns/locale/es'
import dayjs from 'dayjs'
import { ReactComponent as DatepickerIcon } from '@svgs/date.svg'

registerLocale('es', es)

const StyledDatepicker = styled.div`
	.react-datepicker {
		border: none;
		border-radius: var(--border__radius);
		background-color: var(--c__grey-100);
		box-shadow: 0 0px 12px rgba(0, 0, 0, 0.3);
		font-family: var(--font__family);
		font-size: 14px;

		span {
			font-size: 1em;
		}

		&-wrapper {
			width: 100%;
		}

		&__triangle {
			display: none;
		}

		&__current-month {
			font-weight: 900;
			text-transform: uppercase;
			color: var(--c__green);
			font-size: 1.2em;
		}

		&__header {
			border-radius: var(--border__radius) var(--border__radius) 0 0;
			border: none;

			&__dropdown {
				margin-top: 10px;

				& > div > div {
					.react-datepicker__month-read-view--down-arrow,
					.react-datepicker__year-read-view--down-arrow {
						top: 5px;
						margin-left: 30px;
					}
				}
			}
		}

		&__month {
			border-radius: 0 0 var(--border__radius) var(--border__radius);
			background-color: var(--c__white);
			margin: 0;
			padding: 10px;
		}

		&__day {
			margin: 0.3em;
			width: 2.2em;
			line-height: 2em;
			color: var(--c__grey-700);
			border: 1px solid var(--c__white);
			border-radius: 100px !important;
		}

		&__day--selected,
		&__day--in-selecting-range,
		&__day--in-range,
		&__month-text--selected,
		&__month-text--in-selecting-range,
		&__month-text--in-range,
		&__quarter-text--selected,
		&__quarter-text--in-selecting-range,
		&__quarter-text--in-range,
		&__year-text--selected,
		&__year-text--in-selecting-range,
		&__year-text--in-range {
			background-color: var(--c__green);
			border-color: var(--c__green);
			border-radius: 100px;
			color: var(--c__white);

			&:hover {
				background-color: var(--c__green);
				border-color: var(--c__green);
				border-radius: 100px;
			}
		}

		&__day--keyboard-selected,
		&__month-text--keyboard-selected,
		&__quarter-text--keyboard-selected,
		&__year-text--keyboard-selected {
			background-color: var(--c__white);
			border: 1px solid var(--c__grey-400);
			border-radius: 100px;

			&:hover {
				background-color: var(--c__white);
				border: 1px solid var(--c__grey-400);
				border-radius: 100px;
			}
		}

		&__day--today,
		&__month-text--today,
		&__quarter-text--today,
		&__year-text--today {
			font-weight: 900;
		}

		&__year-wrapper {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			gap: 5px;
		}
		&__year-text {
			border-radius: 5px;
			padding: 3px;
			margin: 0;
		}

		&__navigation {
			&--previous {
				top: 7px;
				left: 5px;
			}
			&--next {
				top: 7px;
				right: 5px;
			}
		}

		&__day--disabled {
			opacity: 0.4 !important;
		}
	}
`

const StyledDatepickerInputContainer = styled.div`
	position: relative;

	& > button {
		width: 30px;
		height: 30px;
		position: absolute;
		top: 50%;
		right: 10px;
		transform: translateY(-50%);
		pointer-events: none;
		display: flex;
		align-items: center;
		justify-content: center;
		color: var(--c__green);

		${({ $error }) =>
			!!$error &&
			css`
				color: var(--c__white);
			`}

		svg {
			width: 60%;
			height: 60%;
			fill: currentColor;
		}
	}
`
const StyledDatepickerInput = styled.input`
	width: 100%;
	height: var(--textfield__height);
	line-height: 50px;
	padding: 0 15px;
	font-size: 14px;
	color: var(--c__grey-700);
	border-radius: var(--border__radius--small);
	border: 1px solid var(--c__white);
	background-color: var(--c__white);
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
	outline: none;
	transition: all 150ms ease-out;

	@media (min-width: 768px) {
		font-size: 16px;
	}

	&:focus {
		box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
	}

	${({ $inverted }) =>
		!!$inverted &&
		css`
			border-color: var(--c__grey-200);
			background-color: var(--c__grey-200);
			box-shadow: none;

			&:focus:not([readonly]) {
				box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
			}
		`}

	${({ $error }) =>
		!!$error &&
		css`
			border-color: var(--c__error);
			background-color: var(--c__error);
			color: var(--c__white);

			&::placeholder {
				color: var(--c__white);
			}
		`}
`

const DatepickerInput = React.forwardRef(
	(
		{ inputFormat, inputName, value, onChange, inverted, $error, ...restProps },
		ref
	) => {
		return (
			<StyledDatepickerInputContainer $error={$error}>
				<StyledDatepickerInput
					ref={ref}
					$error={$error}
					value={value}
					$inverted={inverted}
					onChange={onChange}
					{...restProps}
				/>
				<button type="button">
					<DatepickerIcon />
				</button>
			</StyledDatepickerInputContainer>
		)
	}
)

function Datepicker({
	name = '',
	locale = 'es',
	// startDate = new Date(),
	readOnly = false,
	disabled = false,
	inputFormat = 'DD/MM/YYYY',
	disabledKeyboardNavigation = true,
	showMonthDropdown = true,
	showYearDropdown = true,
	label = null,
	defaultValue = new Date(),
	inverted = false,
	showYearPicker = false,
	showError = true,
	errorMessage = null,
	onChange = () => {},
	...restProps
}) {
	const dayjsFormat = useMemo(() => {
		return showYearPicker ? 'YYYY' : 'YYYY-MM-DD'
	}, [showYearPicker])
	const dayjsFormatParsed = useMemo(() => {
		return showYearPicker ? 'yyyy' : 'dd/MM/yyyy'
	}, [showYearPicker])

	const {
		control,
		formState: { errors }
	} = useFormContext()
	const { field } = useController({
		name,
		control,
		defaultValue: dayjs(defaultValue).format(dayjsFormat)
	})
	const { onChange: onFieldChange, value } = field

	const error = !!errors[name]
	const customErrorMessage = errors ? errors[name]?.message : null

	return (
		<StyledDatepicker className="datepicker">
			{label && <Label htmlFor={name} title={label} />}
			<DatePicker
				dateFormat={dayjsFormatParsed}
				disabledKeyboardNavigation={disabledKeyboardNavigation}
				showMonthDropdown={showMonthDropdown}
				showYearDropdown={showYearDropdown}
				locale={locale}
				showYearPicker={showYearPicker}
				selected={dayjs(value).toDate()}
				onChange={date => {
					console.log(date)
					if (date) {
						onFieldChange(dayjs(date).format(dayjsFormat))
						onChange(dayjs(date).format(dayjsFormat))
					}
				}}
				customInput={
					<DatepickerInput
						inputName={name}
						inputFormat={inputFormat}
						inverted={inverted}
						$error={error}
					/>
				}
				{...restProps}
			/>
			{showError && error && (
				<ErrorMessage message={customErrorMessage || errorMessage} />
			)}
		</StyledDatepicker>
	)
}

export default Datepicker

import React, { useCallback } from 'react'
import styled from 'styled-components/macro'

import yup from '@yup'

import useCRUD from '@hooks/crud'
import useMapTools from '@map/hooks/tools'

import useMapStateStore from '@map/store/state'

import { Form, TextField } from '@components/form'
import { Button } from '@components/button'
import { Grid, GridItem } from '@components/grid'
import { Flex } from '@components/flex'
import { Text } from '@components/text'
import Pill from '@components/Pill'

const StyledDialogMapNewGroup = styled.div`
	width: 100%;
	height: 100%;
`
const StyledDialogMapNewGroupPlots = styled.section`
	article {
		margin-bottom: 10px;
	}
	div {
		display: flex;
		flex-flow: row wrap;
		gap: 5px;
	}
`

const schema = yup.object().shape({
	title: yup.string().required()
})

const DialogMapNewGroupInner = ({ plots = [], onCancel = () => {} }) => {
	return (
		<Grid>
			<GridItem columnStart="1" columnEnd="-1">
				<TextField
					name="title"
					label="Nombre *"
					placeholder="Escribe el nombre del grupo de parcelas"
					inverted
				/>
			</GridItem>
			<GridItem columnStart="1" columnEnd="-1">
				<StyledDialogMapNewGroupPlots>
					<Text>
						<p>Parcelas seleccionadas:</p>
					</Text>
					<div>
						{plots.map(({ properties }) => (
							<Pill
								key={properties.id}
								text={properties.title}
								theme="blue"
								hasMargin={false}
							/>
						))}
					</div>
				</StyledDialogMapNewGroupPlots>
			</GridItem>
			<GridItem columnStart="1" columnEnd="-1">
				<Flex justifyContent="space-between" alignItems="center">
					<Button
						label={'Cancelar'}
						theme="secondary"
						size="large"
						onClick={onCancel}
					/>
					<Button
						label={'Guardar'}
						type="submit"
						theme="primary"
						size="large"
					/>
				</Flex>
			</GridItem>
		</Grid>
	)
}

const DialogMapNewGroup = ({ plots, closeModal }) => {
	const { campaignYear: year, resetTools } = useMapTools()

	const updateState = useMapStateStore.use.updateState()

	const { useCreate, queryClient } = useCRUD({
		baseKey: ['groups', { type: 'agriculture' }],
		url: 'groups'
	})

	const { mutate, status } = useCreate({
		config: {
			onSuccess: () => {
				queryClient.invalidateQueries(['map', 'filters', { year }])
				closeModal()
				resetTools()

				updateState({
					mode: null,
					feature: null,
					selectedPlots: []
				})
			}
		}
	})

	const handleSubmit = async data => {
		mutate({
			...data,
			plots: plots.map(({ properties }) => properties.id)
		})
	}

	const handleError = useCallback(error => {
		console.log(error)
	}, [])

	return (
		<StyledDialogMapNewGroup>
			<Form
				onSubmit={handleSubmit}
				onError={handleError}
				loading={status === 'loading'}
				schema={schema}
				useFormProps={{
					mode: 'onChange'
					// defaultValues: formatObject(item, ['harvest_area'])
				}}
			>
				<DialogMapNewGroupInner plots={plots} onCancel={() => closeModal()} />
			</Form>
		</StyledDialogMapNewGroup>
	)
}

export default DialogMapNewGroup

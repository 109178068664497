import React from 'react'
import styled from 'styled-components/macro'
import { motion } from 'framer-motion'
import { Link } from 'react-router-dom'

import { StyledCard } from '@components/cards'
import Icons from '@ui/Icons'

const StyledCardPage = styled(motion(StyledCard))`
	width: 100%;
	height: 100%;
	min-height: var(--card__height);
	position: relative;
	padding: var(--card__gutter);
	transition: all 150ms ease-out;
	box-shadow: var(--card__shadow);

	&:hover {
		box-shadow: var(--card__shadow--hover);
	}

	header {
		display: flex;
		flex-flow: row wrap;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 20px;

		& > svg {
			width: 26px;
			height: 26px;
			fill: var(--c__green);

			@media (min-width: 1024px) {
				width: 32px;
				height: 32px;
			}
		}
	}

	a {
		font-size: 22px;
		color: var(--c__green-700);
		font-weight: 900;
		line-height: 1.2;
		margin-bottom: 15px;
		transition: all 150ms ease-out;

		@media (min-width: 1024px) {
			font-size: 25px;
		}

		&:hover {
			color: var(--c__green);
		}
	}
`
const StyledCardPageCount = styled.section`
	display: flex;
	align-items: flex-end;
	font-size: 18px;
	line-height: 1;

	strong {
		font-size: 1.5em;
		color: var(--c__green);
		font-weight: 900;
		margin-right: 5px;

		@media (min-width: 1024px) {
			font-size: 1.8em;
		}
	}
	span {
		font-size: 1em;
		color: var(--c__grey-500);
	}
`

function CardPage({ id, title, link, count, suffix }) {
	return (
		<StyledCardPage>
			<header>
				<Icons id={id} />
				<div></div>
			</header>
			<Link to={link === 'plots' ? 'fields' : link} title={title}>
				{title}
			</Link>
			<StyledCardPageCount>
				<strong>{count}</strong>
				<span>{suffix}</span>
			</StyledCardPageCount>
		</StyledCardPage>
	)
}

export default CardPage

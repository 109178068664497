import { useCallback, useEffect } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { useLocation } from 'react-router-dom'

import { BASE_URL } from '@constants'

import useStore from '@store/store'
import useAjax from '@hooks/ajax'

function useBreadcrumb(path, config = {}, { empty = false } = {}) {
	const queryClient = useQueryClient()
	const { get } = useAjax()
	const set = useStore(({ set }) => set)
	const { pathname } = useLocation()

	const queryKeys = ['breadcrumb', path]

	const refetchBreadcrumb = useCallback(
		(queryKey = ['breadcrumb', path]) => queryClient.refetchQueries(queryKey),
		[queryClient, path]
	)

	const fetchBreadcrumb = useCallback(
		path => {
			return get(`${BASE_URL}/breadcrumb`, {
				params: {
					path: JSON.stringify(path)
				}
			})
		},
		[get]
	)

	const { data, status, error } = useQuery(
		queryKeys,
		() => fetchBreadcrumb(path),
		config
	)

	/**
	 *! Ahora cogemos el slug que viene de cada item de la API, pero cuando estamos en el single de una categoria, viene undefined el valor de slug. Esto ocurre siempre en los EDIT de los SINGLE
	 */
	useEffect(() => {
		if (data) {
			set(state => {
				const path = pathname.split('/')
				/* state.breadcrumb = data.map((item, i) => ({
					...item,
					slug: path.filter((_, j) => j <= i + 1).join('/'),
					active: i === data.length - 1
				})) */

				/**
				 * Tenemos que usar este código cuando se solucione el problema en la API
				 */
				if (empty) {
					state.breadcrumb = []
				} else {
					state.breadcrumb = data.map((item, i) => ({
						...item,
						slug: item.slug,
						active: i === data.length - 1
					}))
				}
			})
		}
	}, [data, path, pathname, set, config, empty])

	return { refetchBreadcrumb, data, status, error }
}

export default useBreadcrumb

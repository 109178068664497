import React, { useMemo } from 'react'
import styled, { css } from 'styled-components/macro'

import { useUser } from '@hooks/user'
import useCRUD from '@hooks/crud'
import useResponsive from '@hooks/responsive'

import Menu from './menu/Menu'
import Toolbar from './toolbar/Toolbar'
import DashboardRouter from './DashboardRouter'
import Dialogs from '@dialogs/Dialogs'
import Logo from '@dashboard/ui/Logo'

const StyledDashboard = styled.article`
	width: 100%;
	height: 100%;
	padding: var(--grid__gap);
	background-color: var(--c__grey-100);

	@media (min-width: 768px) {
		display: grid;
		grid-template-columns: var(--dashboard__sidebar-width) auto;
		gap: var(--grid__gap--large);
	}
`

const StyledDashboardSidebar = styled.aside`
	width: 100%;
	height: 100%;
	background-color: var(--c__white);
	border-radius: var(--border__radius);
	box-shadow: var(--dashboard__shadow);
`
const StyledDashboardLogoWrapper = styled.div`
	width: 100%;

	${({ $hasUserLogo }) =>
		!!$hasUserLogo &&
		css`
			display: flex;
			flex-flow: column;
			align-items: center;
			gap: 10px;
			padding: 20px 20px 0;
		`}
`
const StyledDashboardLogoUser = styled.figure`
	width: 100%;
	max-width: 120px;

	img {
		width: 100%;
		height: auto;
		object-fit: cover;
		will-change: transform;
		border-radius: 5px;
	}
`

const StyledDashboardContent = styled.div`
	width: 100%;
	height: 100%;
	display: grid;
	grid-template-rows: var(--dashboard__toolbar-height) auto;
	gap: var(--grid__gap--large);
`

function Dashboard() {
	const { data: user } = useUser()
	const { minMD } = useResponsive()

	const { useRead: useHoldings } = useCRUD({
		baseKey: ['holdings', { type: 'agriculture' }],
		url: 'holdings'
	})

	const { data: dataHoldings, status: statusHoldings } = useHoldings({
		ajax: {
			params: {
				type: 'agriculture'
			}
		}
	})

	const hasUserLogo = useMemo(
		() => !!user?.thumb_in_logo && !!user?.urlImg,
		[user]
	)

	if (!user) return null
	if (statusHoldings !== 'success') return null

	return (
		<StyledDashboard>
			{minMD ? (
				<StyledDashboardSidebar>
					<StyledDashboardLogoWrapper $hasUserLogo={hasUserLogo}>
						<Logo hasUserLogo={hasUserLogo} />
						{hasUserLogo && (
							<StyledDashboardLogoUser>
								<img src={user?.urlImg} alt="OKCampo" />
							</StyledDashboardLogoUser>
						)}
					</StyledDashboardLogoWrapper>
					<Menu />
				</StyledDashboardSidebar>
			) : null}
			<StyledDashboardContent>
				<Toolbar user={user} />
				<DashboardRouter holdings={dataHoldings} />
			</StyledDashboardContent>
			<Dialogs />
		</StyledDashboard>
	)
}

export default Dashboard

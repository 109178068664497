import { useCallback } from 'react'
import { useQueryClient } from 'react-query'
import dayjs from 'dayjs'
import { useParams } from 'react-router-dom'
import { omit } from 'lodash'

import useCRUD from '@hooks/crud'
import useStore from '@store/store'

const log = data =>
	data ? console.log(JSON.parse(JSON.stringify(data))) : data

const plotStyles = {
	backgroundColor: 'var(--c__green-700)',
	backgroundSelectedColor: 'var(--c__green-700)',
	progressColor: 'var(--c__green-700)',
	progressSelectedColor: 'var(--c__green-700)'
}

function useCampaigns() {
	const queryClient = useQueryClient()

	const campaigns = useStore(({ campaigns }) => campaigns)
	const set = useStore(({ set }) => set)

	const getCampaignsGantt = useCallback(
		query => {
			const plotsUnique = []
			let filterResult
			const items = campaigns.ganttOrder.map(id => campaigns.ganttById[id])
			const itemsFilter = items.filter(({ name }) =>
				name.toString().toLowerCase().includes(query.toLowerCase())
			)
			if (items.length !== itemsFilter.length) {
				itemsFilter.forEach(node => {
					if (node.type === 'project') {
						filterResult = items.filter(({ id }) =>
							id.toString().toLowerCase().includes(node.id.toLowerCase())
						)
					}
					if (node.type !== 'project') {
						filterResult = items.filter(
							({ id }) =>
								id.toString().toLowerCase() === node.id.toLowerCase() ||
								id === node.project
						)
					}

					filterResult.forEach(node => {
						plotsUnique.push(node)
					})
				})
				const result = plotsUnique.filter((item, index) => {
					return plotsUnique.indexOf(item) === index
				})
				return result
			}
			return items.filter(({ name }) =>
				name.toString().toLowerCase().includes(query.toLowerCase())
			)
		},
		[campaigns]
	)

	const getCampaignById = useCallback(
		(id, key = 'id') => campaigns.collection.find(item => item[key] === id),
		[campaigns]
	)

	const updateExpander = useCallback(
		item => {
			set(({ campaigns }) => {
				campaigns.ganttById[item.id].barChildren = item.barChildren
				campaigns.ganttById[item.id].hideChildren = item.hideChildren
			})
		},
		[set]
	)

	const parseGanttCampaigns = useCallback(() => {
		set(state => {
			const collection = state.campaigns.collection
			const plots = state.campaigns.plots
			state.campaigns.ganttById = {}
			state.campaigns.ganttOrder = []
			let flag = 0
			plots.forEach(plot => {
				const newPlot = {
					start: new Date(),
					end: new Date(),
					name: plot.title,
					id: `plot-${plot.id}`,
					progress: 100,
					type: 'project',
					hideChildren: false,
					isDisabled: true,
					styles: plotStyles,
					barChildren: [],
					plot
				}
				state.campaigns.ganttById[newPlot.id] = newPlot
				state.campaigns.ganttOrder.push(newPlot.id)
				flag = 0
				/*! quitar luego */
				// plot.ganttId = newPlot.id
				collection.forEach(campaign => {
					const campaigns = campaign.plots.find(
						id => id.toString() === plot.id.toString()
					)
					if (campaigns) {
						flag = 1
						const newCampaign = {
							start: dayjs(campaign.begin_date).toDate(),
							end: dayjs(campaign.end_date).toDate(),
							name: campaign.title,
							id: `campaign-${campaign.id}__${newPlot.id}`,
							_id: campaign.id.toString(),
							progress: 0,
							type: 'task',
							project: newPlot.id,
							dependencies: [newPlot.id],
							campaign
						}

						/*! quitar luego */
						campaign.ganttId = newCampaign.id

						state.campaigns.ganttById[newCampaign.id] = newCampaign
						state.campaigns.ganttOrder.push(newCampaign.id)

						const ganttCollection = Object.keys(state.campaigns.ganttById).map(
							key => state.campaigns.ganttById[key]
						)
						const campaignItems = ganttCollection.filter(
							({ type, project }) => type === 'task' && project === newPlot.id
						)

						if (!!campaignItems && !!campaignItems.length) {
							const startDates = campaignItems.map(({ start }) => start)
							const endDates = campaignItems.map(({ end }) => end)
							const plotItem = ganttCollection.find(
								({ type, id }) => type === 'project' && id === newPlot.id
							)

							if (plotItem) {
								plotItem.start = new Date(Math.min(...startDates))
								plotItem.end = new Date(Math.max(...endDates))
							}
						}
					}
				})
				if (flag === 0) {
					const id = newPlot.id
					state.campaigns.ganttById = omit(state.campaigns.ganttById, [id])
					state.campaigns.ganttOrder = state.campaigns.ganttOrder.filter(
						idplots => idplots !== id
					)
				}
			})
		})
	}, [set])

	const getFilteredCampaigns = useCallback(
		query => {
			const gantt = getCampaignsGantt()
			return gantt.filter(({ name }) =>
				name?.toString().toLowerCase().includes(query?.toLowerCase())
			)
			// return _search(gantt, query, { keys: ['name'] })
		},
		[getCampaignsGantt]
	)

	/**
	 * QUERIES
	 */

	const useFetchCampaigns = () => {
		const { categoryId } = useParams()

		const { useRead: useReadPlots } = useCRUD({
			baseKey: ['plots'],
			url: `plots`
		})
		const { useRead: useReadCampaigns } = useCRUD({
			baseKey: ['campaigns', { type: categoryId }],
			url: `campaigns`
		})

		const { status } = useReadPlots({
			config: {
				staleTime: Infinity,
				refetchOnWindowFocus: false,
				onSuccess: data => {
					set(({ campaigns }) => {
						// campaigns.plots = data?.children.filter(
						// 	({ campaigns }) => !!campaigns.length
						// )
						campaigns.plots = data?.children
					})
				}
			},
			ajax: {
				params: {
					type: categoryId
				}
			}
		})

		return useReadCampaigns({
			config: {
				cacheTime: 0,
				staleTime: 0,
				refetchOnWindowFocus: false,
				enabled: status === 'success',
				onSuccess: async data => {
					await set(({ campaigns }) => {
						if (!!data.children && !!data.children.length) {
							campaigns.collection = data?.children.filter(
								({ plots }) => !!plots?.length
							)

							/* data.children.forEach(item => {
								queryClient.setQueryData(
									['campaigns', { type: categoryId }, { id: item.id }],
									item
								)
							}) */
						}
					})

					parseGanttCampaigns()
				}
			}
		})
	}

	const updateCampaign = useCallback(
		({ id, data }) => {
			set(({ campaigns }) => {
				// Actualizar parcelas
				const savePosicion = [...campaigns.ganttOrder]
				campaigns.ganttOrder.forEach(item => {
					if (item.includes(`campaign-${id}`.toLowerCase())) {
						campaigns.ganttById = omit(campaigns.ganttById, [item])
						campaigns.ganttOrder = campaigns.ganttOrder.filter(
							id => id !== item
						)
					}
				})
				let plotsUnique = []
				Object.keys(campaigns.ganttById).forEach(key => {
					const plot = campaigns.ganttById[key]
					if (plot.type === 'project') {
						plotsUnique.push(key)
					} else if (plot.type === 'task') {
						plotsUnique = plotsUnique.filter(item => item !== plot.project)
					}
				})

				plotsUnique.forEach(index => {
					if (!data.plots.includes(index.slice(5))) {
						campaigns.ganttById = omit(campaigns.ganttById, [index])
						campaigns.ganttOrder = campaigns.ganttOrder.filter(c => c !== index)
					}
				})

				data.plots.forEach(plott => {
					const plot = campaigns.plots.find(plots => plots.id === plott)
					if (plott) {
						const plotsProject = campaigns.ganttOrder.find(
							idPlot => idPlot === `plot-${plott}`
						)
						if (!plotsProject) {
							const newPlot = {
								start: new Date(),
								end: new Date(),
								name: plot.title,
								id: `plot-${plot.id}`,
								progress: 100,
								type: 'project',
								hideChildren: false,
								isDisabled: true,
								styles: plotStyles,
								barChildren: [],
								plot
							}
							campaigns.ganttById[newPlot.id] = newPlot
							campaigns.ganttOrder.push(newPlot.id)
						}
						const idPlot = plotsProject || `plot-${plot.id}`
						if (plot.campaigns.length > 0) {
							const campaign = campaigns.collection.find(ids => ids.id === id)
							if (campaign) {
								const newCampaign = {
									start: dayjs(campaign.begin_date).toDate(),
									end: dayjs(campaign.end_date).toDate(),
									name: campaign.title,
									id: `campaign-${campaign.id}__${idPlot}`,
									_id: campaign.id,
									progress: 0,
									type: 'task',
									project: idPlot,
									dependencies: [idPlot],
									campaign
								}
								const positio = savePosicion.indexOf(newCampaign.id)
								if (positio === -1) {
									campaigns.ganttById[newCampaign.id] = newCampaign
									campaigns.ganttOrder.splice(
										campaigns.ganttOrder.indexOf(idPlot) + 1,
										0,
										newCampaign.id
									)
								}
								if (positio !== '-1') {
									campaigns.ganttById[newCampaign.id] = newCampaign
									campaigns.ganttOrder.splice(positio, 0, newCampaign.id)
								}
							}
						}
						if (plot.campaigns.length === 0) {
							const newCampaign = {
								start: dayjs(data.begin_date).toDate(),
								end: dayjs(data.end_date).toDate(),
								name: data.title,
								id: `campaign-${data.id}__${idPlot}`,
								_id: data.id.toString(),
								progress: 0,
								type: 'task',
								project: idPlot,
								dependencies: [idPlot],
								data
							}
							campaigns.ganttById[newCampaign.id] = newCampaign
							campaigns.ganttOrder.splice(
								campaigns.ganttOrder.indexOf(idPlot) + 1,
								0,
								newCampaign.id
							)
						}
					}
				})
				// --------- //

				campaigns.collection.forEach(item => {
					if (item.id === id) {
						item.plots = data.plots
						item.title = data.title
						item.begin_date = data.begin_date
						item.end_date = data.end_date
					}
				})

				Object.keys(campaigns.ganttById).forEach(key => {
					const item = campaigns.ganttById[key]

					if (item._id === id) {
						item.name = data.title
						item.start = dayjs(data.begin_date).toDate()
						item.end = dayjs(data.end_date).toDate()
					}
				})

				const ganttCollection = Object.keys(campaigns.ganttById).map(
					key => campaigns.ganttById[key]
				)

				Object.keys(campaigns.ganttById).forEach(key => {
					const plot = campaigns.ganttById[key]

					if (plot.type === 'project') {
						const campaignItems = ganttCollection.filter(
							({ type, project }) => type === 'task' && project === plot.id
						)

						const startDates = campaignItems.map(({ start }) => start)
						const endDates = campaignItems.map(({ end }) => end)

						plot.start = new Date(Math.min(...startDates))
						plot.end = new Date(Math.max(...endDates))
					}
				})
			})
		},
		[set]
	)

	const deleteCampaign = useCallback(
		data => {
			set(({ campaigns }) => {
				campaigns.collection = campaigns.collection.filter(
					({ id }) => id !== data.id
				)
				campaigns.ganttOrder.forEach(item => {
					if (item.includes(`campaign-${data.id}`.toLowerCase())) {
						// delete campaigns.ganttById[item]
						campaigns.ganttById = omit(campaigns.ganttById, [item])
						campaigns.ganttOrder = campaigns.ganttOrder.filter(
							id => id !== item
						)
					}
				})
				let plotsUnique = []

				const ganttCollection = Object.keys(campaigns.ganttById).map(
					key => campaigns.ganttById[key]
				)
				Object.keys(campaigns.ganttById).forEach(key => {
					const plot = campaigns.ganttById[key]
					if (plot.type === 'project') {
						plotsUnique.push(key)

						const campaignItems = ganttCollection.filter(
							({ type, project }) => type === 'task' && project === plot.id
						)

						const startDates = campaignItems.map(({ start }) => start)
						const endDates = campaignItems.map(({ end }) => end)

						plot.start = new Date(Math.min(...startDates))
						plot.end = new Date(Math.max(...endDates))
					}
				})
				Object.keys(campaigns.ganttById).forEach(key => {
					const plot = campaigns.ganttById[key]
					if (plot.type === 'task') {
						plotsUnique = plotsUnique.filter(item => item !== plot.project)
					}
				})
				plotsUnique.forEach(index => {
					campaigns.ganttById = omit(campaigns.ganttById, [index])
					campaigns.ganttOrder = campaigns.ganttOrder.filter(c => c !== index)
				})
			})
		},
		[set]
	)

	const useUpdateCampaign = ({ id }) => {
		const { categoryId } = useParams()

		const baseKey = [`campaigns`, { type: categoryId || 'agriculture' }, { id }]

		const { useUpdate } = useCRUD({
			baseKey,
			url: `campaigns`
		})

		return useUpdate({
			config: {
				onMutate: values => {
					const campaignOld = getCampaignById(values.id)
					const campaignGanttOld =
						campaigns.ganttById[
							`campaign-${values.id}__plot-${campaignOld.plots[0]}`
						]
					const data = { ...campaignOld, ...values }

					updateCampaign({ id, data })

					// return { campaignOld, campaignGanttOld }
					/* const oldCampaign = queryClient.getQueryData(baseKey)
					queryClient.setQueryData(
						baseKey,
						values
					)
					return oldCampaign */
				},
				/* onError: (err, newTodo, context) => {
					const { campaignOld, campaignGanttOld } = context
					console.log(campaignOld, campaignGanttOld)
					set(({ campaigns }) => {

						const collection = campaigns.collection
						const ganttById = campaigns.ganttById

						const campaign = collection.find(({ id }) => id === oldValues.id)

						const ganttCollection = Object.keys(ganttById).map(
							key => ganttById[key]
						)
						const campaignGantt = ganttCollection.find(
							({ _id }) => _id === oldValues.id
						)
					})
				}, */
				onSuccess: async data => {
					/* queryClient.invalidateQueries(['campaigns', { type: categoryId }])
					queryClient.invalidateQueries([
						'campaigns',
						{ type: categoryId },
						{ id }
					]) */

					console.log(baseKey)

					updateCampaign({ id, data })
					queryClient.invalidateQueries(baseKey, { exact: true })
				}
			}
		})
	}

	const useDeleteCampaign = id => {
		const baseKey = ['campaigns', id]

		const { useDelete } = useCRUD({
			baseKey,
			url: 'campaigns'
		})

		return useDelete({
			config: {
				onMutate: async itemId => {
					// await queryClient.cancelQueries(baseKey)
					// const previousItems = queryClient.getQueryData(baseKey)
					// if (previousItems && previousItems?.children) {
					// 	queryClient.setQueryData(baseKey, old => {
					// 		const id = itemId?.id || itemId
					// 		return {
					// 			...old,
					// 			children: old.children.filter(o => o.id !== id) || old?.children
					// 		}
					// 	})
					// }
					// return { previousItems }
				},
				/* onError: (err, newTodo, context) => {
					queryClient.setQueryData(baseKey, context.previousItems)
				}, */
				onSettled: async (data, error, variables, context) => {
					// queryClient.removeQueries(['campaigns', id])
					// queryClient.invalidateQueries(baseKey, { exact: true })
					deleteCampaign(data)
				}
			}
		})
	}

	return {
		campaigns,
		getCampaignsGantt,
		getCampaignById,
		updateExpander,
		getFilteredCampaigns,
		useFetchCampaigns,
		useUpdateCampaign,
		useDeleteCampaign
	}
}

export default useCampaigns

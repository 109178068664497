import React, { useCallback, useEffect } from 'react'
import styled from 'styled-components/macro'
import { useFormContext, useWatch } from 'react-hook-form'

import { usePromptStatus } from '@hooks/prompt-form'

import { Text } from '@components/text'
import { Form, Checkbox } from '@components/form'
import { Button } from '@components/button'
import { Grid, GridItem } from '@components/grid'
import { Flex } from '@components/flex'

const StyledDialogPrompt = styled.div`
	width: 100%;
	height: 100%;
`
const StyledDialogPromptFooter = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-top: 1px solid var(--c__grey-300);
	padding-top: 20px;
`

const DialogPromptInner = ({
	title,
	cancelTitle,
	confirmTitle,
	onClickCancel = () => {},
	onClickConfirm = () => {}
}) => {
	const { getPromptStatus, setPromptStatus } = usePromptStatus()
	const promptStatus = getPromptStatus()

	const { control } = useFormContext()

	const promptDisabled = useWatch({
		control,
		name: 'promptDisabled',
		defaultValue: promptStatus === 'disabled'
	})

	useEffect(() => {
		setPromptStatus(!!promptDisabled ? 'disabled' : 'active')
	}, [promptDisabled])

	return (
		<Grid>
			<GridItem columnStart="1" columnEnd="-1">
				<Flex>
					<Text size="large">
						<p>{title}</p>
					</Text>
				</Flex>
			</GridItem>
			<GridItem columnStart="1" columnEnd="-1">
				<Checkbox
					name="promptDisabled"
					checked={promptDisabled}
					size="small"
					label="Evitar que aparezcan más avisos de guardado"
				/>
			</GridItem>
			<GridItem columnStart="1" columnEnd="-1">
				<StyledDialogPromptFooter>
					<Button
						label={cancelTitle}
						theme="secondary"
						size="large"
						onClick={onClickCancel}
					/>
					<Button
						label={confirmTitle}
						theme="primary"
						size="large"
						onClick={onClickConfirm}
					/>
				</StyledDialogPromptFooter>
			</GridItem>
		</Grid>
	)
}

const DialogPrompt = props => {
	const handleCancel = useCallback(() => {
		props.onCancel()
		props.onPromptCancel()

		props.closeModal()
	}, [])

	const handleConfirm = useCallback(() => {
		props.onConfirm()
		props.onPromptConfirm()

		props.closeModal()
	}, [])

	return (
		<StyledDialogPrompt>
			<Form hasPrompt={false}>
				<DialogPromptInner
					{...props}
					onClickCancel={handleCancel}
					onClickConfirm={handleConfirm}
				/>
			</Form>
		</StyledDialogPrompt>
	)
}

export default DialogPrompt

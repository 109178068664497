import React from 'react'
import styled from 'styled-components/macro'

export const StyledCard = styled.div`
	width: 100%;
	min-height: 160px;
	background-color: var(--c__white);
	border-radius: var(--border__radius);
	box-shadow: var(--dashboard__shadow);
	overflow: hidden;
`

function Card({ children }) {
	return <StyledCard>{children}</StyledCard>
}

export default Card

import React, { useMemo } from 'react'
import styled from 'styled-components/macro'

import logoWhite from '@images/common/logo-white.png'
import splash from '@images/common/splash.png'
import Spinner from './Spinner'

const StyledPreloaderInit = styled.aside`
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 10;
	background-color: var(--c__green);
	background-image: linear-gradient(
		to bottom,
		var(--c__green) 0%,
		var(--c__green-500) 100%
	);
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;

	&:before {
		content: '';
		background-image: url(${splash});
		background-size: cover;
		background-repeat: no-repeat;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 2;
		pointer-events: none;
	}

	& > section {
		max-width: 350px;
		display: flex;
		flex-flow: column;
		align-items: center;
		position: relative;
		z-index: 3;

		& > img {
			width: 300px;
			height: auto;
			margin-bottom: 40px;
		}

		p {
			font-size: 18px;
			color: var(--c__white);
			font-weight: 700;
		}
	}
`

function PreloaderInit() {
	return (
		<StyledPreloaderInit>
			<section>
				<img src={logoWhite} alt="OKCampo" />
				<Spinner />
			</section>
		</StyledPreloaderInit>
	)
}

export default PreloaderInit

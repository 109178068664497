import React, { useRef } from 'react'
import styled from 'styled-components/macro'
import classNames from 'classnames'
import { motion, AnimatePresence } from 'framer-motion'

const StyledOverlay = styled(motion.aside)`
	width: 100%;
	height: 100%;
	position: fixed;
	overflow: hidden;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 2147483641;
	outline: medium none;
	background-color: var(--modal__overlay-background);
	backface-visibility: hidden;
	transform-style: preserve-3d;
	perspective: 1200px;
`

function Overlay({
	isOpened = false,
	dismissable = false,
	styles = {},
	springProps = {},
	onOpen = () => {},
	onClose = () => {},
	onButtonClose = () => {}
}) {
	const overlay = useRef()

	const variants = {
		initial: {
			opacity: 0
		},
		open: {
			opacity: 1,
			transition: {
				duration: 0.2
			}
		},
		close: {
			opacity: 0,
			transition: {
				duration: 0.1
			}
		}
	}

	return (
		<AnimatePresence>
			{isOpened && (
				<StyledOverlay
					ref={overlay}
					id="modal-overlay"
					className={classNames({
						modal__overlay: true,
						'modal__overlay--dismissable': !!dismissable
					})}
					initial={variants.initial}
					animate={variants.open}
					exit={variants.close}
				/>
			)}
		</AnimatePresence>
	)
}

export default Overlay

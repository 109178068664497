import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { useAuth } from '@context/auth'
import { useNotifications } from '@notifications'
import yup from '@yup'

import { Form, TextField } from '@components/form'
import { Button } from '@components/button'
import { Text } from '@components/text'
import { Box } from '@components/layout'
import { emailRegex } from '@utils'

import Auth from './Auth'

const schema = yup.object().shape({
	email: yup.string().required().email()
})

function ForgotPassword() {
	const navigate = useNavigate()
	const { notification } = useNotifications()
	const { forgotPassword, status, resetStatus, error } = useAuth()

	const handleSubmit = values => {
		forgotPassword(values)
	}

	useEffect(() => {
		if (status === 'success') {
			resetStatus()

			notification({
				title: 'Código enviado',
				description:
					'Te acabamos de enviar un email con el código que debes introducir en este formulario',
				id: 'forgot-password',
				type: 'info',
				timeout: 15000
			})

			navigate('/auth/reset-password')
		}
	}, [status])

	return (
		<Auth title="Recupera tu contraseña" isTitleBold>
			<Form
				loading={status === 'loading'}
				schema={schema}
				hasPrompt={false}
				onSubmit={handleSubmit}
			>
				<Box>
					<Text size="medium" center>
						<p>
							<strong>Mándanos tu email</strong> desde el formulario de abajo y{' '}
							<strong>te enviaremos a tu correo tu nueva contraseña</strong>.
						</p>
						<p>
							Cuando la tengas, <strong>vuelve a la página de inicio</strong> y
							úsala.
						</p>
						<p>Así de fácil :)</p>
					</Text>
				</Box>
				<Box>
					<TextField
						type="email"
						name="email"
						label="Email *"
						placeholder="Escribe tu email"
					/>
				</Box>
				<Box>
					<Button
						label="Enviar petición"
						type="submit"
						theme="primary"
						size="large"
						full
						center
					/>
				</Box>
				<Box>
					<Button label="Volver al inicio" to="/auth/login" full center />
				</Box>
			</Form>
		</Auth>
	)
}

export default ForgotPassword

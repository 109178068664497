import { useMemo } from 'react'
import { useMediaQuery } from 'react-responsive'

export default function useResponsive() {
	const sizes = useMemo(() => ({
		xs: 320,
		sm: 568,
		md: 768,
		lg: 1024,
		xl: 1200,
		xxl: 1400,
		xxxl: 1600
	}))

	const minXS = useMediaQuery({ query: `(min-width: ${sizes.xs}px)` })
	const minSM = useMediaQuery({ query: `(min-width: ${sizes.sm}px)` })
	const minMD = useMediaQuery({ query: `(min-width: ${sizes.md}px)` })
	const minLG = useMediaQuery({ query: `(min-width: ${sizes.lg}px)` })
	const minXL = useMediaQuery({ query: `(min-width: ${sizes.xl}px)` })
	const minXXL = useMediaQuery({ query: `(min-width: ${sizes.xxl}px)` })
	const minXXXL = useMediaQuery({ query: `(min-width: ${sizes.xxxl}px)` })

	return {
		sizes,
		minXS,
		minSM,
		minMD,
		minLG,
		minXL,
		minXXL,
		minXXXL,
		useMediaQuery
	}
}

import React from 'react'
import styled from 'styled-components/macro'
import cx from 'classnames'
import { Link } from 'react-router-dom'

import logo from '@images/common/logo-okcampo.png'

const StyledDashboardLogo = styled(Link)`
	transition: opacity 150ms ease-out;
	height: 100%;

	@media (min-width: 768px) {
		padding: 20px;
		height: auto;
	}

	&.has-user-logo {
		width: 100%;
		max-width: 120px;
		padding: 0;
	}

	&:hover {
		opacity: 0.7;
	}

	img {
		max-width: 90px;

		@media (min-width: 768px) {
			max-width: none;
		}
	}
`

function Logo({ hasUserLogo = false }) {
	return (
		<StyledDashboardLogo
			className={cx([hasUserLogo && 'has-user-logo'])}
			to="/"
		>
			<img src={logo} alt="OKCampo" />
		</StyledDashboardLogo>
	)
}

export default Logo

import React from 'react'
import styled from 'styled-components'
import { usePagination } from 'react-pagination-hook'

const StyledPagination = styled.aside`
	width: 100%;
	margin-top: 20px;
	margin-bottom: 20px;
	display: flex;
	align-items: center;
	justify-content: center;

	@media (min-width: 768px) {
		justify-content: flex-end;
	}

	button,
	span {
		font-size: 14px;
		color: var(--c__grey-700);
		margin-right: 12px;
		transition: all 150ms ease-out;

		&:hover::not([disabled]) {
			color: var(--c__green);
		}

		&:last-child {
			margin-right: 0;
		}

		&.active {
			color: var(--c__green);
			font-weight: 700;
		}

		&[disabled] {
			opacity: 0.4;
			pointer-events: none;
		}
	}
`

function TablePagination({
	initialPage = 1,
	numberOfPages = 10,
	maxButtons = 5,
	onChange = () => {}
}) {
	const { visiblePieces, activePage } = usePagination({
		initialPage,
		numberOfPages,
		maxButtons
	})

	if (visiblePieces.length <= 1) {
		return null
	}

	return (
		<StyledPagination className="pagination">
			{visiblePieces.map((visiblePiece, index) => {
				const key = `${visiblePiece.type}-${index}`
				const isActive =
					visiblePiece.type === 'page-number' &&
					activePage === visiblePiece.pageNumber
				let label = visiblePiece?.pageNumber

				if (visiblePiece.type === 'previous') {
					label = 'Atrás'
				} else if (visiblePiece.type === 'next') {
					label = 'Siguiente'
				} else if (visiblePiece.type === 'page-number') {
					label += 0
				}

				if (visiblePiece.type === 'ellipsis') {
					return (
						<span key={key} title="...">
							...
						</span>
					)
				}

				return isActive ? (
					<span key={key} className="active" title={label}>
						{label}
					</span>
				) : (
					<button
						key={key}
						title={label}
						disabled={visiblePiece?.isDisabled}
						onClick={e => onChange(visiblePiece.pageNumber - 1)}
					>
						{label}
					</button>
				)
			})}
		</StyledPagination>
	)
}

export default TablePagination

import React, { useState } from 'react'
import { isObject } from 'lodash'

function useLocalStorage(
	key,
	initialValue,
	settings = {
		store: window.localStorage
	}
) {
	// State to store our value
	// Pass initial state function to useState so logic is only executed once
	const [storedValue, setStoredValue] = useState(() => {
		try {
			// Get from local storage by key
			const item = settings.store.getItem(key)
			// Parse stored json or if none return initialValue
			return item ? JSON.parse(item) : initialValue
		} catch (error) {
			// If error also return initialValue
			console.log(error)
			return initialValue
		}
	})
	// Return a wrapped version of useState's setter function that ...
	// ... persists the new value to localStorage.
	const setValue = value => {
		try {
			// Allow value to be a function so we have same API as useState
			let valueToStore = value instanceof Function ? value(storedValue) : value
			valueToStore = isObject(valueToStore)
				? { ...storedValue, ...valueToStore }
				: valueToStore
			// Save state
			setStoredValue(valueToStore)
			// Save to local storage
			settings.store.setItem(key, JSON.stringify(valueToStore))
		} catch (error) {
			// A more advanced implementation would handle the error case
			console.log(error)
		}
	}
	return [storedValue, setValue]
}

export default useLocalStorage

import { useCallback, useMemo } from 'react'
import { useQueryClient } from 'react-query'

import useStore from '@store/store'
import useCRUD from '@hooks/crud'

function useAlerts() {
	const queryClient = useQueryClient()

	const alerts = useStore(({ alerts }) => alerts)
	const set = useStore(({ set }) => set)

	const alertsSidepanelOpened = useMemo(() => alerts.opened, [alerts])

	const toggleSidepanel = useCallback(
		(opened = false) => {
			set(({ alerts }) => {
				if (!opened) {
					alerts.view = 'all'
				}
				alerts.opened = opened
			})
		},
		[set]
	)

	const parseNewLines = useCallback(text => {
		const lines = text.split(/\r?\n/)
		return !lines.length
			? ''
			: lines.reduce((result, line) => {
					result += line === '' ? '<br/>' : `<p>${line}</p>`
					return result
			  }, '')
	}, [])

	/**
	 * QUERIES
	 */
	const useFetch = () => {
		const baseKey = [`alerts`]

		const { useRead } = useCRUD({
			baseKey,
			url: 'alerts'
		})

		return useRead()
	}

	const useCreate = () => {
		const baseKey = [`alerts`]

		const { useCreate } = useCRUD({
			baseKey,
			url: 'alerts'
		})

		return useCreate({
			config: {
				onSettled: async (data, error, variables, context) => {
					queryClient.invalidateQueries(baseKey, { exact: true })
				}
			}
		})
	}

	const useDelete = () => {
		const baseKey = [`alerts`]

		const { useDelete } = useCRUD({
			baseKey,
			url: `alerts`
		})

		return useDelete({
			config: {
				onMutate: async itemId => {
					await queryClient.cancelQueries(baseKey)

					const previousItems = queryClient.getQueryData(baseKey)

					if (previousItems) {
						queryClient.setQueryData(baseKey, old => {
							const id = itemId?.id || itemId
							return old.filter(o => o.id !== id) || old
						})
					}

					return { previousItems }
				},
				onError: (err, newTodo, context) => {
					queryClient.setQueryData(baseKey, context.previousItems)
				},
				onSettled: async (data, error, variables, context) => {
					queryClient.invalidateQueries(baseKey, { exact: true })
				}
			}
		})
	}

	const useFetchUsers = user => {
		const { useRead } = useCRUD({
			baseKey: ['users'],
			url: 'users'
		})

		return useRead({
			ajax: {
				params: {
					user_id: user?.id
				}
			}
		})
	}

	return {
		alertsSidepanelOpened,
		toggleSidepanel,
		parseNewLines,
		useFetch,
		useCreate,
		useDelete,
		useFetchUsers
	}
}

export default useAlerts

import React from 'react'
import styled from 'styled-components/macro'
import { motion } from 'framer-motion'

import { StyledCard } from '@components/cards'
import { Button } from '@components/button'

const StyledCardAccess = styled(motion(StyledCard))`
	width: 100%;
	height: 100%;
	min-height: var(--card__height);
	position: relative;
	padding: var(--card__gutter);
	transition: all 150ms ease-out;
	box-shadow: var(--card__shadow);
	display: flex;
	flex-flow: column;
	align-items: center;
	gap: 20px;
	cursor: pointer;

	&:hover {
		box-shadow: var(--card__shadow--hover);
	}

	h3 {
		font-size: 22px;
		color: var(--c__green-700);
		font-weight: 900;
		line-height: 1.2;
	}

	img {
		max-width: 80px;
		width: 100%;
		height: auto;
	}
`

function CardAccess({ image, title, onClick = () => {} }) {

	return (
		<StyledCardAccess onClick={onClick}>
			<h3>{title} </h3>
			<img src={image} alt={title} />
			<Button label={'Acceder'} theme="secondary" />
		</StyledCardAccess>
	)
}

export default CardAccess
